// IMPORT REACT
import React,{ useRef, useState, useEffect } from 'react'
import axios from "axios"
import { motion, AnimatePresence } from "framer-motion";
import { useParams } from "react-router-dom"

// IMPORT LOADING PAGE
import LoadingPage from '../../Layout/LoadingPage.jsx'

const WePernikahanHasil = () => { 

    const { salinLink } = useParams();
    const { namaTamu } = useParams();

    const [dataWePernikahan, setDataWePernikahan] = useState({})
    const [dataUcapan, setDataUcapan] = useState([])
    const [kalenderAkad, setKalenderAkad] = useState('')
    const [kalenderResepsi, setKalenderResepsi] = useState('')
    const [link4, setLink4] = useState('')
    const [msg, setMsg] = useState('')
    const [msgUcapan, setMsgUcapan] = useState('')
    const [nama, setNama] = useState('')
    const [status, setStatus] = useState('')
    const [ucapan, setUcapan] = useState('')

    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showPage, setShowPage] = useState(true);
    const [showMainPage, setShowMainPage] = useState(false);
    const [bukaUndangan, setBukaUndangan] = useState('none')
    const [isPlaying, setIsPlaying] = useState(false);

    const audioRef = useRef(null);
    const inputNoRek1 = useRef(null);
    const inputNoRek2 = useRef(null);
    const elementsRef = useRef([]);
    const timeoutsRef = useRef([]);

    // USEEFFECT DATA WE PERNIKAHAN
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setProgress(0);
                let fakeProgress = 0;
                const fastLoading = setInterval(() => {
                    fakeProgress += 10;
                    setProgress(fakeProgress);
                    if (fakeProgress >= 80) {
                        clearInterval(fastLoading);
                    }
                }, 50);
                const response = await axios.get(
                    `https://server13.webew.id/selectWePernikahanHasilBE/${salinLink}`,
                    {
                        onDownloadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            if (total) {
                                let percentCompleted = Math.round((loaded / total) * 100);
                                setProgress(Math.max(80, percentCompleted)); // Pastikan tidak turun dari 80%
                            } else {
                                // Jika total tidak tersedia, buat progres naik perlahan
                                setProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : prevProgress));
                            }
                        },
                    }
                );
                if (response.data.jenisUndanganDigitalPernikahan) {
                    // TEMA PERNIKAHAN
                    const tema = {
                        agapanthus: {
                            pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_agapanthus.png",
                            displayLoader: "none",
                            btnColorCD: "#0081b0",
                            btnColor: "#f3fbff",
                            fntColor: "rgb(108, 108, 108)",
                            borderBtn: "3px solid #0081b0",
                            bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                            bgColor: "#f8ffff2f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png",
                            bgColorBox2: "#4fd0ff15",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png",
                            links: [
                                {
                                    url: "https://www.freepik.com/free-vector/summer-pattern-background-zoom_8845952.htm#page=5&query=purple%20leaf%20background&position=34&from_view=search&track=ais",
                                    pemilik: "pikisuperstar",
                                },
                                {
                                    url: "https://www.freepik.com/free-vector/gradient-tropical-leaves-background_14309031.htm#page=5&query=purple%20leaf%20background&position=46&from_view=search&track=ais",
                                    pemilik: "Freepik",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        aglaonema: {
                            pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_aglaonema.png",
                            displayLoader: "none",
                            btnColorCD: "green",
                            btnColor: "#f4fff3",
                            fntColor: "rgb(108, 108, 108)",
                            borderBtn: "3px solid green",
                            bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                            bgColor: "#fafff82f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png",
                            bgColorBox2: "#5eff4f15",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png",
                            links: [
                                {
                                    url: "https://www.freepik.com/free-vector/summer-pattern-background-zoom_8845952.htm#page=5&query=purple%20leaf%20background&position=34&from_view=search&track=ais",
                                    pemilik: "pikisuperstar",
                                },
                                {
                                    url: "https://www.freepik.com/free-vector/realistic-tropical-leaves-background_13991173.htm#page=2&query=tropical&position=3&from_view=search&track=sph",
                                    pemilik: "pikisuperstar",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        alamanda: {
                            pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_alamanda.png",
                            displayLoader: "none",
                            btnColorCD: "#b05500",
                            btnColor: "#fffaf3",
                            fntColor: "rgb(108, 108, 108)",
                            borderBtn: "3px solid #b05500",
                            bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                            bgColor: "#fffcf82f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png",
                            bgColorBox2: "#ffa44f15",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png",
                            links: [
                                {
                                    url: "https://www.freepik.com/free-vector/realistic-autumn-background_9260611.htm#page=6&query=yellow%20leaf&position=16&from_view=search&track=ais",
                                    pemilik: "pikisuperstar",
                                },
                                {
                                    url: "https://www.freepik.com/free-vector/flat-autumn-forest-leaves-background_5113181.htm#page=3&query=yellow%20leaf%20background&position=20&from_view=search&track=ais",
                                    pemilik: "freepik",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        amaryllis: {
                            pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_amaryllis.png",
                            displayLoader: "none",
                            btnColorCD: "#b00000",
                            btnColor: "#fff3f3",
                            fntColor: "rgb(108, 108, 108)",
                            borderBtn: "3px solid #b00000",
                            bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                            bgColor: "#fff8f82f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png",
                            bgColorBox2: "#ff4f4f15",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png",
                            links: [
                                {
                                    url: "https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5045257.htm#page=3&query=read%20leaf%20background&position=14&from_view=search&track=ais",
                                    pemilik: "freepik",
                                },
                                {
                                    url: "https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5020958.htm#page=7&query=red%20leaf%20background&position=34&from_view=search&track=ais",
                                    pemilik: "freepik",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        adorable: {
                            pajangan: "",
                            displayLoader: "none",
                            btnColorCD: "#00b07e",
                            btnColor: "#f3fff8",
                            fntColor: "rgb(0, 58, 41)",
                            borderBtn: "3px solid #00b07e",
                            bgCover: "linear-gradient(to bottom,rgb(221, 255, 237),rgb(221, 255, 237))",
                            bgColor: "#f8fffd2f",
                            bgImageBox1: "",
                            bgColorBox2: "#4fffc115",
                            bgImageBox2: "",
                            links: [
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        cute: {
                            pajangan: "",
                            displayLoader: "none",
                            btnColorCD: "rgb(176, 0, 135)",
                            btnColor: "#fff3fe",
                            fntColor: "rgb(61, 0, 47)",
                            borderBtn: "3px solid rgb(176, 0, 135)",
                            bgCover: "linear-gradient(to bottom, #f1c9fe,rgb(245, 214, 255))",
                            bgColor: "#fff8fc2f",
                            bgImageBox1: "",
                            bgColorBox2: "#ff4fb915",
                            bgImageBox2: "",
                            links: [
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        beauty: {
                            pajangan: "",
                            displayLoader: "none",
                            btnColorCD: "rgb(176, 59, 0)",
                            btnColor: "#fff7f3",
                            fntColor: "rgb(68, 26, 0)",
                            borderBtn: "3px solid rgb(176, 59, 0)",
                            bgCover: "linear-gradient(to bottom, #fed5c9,rgb(255, 225, 215))",
                            bgColor: "#fffbf82f",
                            bgImageBox1: "",
                            bgColorBox2: "#ffa44f15",
                            bgImageBox2: "",
                            links: [
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        cool: {
                            pajangan: "",
                            displayLoader: "none",
                            btnColorCD: "rgb(0, 147, 176)",
                            btnColor: "#f3fcff",
                            fntColor: "rgb(0, 46, 55)",
                            borderBtn: "3px solid rgb(0, 147, 176)",
                            bgCover: "linear-gradient(to bottom, rgb(212, 240, 255),rgb(212, 240, 255))",
                            bgColor: "#f8ffff2f",
                            bgImageBox1: "",
                            bgColorBox2: "#4fdcff15",
                            bgImageBox2: "",
                            links: [
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        blegold: {
                            pajangan: "",
                            displayLoader: "none",
                            btnColorCD: "rgb(176, 135, 0)",
                            btnColor: "#392f00",
                            fntColor: "rgb(255, 220, 80)",
                            borderBtn: "3px solid rgb(176, 135, 0)",
                            borderBoxLeft: "3px solid rgb(255, 204, 0)",
                            borderBoxRight: "3px solid rgb(255, 204, 0)",
                            bgCover: "linear-gradient(to bottom,rgb(70, 61, 0),rgb(0, 0, 0))",
                            bgColor: "#fffef82f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2G.png",
                            bgColorBox2: "#ffed4f15",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5G.png",
                            links: [
                                {
                                    url: "https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph",
                                    pemilik: "macrovector",
                                },
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        blepink: {
                            pajangan: "",
                            displayLoader: "none",
                            btnColorCD: "rgb(255, 44, 209)",
                            btnColor: "rgb(67, 0, 62)",
                            fntColor: "rgb(255, 169, 249)",
                            borderBtn: "3px solid rgb(255, 190, 237)",
                            borderBoxLeft: "3px solid rgb(255, 190, 237)",
                            borderBoxRight: "3px solid rgb(255, 190, 237)",
                            bgCover: "linear-gradient(to bottom,rgb(74, 0, 77),rgb(0, 0, 0))",
                            bgColor: "#fff8fd2f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2P.png",
                            bgColorBox2: "#ff4fd015",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5P.png",
                            links: [
                                {
                                    url: "https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph",
                                    pemilik: "macrovector",
                                },
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        bletosca: {
                            pajangan: "",
                            displayLoader: "none",
                            btnColorCD: "rgb(0, 130, 133)",
                            btnColor: "#004748",
                            fntColor: "rgb(185, 254, 255)",
                            borderBtn: "3px solid rgb(0, 251, 255)",
                            borderBoxLeft: "3px solid rgb(0, 247, 255)",
                            borderBoxRight: "3px solid rgb(0, 247, 255)",
                            bgCover: "linear-gradient(to bottom,rgb(0, 62, 64),rgb(0, 0, 0))",
                            bgColor: "#f8ffff2f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2T.png",
                            bgColorBox2: "#4ffcff15",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5T.png",
                            links: [
                                {
                                    url: "https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph",
                                    pemilik: "macrovector",
                                },
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        blewhite: {
                            pajangan: "",
                            displayLoader: "none",
                            btnColorCD: "rgb(0, 0, 0)",
                            btnColor: "rgb(73, 73, 73)",
                            fntColor: "rgb(255, 255, 255)",
                            borderBtn: "3px solid rgb(255, 255, 255)",
                            borderBoxLeft: "3px solid rgb(255, 255, 255)",
                            borderBoxRight: "3px solid rgb(255, 255, 255)",
                            bgCover: "linear-gradient(to bottom,rgb(76, 76, 76),rgb(0, 0, 0))",
                            bgColor: "#ffffff2f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2W.png",
                            bgColorBox2: "#ffffff15",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5W.png",
                            links: [
                                {
                                    url: "https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph",
                                    pemilik: "macrovector",
                                },
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        bnwI: {
                            pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png",
                            displayLoader: "none",
                            btnColorCD: "rgb(108, 108, 108)",
                            btnColor: "white",
                            fntColor: "rgb(108, 108, 108)",
                            borderBtn: "3px solid rgb(108, 108, 108)",
                            bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                            bgColor: "#e8e8e82f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb1.png",
                            bgColorBox2: "#43434315",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb2.png",
                            links: [
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-vector/elegant-wedding-decorative-floral-background_9853423.htm#query=black%20and%20white%20flower%20background&position=10&from_view=search&track=ais",
                                    pemilik: "Harryarts",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        bnwII: {
                            pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png",
                            displayLoader: "none",
                            btnColorCD: "rgb(108, 108, 108)",
                            btnColor: "white",
                            fntColor: "rgb(108, 108, 108)",
                            borderBtn: "3px solid rgb(108, 108, 108)",
                            bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                            bgColor: "#e8e8e82f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb5.png",
                            bgColorBox2: "#43434315",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb4.png",
                            links: [
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-vector/beautiful-hand-drawn-wedding-ornament-floral-background_9853397.htm#page=3&query=black%20and%20white%20flower%20background&position=3&from_view=search&track=ais",
                                    pemilik: "Harryarts",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        bnwIII: {
                            pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png",
                            displayLoader: "none",
                            btnColorCD: "rgb(108, 108, 108)",
                            btnColor: "white",
                            fntColor: "rgb(108, 108, 108)",
                            borderBtn: "3px solid rgb(108, 108, 108)",
                            bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                            bgColor: "#e8e8e82f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb8.png",
                            bgColorBox2: "#43434315",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png",
                            links: [
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-vector/hand-drawn-spring-background_12806202.htm#query=black%20and%20white%20flower%20background&position=38&from_view=search&track=ais",
                                    pemilik: "Freepik",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        },
                        bnwIV: {
                            pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png",
                            displayLoader: "none",
                            btnColorCD: "rgb(108, 108, 108)",
                            btnColor: "white",
                            fntColor: "rgb(108, 108, 108)",
                            borderBtn: "3px solid rgb(108, 108, 108)",
                            bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                            bgColor: "#e8e8e82f",
                            bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb10.png",
                            bgColorBox2: "#43434315",
                            bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb11.png",
                            links: [
                                {
                                    url: "https://webew.id",
                                    pemilik: "Webew.id",
                                },
                                {
                                    url: "https://www.freepik.com/free-vector/floral-background-with-sketchy-style_2416402.htm#page=5&query=black%20and%20white%20flower%20background&position=13&from_view=search&track=ais",
                                    pemilik: "Freepik",
                                },
                                {
                                    url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",
                                    pemilik: "Freepik",
                                },
                            ],
                            rotate: "rotate(-180deg)",
                        }
                    };
                    const temaTerpilih = tema[response.data.jenisUndanganDigitalPernikahan] || {}
                    // DATA PERNIKAHAN
                    setDataWePernikahan({
                        // TAMPILKAN DULUAN
                        jenisWePernikahan: response.data.jenisUndanganDigitalPernikahan,
                        tema:temaTerpilih,
                        bgImageCover: `https://server13.webew.id/imagesUndanganDigitalPernikahan/${response.data.urlBgImageCover}`,
                        musik: response.data.musik,
                        panggilan: response.data.panggilan,
                        salinLink: response.data.salinLink,

                        namaPanggilanPengantin1: "",
                        namaPanggilanPengantin2: "",
                        hariAkad: "",
                        tanggalAkad: "",
                        jamMulaiAkad: "",
                        jamAkhirAkad: "",
                        hariResepsi: "",
                        tanggalResepsi: "",
                        jamMulaiResepsi: "",
                        jamAkhirResepsi: "",

                        imagePengantin1: "",
                        imagePengantin2: "",
                        namaPengantin1: "",
                        namaSosmedPengantin1: "",
                        namaPengantin2: "",
                        namaSosmedPengantin2: "",
                        kelaminPengantin1: "",
                        kelaminPengantin2: "",
                        urutanPengantin1: "",
                        urutanPengantin2: "",
                        namaBapakPengantin1: "",
                        namaBapakPengantin2: "",
                        namaIbuPengantin1: "",
                        namaIbuPengantin2: "",

                        zonaWaktuAkad: "",
                        zonaWaktuResepsi: "",
                        alamatAkad: "",
                        linkMapsAlamatAkad: "",
                        alamatResepsi: "",
                        linkMapsAlamatResepsi: "",
                        ayat: "",
                        surah: "",

                        imageGallery1: "",
                        imageGallery2: "",
                        imageGallery3: "",
                        imageGallery4: "",
                        imageGallery5: "",
                        imageGallery6: "",
                        namaBank1: "",
                        namaPemilikBank1: "",
                        norekBank1: "",
                        namaBank2: "",
                        namaPemilikBank2: "",
                        norekBank2: "",
                    })
                    // JEDA 3 DETIK
                    timeoutsRef.current.push(setTimeout(() => {
                        setDataWePernikahan((prev) => ({
                            ...prev,
                            namaPanggilanPengantin1: response.data.namaPanggilanPengantin1,
                            namaPanggilanPengantin2: response.data.namaPanggilanPengantin2,
                            hariAkad: response.data.hariAkad,
                            tanggalAkad: response.data.tanggalAkad,
                            jamMulaiAkad: response.data.jamMulaiAkad,
                            jamAkhirAkad: response.data.jamAkhirAkad,
                            hariResepsi: response.data.hariResepsi,
                            tanggalResepsi: response.data.tanggalResepsi,
                            jamMulaiResepsi: response.data.jamMulaiResepsi,
                            jamAkhirResepsi: response.data.jamAkhirResepsi,
                        }));
                    }, 3000));
                    // JEDA 5 DETIK
                    timeoutsRef.current.push(setTimeout(() => {
                        setDataWePernikahan((prev) => ({
                            ...prev,
                            imagePengantin1: `https://server13.webew.id/imagesUndanganDigitalPernikahan/${response.data.urlImagePengantin1}`,
                            imagePengantin2: `https://server13.webew.id/imagesUndanganDigitalPernikahan/${response.data.urlImagePengantin2}`,
                            namaPengantin1: response.data.namaPengantin1,
                            namaSosmedPengantin1: response.data.namaSosmedPengantin1,
                            namaPengantin2: response.data.namaPengantin2,
                            namaSosmedPengantin2: response.data.namaSosmedPengantin2,
                            kelaminPengantin1: response.data.kelaminPengantin1,
                            kelaminPengantin2: response.data.kelaminPengantin2,
                            urutanPengantin1: response.data.urutanPengantin1,
                            urutanPengantin2: response.data.urutanPengantin2,
                            namaBapakPengantin1: response.data.namaBapakPengantin1,
                            namaBapakPengantin2: response.data.namaBapakPengantin2,
                            namaIbuPengantin1: response.data.namaIbuPengantin1,
                            namaIbuPengantin2: response.data.namaIbuPengantin2,
                        }));
                    }, 5000));
                    // JEDA 9 DETIK
                    timeoutsRef.current.push(setTimeout(() => {
                        setDataWePernikahan((prev) => ({
                            ...prev,
                            zonaWaktuAkad: response.data.zonaWaktuAkad,
                            zonaWaktuResepsi: response.data.zonaWaktuResepsi,
                            alamatAkad: response.data.alamatAkad,
                            linkMapsAlamatAkad: response.data.linkMapsAlamatAkad,
                            alamatResepsi: response.data.alamatResepsi,
                            linkMapsAlamatResepsi: response.data.linkMapsAlamatResepsi,
                            ayat: response.data.ayat,
                            surah: response.data.surah,
                        }));
                    }, 9000));
                    // JEDA 12 DETIK
                    timeoutsRef.current.push(setTimeout(() => {
                        setDataWePernikahan((prev) => ({
                            ...prev,
                            imageGallery1: `https://server13.webew.id/imagesUndanganDigitalPernikahan/${response.data.urlImageGallery1}`,
                            imageGallery2: `https://server13.webew.id/imagesUndanganDigitalPernikahan/${response.data.urlImageGallery2}`,
                            imageGallery3: `https://server13.webew.id/imagesUndanganDigitalPernikahan/${response.data.urlImageGallery3}`,
                            imageGallery4: `https://server13.webew.id/imagesUndanganDigitalPernikahan/${response.data.urlImageGallery4}`,
                            imageGallery5: `https://server13.webew.id/imagesUndanganDigitalPernikahan/${response.data.urlImageGallery5}`,
                            imageGallery6: `https://server13.webew.id/imagesUndanganDigitalPernikahan/${response.data.urlImageGallery6}`,
                            namaBank1: response.data.namaBank1,
                            namaPemilikBank1: response.data.namaPemilikBank1,
                            norekBank1: response.data.norekBank1,
                            namaBank2: response.data.namaBank2,
                            namaPemilikBank2: response.data.namaPemilikBank2,
                            norekBank2: response.data.norekBank2,
                        }));
                    }, 12000));
                }
                setProgress(100);
                setTimeout(() => setLoading(false), 500);
            } catch (error) {
                setProgress(100);
                setTimeout(() => setLoading(false), 500);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            }
        };
        if (salinLink) {
            fetchData();
        }
        // Cleanup jika component unmount sebelum selesai
        return () => {
            // Hapus semua timeout saat komponen unmount atau dependen berubah
            timeoutsRef.current.forEach(clearTimeout);
            timeoutsRef.current = [];
        }
    }, [salinLink]);
    
    const targetDate = `${dataWePernikahan?.tanggalAkad}T${dataWePernikahan?.jamMulaiAkad?.replace(/^(\d{2}).*$/, '$1')}:00:00`;
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());    

    // EVENT COUNTDOWN
    function calculateTimeLeft() {
        const difference = new Date(targetDate) - new Date();
        if (difference > 0) {
            return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return null;
    }

    // USEEFFECT COUNTDOWN
    useEffect(() => {
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            if (newTimeLeft) {
                setTimeLeft(newTimeLeft);
            } else {
                clearInterval(timer);
            }
        }, 1000);
        return () => clearInterval(timer); // Membersihkan interval saat komponen dilepas
    }, [targetDate]); // Bergantung pada targetDate
    
    // USEEFFECT KALENDER
    useEffect(() => {
        if (!dataWePernikahan?.tanggalAkad || !dataWePernikahan?.jamMulaiAkad || !dataWePernikahan?.jamAkhirAkad || !dataWePernikahan?.tanggalResepsi || !dataWePernikahan?.jamMulaiResepsi || !dataWePernikahan?.jamAkhirResepsi) return;
        const formatTanggal = (tanggal, jam) => tanggal.replace(/[^\w\s]/gi, '') + "T" + jam.replace(/[^\w\s]/gi, '') + "00";
        const tanggalAwalAkad = formatTanggal(dataWePernikahan?.tanggalAkad, dataWePernikahan?.jamMulaiAkad);
        const tanggalAkhirAkad = formatTanggal(dataWePernikahan?.tanggalAkad, dataWePernikahan?.jamAkhirAkad);
        setKalenderAkad(
            `https://calendar.google.com/calendar/render?action=TEMPLATE&text=Pernikahan ${dataWePernikahan?.namaPanggilanPengantin1} dan ${dataWePernikahan?.namaPanggilanPengantin2}&details=Kami mengharapkan kehadiran para tamu undangan di acara kami yang berbahagia ini yaitu pernikahan (Akad) kami ${dataWePernikahan?.namaPanggilanPengantin1} dan ${dataWePernikahan?.namaPanggilanPengantin2} pada hari ${dataWePernikahan?.hariAkad}, ${dataWePernikahan?.tanggalAkad}, dari jam ${dataWePernikahan?.jamMulaiAkad} s/d ${dataWePernikahan?.jamAkhirAkad}. Ditunggu kehadirannya yaaa&dates=${tanggalAwalAkad}/${tanggalAkhirAkad}&location=Indonesia`
        );
        const tanggalAwalResepsi = formatTanggal(dataWePernikahan?.tanggalResepsi, dataWePernikahan?.jamMulaiResepsi);
        const tanggalAkhirResepsi = formatTanggal(dataWePernikahan?.tanggalResepsi, dataWePernikahan?.jamAkhirResepsi);
        setKalenderResepsi(
            `https://calendar.google.com/calendar/render?action=TEMPLATE&text=Pernikahan ${dataWePernikahan?.namaPanggilanPengantin1} dan ${dataWePernikahan?.namaPanggilanPengantin2}&details=Kami mengharapkan kehadiran para tamu undangan di acara kami yang berbahagia ini yaitu pernikahan (Resepsi) kami ${dataWePernikahan?.namaPanggilanPengantin1} dan ${dataWePernikahan?.namaPanggilanPengantin2} pada hari ${dataWePernikahan?.hariResepsi}, ${dataWePernikahan?.tanggalResepsi}, dari jam ${dataWePernikahan?.jamMulaiResepsi} s/d ${dataWePernikahan?.jamAkhirResepsi}. Ditunggu kehadirannya yaaa&dates=${tanggalAwalResepsi}/${tanggalAkhirResepsi}&location=Indonesia`
        );
    }, [dataWePernikahan?.tanggalAkad, dataWePernikahan?.jamMulaiAkad, dataWePernikahan?.jamAkhirAkad, dataWePernikahan?.tanggalResepsi, dataWePernikahan?.jamMulaiResepsi, dataWePernikahan?.jamAkhirResepsi]); 
    
    // USEEFFECT MUSIK
    useEffect(() => {
        const musicLinks = {
            "All Of Me - John Legend": "https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe",
            "A Thousand Years - Christina Perri": "https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY",
            "Beautiful In White - Shane Filan": "https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx",
            "Because You Loved Me - Celine Dion": "https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb",
            "Cant Help Falling In Love - Elvis Presley": "https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6",
            "Cant Help Falling In Love - Alexandra Porat Cover": "https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk",
            "Close To You - Carpenters": "https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB",
            "Endless Love - Luther Vandross": "https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG",
            "Everything I Do - Bryan Adams": "https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614",
            "I Love You - Celine Dion": "https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU",
            "I Wanna Grow Old With You - Westlife": "https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN",
            "Lucky - Jason Mraz": "https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87",
            "Make You Feel My Love - Adele": "https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi",
            "Marry You - Bruno Mars": "https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf",
            "My Love - Westlife": "https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK",
            "Perfect - Ed Sheeran": "https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro",
            "Since I Found You - Christian Bautista": "https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh4AXSAboh-",
            "The Power Of Love - Celine Dion": "https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4",
            "The Way You Look Me - Christian Bautista": "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",
            "Thinking Out Loud - Ed Sheeran": "https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI",
            "When You Tell Me That You Love Me - Westlife Ft Diana Ross": "https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E",
            "You Are The Reason - Calum Scott": "https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE",
            "Akad - Payung Teduh": "https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX",
            "Akhirnya Kumenemukanmu - Naff": "https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-",
            "Aku Dan Dirimu - Arilaso Ft BCL": "https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9",
            "Aku Makin Cinta - Vina Panduwinata": "https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK",
            "Berawal Dari Tatap - Yura Yunita": "https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub",
            "Berdua Saja - Payung Teduh": "https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz",
            "Bukan Cinta Biasa - Afgan": "https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax",
            "Cinta luar Biasa - Andmesh Kamaleng": "https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s",
            "Cinta Pertama Dan Terakhir - Sherina": "https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz",
            "Cinta Sejati - BCL": "https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF",
            "Cinta Terakhir - Arilaso": "https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64",
            "Jani Suci - Yovie & Nuno": "https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR",
            "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina": "https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA",
            "Karena Cinta - Glenn Fredly": "https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr",
            "Kasih Putih - Glenn Fredly": "https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq",
            "Kesempurnaan Cinta - Rizky Febian": "https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a",
            "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto": "https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c",
            "Malaikat Juga Tahu - Glenn Fredly": "https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8",
            "Menikahimu - Kahitna": "https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D",
            "Sedalam Cinta - Indra Lesmana Ft Nania": "https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW",
            "Teman Hidup - Tulus": "https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF",
            "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee": "https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ",
        };
        if (musicLinks[dataWePernikahan?.musik]) {
            setLink4(musicLinks[dataWePernikahan?.musik]);
        }
    }, [dataWePernikahan?.musik]); // Hanya dijalankan jika 'musik' berubah
    
    // USEEFFECT ANIMASI SCROLL
    useEffect(() => {
        if (!Array.isArray(elementsRef.current[0])) {
            elementsRef.current[0] = [];
        }
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        animateElement(entry.target);
                    }
                });
            },
            {
                threshold: 0,
                rootMargin: "0px 0px 0px 0px",
            }
        );
        elementsRef.current[0].forEach((el) => observer.observe(el));
        return () => observer.disconnect();
    }, []);

    // ANIMASI SCROLL
    const animateElement = (element) => {
        let start = null;
        const duration = 1200; // Durasi animasi dalam milidetik
        const initialScale = 0.5; // Ukuran awal (kecil)
        const targetScale = 1; // Ukuran akhir (normal)
        const initialOpacity = 0; // Transparan awal
        const targetOpacity = 1; // Transparan akhir
        const step = (timestamp) => {
          if (!start) start = timestamp;
          const progress = Math.min((timestamp - start) / duration, 1); // Hitung progres animasi (0 ke 1)
          // Lerp (Linear Interpolation) untuk scale dan opacity
          const currentScale = initialScale + (targetScale - initialScale) * progress;
          const currentOpacity = initialOpacity + (targetOpacity - initialOpacity) * progress;
          // Atur gaya elemen
          element.style.transform = `scale(${currentScale})`;
          element.style.opacity = currentOpacity;
          if (progress < 1) {
            requestAnimationFrame(step);
          }
        };
        requestAnimationFrame(step);
    };

    // PROSES MENYIMPAN UCAPAN WE PERNIKAHAN
    const createUcapanWePernikahan = async(e)=>{
        e.preventDefault()
        try {
            await axios.post('https://server13.webew.id/createUcapanWePernikahanBE',{
                nama,
                status,
                ucapan,
                salinLink: dataWePernikahan?.salinLink,
                panggilan: dataWePernikahan?.panggilan
            })

            setDataUcapan((prevUcapan) => [
                { nama, ucapan },
                ...prevUcapan
            ]);

            setMsgUcapan("Ucapan Berhasil Dikirim")
            setNama("")
            setStatus("")
            setUcapan("")
            
            setTimeout(() => setMsgUcapan(""), 2000);
        } catch (error) {
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    
    // BUTTON UCAPAN PERNIKAHAN LIVE
    const handleUcapan = async () => {
        try {
            const timeoutId = setTimeout(async () => {
                try {
                    const response = await axios.get(
                        `https://server13.webew.id/selectUcapanWePernikahanLiveBE/${salinLink}`
                    );
                    setDataUcapan(response.data);
                } catch (error) {
                    if (error.response) {
                        setMsg(error.response.data.msg);
                    }
                }
            }, 12000);
            // Pastikan timeoutsRef.current sudah ada sebelum push
            if (timeoutsRef.current) {
                timeoutsRef.current.push(timeoutId);
            }
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }; 

    // BUTTON BUKA UNDANGAN
    const handleBukaUndangan = () => {
        handleUcapan()
        audioRef.current.play()
        setIsPlaying(true)
        setShowPage(false);
        setShowMainPage(true);
        setBukaUndangan("");
    }

    // BUTTON PLAY MUSSIC
    const handlePlay = () => {
        audioRef.current.play()
        setIsPlaying(true)
    }
    
    // BUTTON PAUSE MUSIC
    const handlePause = () => {
        audioRef.current.pause()
        setIsPlaying(false)
    }
    
    // BUTTON PLAY N PAUSE MUSIC
    const handlePlayPause = () => {
        if (isPlaying) {
            handlePause()
        } else {
            handlePlay()
        }
    }
    
    // PROSES SALIN REKENING 1
    function salinRek1(e) {
        inputNoRek1.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    
    // PROSES SALIN REKENING 2
    function salinRek2(e) {
        inputNoRek2.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    
    // PROSES MENGUBAH FORMAT TANGGAL
    function formatDateToIndonesian(date) {
        const months = [
            "Januari", "Februari", "Maret", "April", "Mei", "Juni",
            "Juli", "Agustus", "September", "Oktober", "November", "Desember"
          ];
      
          if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
            return "13 Mei 2022";
          }
          const [year, month, day] = date.split("-");
          const monthName = months[parseInt(month, 10) - 1];
          return `${parseInt(day, 10)} ${monthName} ${year}`;
    }
    
    // CLASSNAME PAJANGAN 
    const kupuKupu = [
        "moving-image-1",
        "moving-image-2",
        "moving-image-3",
        "moving-image-4",
        "moving-image-5",
    ];
    const daun = [
        "moving-image-6",
        "moving-image-7",
        "moving-image-8",
        "moving-image-9",
        "moving-image-10",
    ];
    
    return (
        <div className="background-home">

            {/* COVER UNDANGAN 1 */}
            {loading && <LoadingPage progress={progress} />}
            <section style={{display:dataWePernikahan?.bgImageCover ? "":"none"}}>

            {/* COVER UNDANGAN 2 */}
            <AnimatePresence>
                {showPage && (
                    <div style={{position:"fixed",minHeight:"100vh",width:"100%",background: `linear-gradient(to top, ${dataWePernikahan?.tema?.btnColorCD} -100%, ${dataWePernikahan?.tema?.bgColorBox2}) 100%`,zIndex:"1",overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight:"100vh",width:"100%",zIndex:"2"}}>
                                <motion.div className="px-4 py-4" style={{backgroundImage:[dataWePernikahan?.tema?.bgCover],height:"auto",width:"auto",borderRadius:"20px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}initial={{ scale: 0.1 }}animate={{ scale: 1 }}exit={{ opacity: 0, y:-500 }}transition={{ duration: 1 }}>
                                    <img loading="lazy" src={dataWePernikahan?.bgImageCover} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:`0px 10px 25px -14px ${dataWePernikahan?.tema?.btnColorCD}`}} alt="pengantin" />
                                    <h5 className='fw-bold bad-script-regular text-center' style={{marginTop:"30px",color:[dataWePernikahan?.tema?.fntColor]}}>Kepada Yth. Bpk/Ibu/Saudara/i</h5>
                                    <p className='fw-normal courgette-regular m-0 text-center' style={{color:[dataWePernikahan?.tema?.fntColor],fontSize:"40pt"}}>{namaTamu}</p>
                                    <button onClick={handleBukaUndangan} className="form form-control fw-bold bad-script-regular" style={{backgroundColor:[dataWePernikahan?.tema?.btnColor],padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.tema?.btnColorCD}`,color:[dataWePernikahan?.tema?.fntColor],border:[dataWePernikahan?.tema?.borderBtn],fontSize:"14pt"}}><i className="bi bi-envelope-paper-heart-fill"></i> Buka Undangan <i className="bi bi-envelope-paper-heart-fill"></i></button>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                )}
            </AnimatePresence>

            {/* AUDIO */}
            <div className="fixed-bottom" style={{display:bukaUndangan}}>
                <audio ref={audioRef} src={`/song/${dataWePernikahan?.musik}.mp3`} autoPlay loop preload='none'/>
                <button onClick={handlePlayPause} style={{width:"100%",height:"auto",border:"none",borderTopLeftRadius:"30px",borderTopRightRadius:"30px",background: `linear-gradient(-45deg, ${dataWePernikahan?.tema?.btnColorCD} -200%, ${dataWePernikahan?.tema?.btnColor} 70%)`,borderTop:[dataWePernikahan?.tema?.borderBtn]}}>
                    {isPlaying ? <p style={{color:[dataWePernikahan?.tema?.fntColor],fontWeight:"600",margin:"0",padding:"0",fontSize:"10pt"}}><i className="bi bi-pause-circle" style={{fontSize:"18px"}}></i> {dataWePernikahan?.musik} </p>:<p style={{color:[dataWePernikahan?.tema?.fntColor],fontWeight:"600",margin:"0",padding:"0",fontSize:"10pt"}}><i className="bi bi-play-circle" style={{fontSize:"18px"}}></i> {dataWePernikahan?.musik}</p>}                                           
                </button>
            </div>

            {/* COVER */}
            <section style={{overflow:"auto",height:"100vh"}}>
                <section style={{backgroundImage:"url('"+`${dataWePernikahan?.bgImageCover}`+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                    <div style={{background: `linear-gradient(to top, ${dataWePernikahan?.tema?.btnColorCD} -100%, ${dataWePernikahan?.tema?.bgColor} 100%)`,width:"100%",height:"100vh"}}>
                        <AnimatePresence>

                            {showMainPage && (
                                <motion.div initial={{ y:500 }}animate={{ y:0 }}transition={{ duration: 1 }}>
                                    <div className="marginTopWePernikahan" style={{width:"100%",marginBottom:"18px",textAlign:"center",textShadow:`0 0 20px ${dataWePernikahan?.tema?.btnColorCD}`,display:[bukaUndangan]}}>
                                        <p className='fw-bold courgette-regular' style={{color:"white",fontSize:"40pt"}}>{dataWePernikahan?.namaPanggilanPengantin1} & {dataWePernikahan?.namaPanggilanPengantin2}</p>
                                        <h2 className='fw-bold bad-script-regular' style={{color:"white",fontVariant:"small-caps", marginTop:"-25px"}}>{dataWePernikahan?.hariAkad}, {formatDateToIndonesian(dataWePernikahan?.tanggalAkad)}</h2>
                                    </div>
                                    
                                    {timeLeft ? (
                                        <div className='px-1' style={{width: "100%", marginBottom:"18px" }}>
                                            <table style={{ textAlign: "center", width: "100%" }}>
                                                <tbody>
                                                    <tr style={{ textAlign: "center", width: "100%" }}>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.tema?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.tema?.btnColorCD} -200%, ${dataWePernikahan?.tema?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.tema?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>{timeLeft.days}</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Hari</div>
                                                        </td>
                                                        <td style={{ width: "2%" }}></td>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.tema?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.tema?.btnColorCD} -200%, ${dataWePernikahan?.tema?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.tema?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>{timeLeft.hours}</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Jam</div>
                                                        </td>
                                                        <td style={{ width: "2%" }}></td>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.tema?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.tema?.btnColorCD} -200%, ${dataWePernikahan?.tema?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.tema?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>{timeLeft.minutes}</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Menit</div>
                                                        </td>
                                                        <td style={{ width: "2%" }}></td>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.tema?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.tema?.btnColorCD} -200%, ${dataWePernikahan?.tema?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.tema?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>{timeLeft.seconds}</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Detik</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className='px-1' style={{width: "100%", marginBottom:"18px" }}>
                                            <table style={{ textAlign: "center", width: "100%" }}>
                                                <tbody>
                                                    <tr style={{ textAlign: "center", width: "100%" }}>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.tema?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.tema?.btnColorCD} -200%, ${dataWePernikahan?.tema?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.tema?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Hari</div>
                                                        </td>
                                                        <td style={{ width: "2%" }}></td>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.tema?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.tema?.btnColorCD} -200%, ${dataWePernikahan?.tema?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.tema?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Jam</div>
                                                        </td>
                                                        <td style={{ width: "2%" }}></td>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.tema?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.tema?.btnColorCD} -200%, ${dataWePernikahan?.tema?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.tema?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Menit</div>
                                                        </td>
                                                        <td style={{ width: "2%" }}></td>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.tema?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.tema?.btnColorCD} -200%, ${dataWePernikahan?.tema?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.tema?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Detik</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}

                                </motion.div>
                            )}

                        </AnimatePresence>
                    </div>
                </section>
                <section style={{display:bukaUndangan}}>

                    {/* BOX 1 */}
                    <section style={{backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox1} className='w-100' style={{transform:`${dataWePernikahan?.tema?.rotate}`}} />
                        <p className='text-center fw-bold m-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],fontFamily:"initial",fontSize:"23pt"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</p>
                        <h6 className='text-center fw-normal m-0 mb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor]}}>Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{width:"45%"}}><img loading="lazy" src={dataWePernikahan?.imagePengantin1} className='img-fluid' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",minHeight:"210px",maxHeight:"210px",borderRadius:"30px",boxShadow:`10px 10px 25px -18px ${dataWePernikahan?.tema?.btnColorCD}`,borderLeft:[dataWePernikahan?.borderBoxLeft]}} alt="calon pengantin pria" /></td>
                                    <td style={{width:"5%"}}><div className="text-center bi bi-suit-heart-fill" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"25px",color:[dataWePernikahan?.tema?.fntColor]}}></div></td>
                                    <td style={{width:"45%"}}><img loading="lazy" src={dataWePernikahan?.imagePengantin2} className='img-fluid' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",minHeight:"210px",maxHeight:"210px",borderRadius:"30px",boxShadow:`-10px 10px 25px -18px ${dataWePernikahan?.tema?.btnColorCD}`,borderRight:[dataWePernikahan?.borderBoxRight]}} alt="calon pengantin Wanita" /></td>
                                </tr>
                            </tbody>    
                        </table>
                        <div className="container-fluid">
                            <div className="row justify-content-center mt-3">

                                <div className="col-12 col-md-5">
                                    <p className='text-center fw-bold courgette-regular m-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],fontSize:[dataWePernikahan?.namaPengantin1?.length > 23 ?"22pt":"28pt"]}}>{dataWePernikahan?.namaPengantin1}</p>
                                    <h6 className='text-center fw-normal bad-script-regular opacity-75'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],marginBottom:"0"}}><i className="bi bi-instagram"style={{color:[dataWePernikahan?.tema?.fntColor],fontSize:"9pt"}}></i>  <a href={`https://instagram.com/${dataWePernikahan?.namaSosmedPengantin1}`}>{dataWePernikahan?.namaSosmedPengantin1}</a></h6>
                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor]}}>{dataWePernikahan?.kelaminPengantin1} {dataWePernikahan?.urutanPengantin1} dari<br/>Bapak {dataWePernikahan?.namaBapakPengantin1} & Ibu {dataWePernikahan?.namaIbuPengantin1}</h6>
                                </div>

                                <div className="col-12 col-md-1"><div className="text-center fw-normal courgette-regular mt-2" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"25pt",color:[dataWePernikahan?.tema?.fntColor]}}>&</div></div>

                                <div className="col-12 col-md-5">
                                    <p className='text-center fw-bold courgette-regular m-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],fontSize:[dataWePernikahan?.namaPengantin2?.length > 23 ?"22pt":"28pt"]}}>{dataWePernikahan?.namaPengantin2}</p>
                                    <h6 className='text-center fw-normal bad-script-regular opacity-75'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],marginBottom:"0"}}><i className="bi bi-instagram"style={{color:[dataWePernikahan?.tema?.fntColor],fontSize:"9pt"}}></i> <a href={`https://instagram.com/${dataWePernikahan?.namaSosmedPengantin2}`}>{dataWePernikahan?.namaSosmedPengantin2}</a></h6>
                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor]}}>{dataWePernikahan?.kelaminPengantin2} {dataWePernikahan?.urutanPengantin2} dari<br/>Bapak {dataWePernikahan?.namaBapakPengantin2} & Ibu {dataWePernikahan?.namaIbuPengantin2}</h6>
                                </div>

                            </div>
                        </div>
                        <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox1} className='w-100'/>
                    </section>

                    {/* BOX 2 */}
                    <section style={{backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:`${dataWePernikahan?.tema?.bgColorBox2}`,width:"100%",height:"auto"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                            <div className="px-3 pt-3">
                                                <div className='bi bi-calendar2-event text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.tema?.fntColor]}}></div>
                                                <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Akad Nikah</p>
                                                <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor]}}>{dataWePernikahan?.hariAkad}</h6>
                                                <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[dataWePernikahan?.tema?.fntColor]}}>{formatDateToIndonesian(dataWePernikahan?.tanggalAkad)}</h6>
                                                <h6 className='text-center fw-normal pb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[dataWePernikahan?.tema?.fntColor]}}>{dataWePernikahan?.jamMulaiAkad} sd {dataWePernikahan?.dataWePernikahan?.jamAkhirAkad} {dataWePernikahan?.zonaWaktuAkad}</h6>
                                                <a href={kalenderAkad} aria-label="saveCalendar">
                                                    <button className="form form-control fw-bold mb-4 mt-3 px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[dataWePernikahan?.tema?.btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.tema?.btnColorCD}`,color:[dataWePernikahan?.tema?.fntColor],border:[dataWePernikahan?.tema?.borderBtn],fontSize:"12pt"}}><i className="bi bi-calendar-heart"></i> Simpan Kalender</button>
                                                </a>
                                            </div>
                                            <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox2} className='w-100' />
                                        </div>
                                    </div>

                                    <div className="col-md-1"></div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                            <div className="px-3 pt-3">
                                                <div className='bi bi-calendar2-event text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.tema?.fntColor]}}></div>
                                                <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Resepsi Nikah</p>
                                                <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor]}}>{dataWePernikahan?.hariResepsi}</h6>
                                                <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[dataWePernikahan?.tema?.fntColor]}}>{formatDateToIndonesian(dataWePernikahan?.tanggalResepsi)}</h6>
                                                <h6 className='text-center fw-normal pb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[dataWePernikahan?.tema?.fntColor]}}>{dataWePernikahan?.jamMulaiResepsi} sd {dataWePernikahan?.jamAkhirResepsi} {dataWePernikahan?.zonaWaktuResepsi}</h6>
                                                <a href={kalenderResepsi} aria-label="saveCalendar">
                                                    <button className="form form-control fw-bold mb-4 mt-3 px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[dataWePernikahan?.tema?.btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.tema?.btnColorCD}`,color:[dataWePernikahan?.tema?.fntColor],border:[dataWePernikahan?.tema?.borderBtn],fontSize:"12pt"}}><i className="bi bi-calendar-heart"></i> Simpan Kalender</button>
                                                </a>
                                            </div>
                                            <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox2} className='w-100' />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    {/* BOX 3 */}
                    <section style={{backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox1} className='w-100' style={{transform:`${dataWePernikahan?.tema?.rotate}`}} />
                        <div className='bi bi-map text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.tema?.fntColor]}}></div>
                        <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Alamat Nikah</p>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                
                                <div className="col-12 col-md-5 mt-4 mb-3">
                                    <h1 className='text-center fw-normal courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor]}}>Akad Nikah</h1>
                                    <h6 className='text-center fw-normal mb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor]}}>{dataWePernikahan?.alamatAkad}</h6>
                                    <a href={dataWePernikahan?.linkMapsAlamatAkad} aria-label="Google Maps">
                                        <button className="form form-control fw-bold px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[dataWePernikahan?.tema?.btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.tema?.btnColorCD}`,color:[dataWePernikahan?.tema?.fntColor],border:[dataWePernikahan?.tema?.borderBtn],fontSize:"12pt"}}><i className="bi bi-geo-alt"></i> Lihat Maps</button>
                                    </a>
                                </div>

                                <div className="col-12 col-md-1"></div>

                                <div className="col-12 col-md-5 mt-4 mb-3">
                                    <h1 className='text-center fw-normal courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor]}}>Resepsi Nikah</h1>
                                    <h6 className='text-center fw-normal mb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor]}}>{dataWePernikahan?.alamatResepsi}</h6>
                                    <a href={dataWePernikahan?.linkMapsAlamatResepsi} aria-label="Google Maps">
                                        <button className="form form-control fw-bold px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[dataWePernikahan?.tema?.btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.tema?.btnColorCD}`,color:[dataWePernikahan?.tema?.fntColor],border:[dataWePernikahan?.tema?.borderBtn],fontSize:"12pt"}}><i className="bi bi-geo-alt"></i> Lihat Maps</button>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox1} className='w-100'/>
                    </section>

                    {/* BOX 4 */}
                    <section style={{backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:`${dataWePernikahan?.tema?.bgColorBox2}`,width:"100%",height:"auto"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center p-4">

                                    <div className="col-12 col-md-6 my-4">
                                        <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                            <div className="p-3">
                                                <h6 className='text-center fw-normal mt-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor]}}>{dataWePernikahan?.ayat}</h6>
                                                <h2 className='text-center fw-bold pt-1 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor]}}>({dataWePernikahan?.surah})</h2>
                                            </div>
                                            <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox2} className='w-100' style={{marginTop:"-10px"}}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    {/* BOX 5 */}
                    <section style={{backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox1} className='w-100' style={{transform:`${dataWePernikahan?.tema?.rotate}`}} />
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className='col-12 col-lg-10 mb-5'>
                                    <div className='bi bi-images text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.tema?.fntColor]}}></div>
                                    <p className='text-center fw-bold pt-0 mb-4 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Gallery Foto</p>
                                    <div className="container-fluid">
                                        <div className='rowes justify-content-center'>

                                            <div className="column">
                                                <a href={dataWePernikahan?.imageGallery1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery1} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`-10px 10px 25px -18px ${dataWePernikahan?.tema?.btnColorCD}`}}/></a>
                                                <a href={dataWePernikahan?.imageGallery2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery2} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`-10px 10px 25px -18px ${dataWePernikahan?.tema?.btnColorCD}`}}/></a>
                                                <a href={dataWePernikahan?.imageGallery3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery3} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`-10px 10px 25px -18px ${dataWePernikahan?.tema?.btnColorCD}`}}/></a>
                                            </div>
                                            <div className="column">
                                                <a href={dataWePernikahan?.imageGallery4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery4} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`10px 10px 25px -18px ${dataWePernikahan?.tema?.btnColorCD}`}}/></a>
                                                <a href={dataWePernikahan?.imageGallery5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery5} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`10px 10px 25px -18px ${dataWePernikahan?.tema?.btnColorCD}`}}/></a>
                                                <a href={dataWePernikahan?.imageGallery6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery6} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`10px 10px 25px -18px ${dataWePernikahan?.tema?.btnColorCD}`}}/></a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox1} className='w-100' />
                    </section>

                    {/* BOX 6 */}
                    <section style={{backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{backgroundColor:`${dataWePernikahan?.tema?.bgColorBox2}`,width:"100%",height:"auto"}}>
                            <div className="container-fluid">
                                <div className="row justify-content-center py-4 px-2">

                                    <div className="col-12 col-md-10 my-4">
                                        <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                            <div className="p-3">
                                                <div className='bi bi-credit-card text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.tema?.fntColor]}}></div>
                                                <p className='text-center fw-bold pt-0 pb-3 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Kado Pernikahan</p>
                                                <div className="row justify-content-center">

                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <div className="box p-4" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",background: `linear-gradient(-45deg, ${dataWePernikahan?.tema?.btnColorCD} -200%, ${dataWePernikahan?.tema?.btnColor} 70%)`,boxShadow:`10px 10px 20px -15px ${dataWePernikahan?.fntColor}`,boxShadow:`10px 10px 25px -17px ${dataWePernikahan?.tema?.btnColorCD}`}}>
                                                                <p className='fw-bold bad-script-regular' style={{color:[dataWePernikahan?.tema?.fntColor],textAlign:"right",fontSize:"15pt",fontStyle:"italic"}}><i className="bi bi-bank"></i> Bank {dataWePernikahan?.namaBank1}</p>
                                                                <div className="row mb-4">
                                                                    <div className="col-2"><i className="bi bi-cpu" style={{color:[dataWePernikahan?.tema?.fntColor],fontSize:"40px"}}></i></div>
                                                                    <div className="col-10"><p className='fw-bold mt-3 px-1 bad-script-regular' style={{color:[dataWePernikahan?.tema?.fntColor]}}>An. {dataWePernikahan?.namaPemilikBank1}</p></div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-10">
                                                                        <input type="text" id='noRek1' className="fw-bold bad-script-regular" style={{backgroundColor:"transparent",border:"none",color:[dataWePernikahan?.tema?.fntColor],fontSize:"14pt"}} ref={inputNoRek1} defaultValue={dataWePernikahan?.norekBank1}/>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <button type="button" className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[dataWePernikahan?.tema?.fntColor]}}><i className="bi bi-copy fw-bold" onClick={salinRek1}></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-12 col-md-5 mb-4">
                                                        <div>
                                                            <div className="box p-4" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",background: `linear-gradient(-45deg, ${dataWePernikahan?.tema?.btnColorCD} -200%, ${dataWePernikahan?.tema?.btnColor} 70%)`,boxShadow:`10px 10px 20px -15px ${dataWePernikahan?.fntColor}`,boxShadow:`10px 10px 25px -17px ${dataWePernikahan?.tema?.btnColorCD}`}}>
                                                                <p className='fw-bold bad-script-regular' style={{color:[dataWePernikahan?.tema?.fntColor],textAlign:"right",fontSize:"15pt",fontStyle:"italic"}}><i className="bi bi-bank"></i> Bank {dataWePernikahan?.namaBank2}</p>
                                                                <div className="row mb-4">
                                                                    <div className="col-2"><i className="bi bi-cpu" style={{color:[dataWePernikahan?.tema?.fntColor],fontSize:"40px"}}></i></div>
                                                                    <div className="col-10"><p className='fw-bold mt-3 px-1 bad-script-regular' style={{color:[dataWePernikahan?.tema?.fntColor]}}>An. {dataWePernikahan?.namaPemilikBank2}</p></div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-10">
                                                                        <input type="text" id='noRek1' className="fw-bold bad-script-regular" style={{backgroundColor:"transparent",border:"none",color:[dataWePernikahan?.tema?.fntColor],fontSize:"14pt"}} ref={inputNoRek2} defaultValue={dataWePernikahan?.norekBank2}/>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <button type="button" className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[dataWePernikahan?.tema?.fntColor]}}><i className="bi bi-copy fw-bold" onClick={salinRek2}></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div>
                                            <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox2} className='w-100' />
                                        </div>
                                    </div>
                                    
                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                            <div className="p-3">
                                                <div className='bi bi-chat-square-text text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.tema?.fntColor]}}></div>
                                                <p className='text-center fw-bold pt-0 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Beri Ucapan</p>
                                                <form onSubmit={createUcapanWePernikahan} ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)"}}>
                                                    <label className='mx-2 fw-normal bad-script-regular' htmlFor="nama" style={{color:[dataWePernikahan?.tema?.fntColor],fontSize:"13pt"}}>Nama</label>
                                                    <input type="text" id='nama' name='nama' className='form form-control mb-2 bad-script-regular' value={nama} onChange={(e)=>setNama(e.target.value)} style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${dataWePernikahan?.tema?.btnColorCD}`}} required maxLength="100" />
                                                    <label className='mx-2 fw-normal bad-script-regular' htmlFor="status" style={{color:[dataWePernikahan?.tema?.fntColor],fontSize:"13pt"}}>Status</label>
                                                    <select className="form-select form mb-2 bad-script-regular" id="status" name='status' aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${dataWePernikahan?.tema?.btnColorCD}`}} required>
                                                        <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                        <option value="Saudara">Saudara</option>
                                                        <option value="Atasan Kerja">Atasan Kerja</option>
                                                        <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                        <option value="Dosen/Guru">Dosen/Guru</option>
                                                        <option value="Teman Kerja">Teman Kerja</option>
                                                        <option value="Teman Sekolah">Teman Sekolah</option>
                                                        <option value="Teman">Teman</option>
                                                    </select>
                                                    <label className='mx-2 fw-normal bad-script-regular' htmlFor="ucapan" style={{color:[dataWePernikahan?.tema?.fntColor],fontSize:"13pt"}}>Ucapan</label>
                                                    <input type="text" id='ucapan' name='ucapan' className='form form-control mb-4 bad-script-regular' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${dataWePernikahan?.tema?.btnColorCD}`}} maxLength="300"/>
                                                    <button type="submit"  className="form form-control fw-bold mb-4 mt-5 bad-script-regular" style={{backgroundColor:[dataWePernikahan?.tema?.btnColor],padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.tema?.btnColorCD}`,color:[dataWePernikahan?.tema?.fntColor],border:[dataWePernikahan?.tema?.borderBtn],fontSize:"15pt"}}><i className="bi bi-send"></i> Kirim</button>
                                                    <h6 className='text-center mt-3 bad-script-regular' style={{color:[dataWePernikahan?.tema?.fntColor]}}>{msgUcapan}</h6>
                                                </form>
                                            </div>
                                            <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox2} className='w-100' />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5 my-4">
                                        <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                            <div className="p-3">
                                                <div className='bi bi-chat-square-text text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.tema?.fntColor]}}></div>
                                                <p className='text-center fw-bold pt-0 pb-3 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.tema?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Ucapan Live</p>
                                                <div style={{maxHeight:"400px",overflow:"auto"}}>
                                                    <table>
                                                        <tbody>
                                                            {dataUcapan.map((user,index)=>(
                                                                <tr key={index} className='mt-2'>
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:[dataWePernikahan?.tema?.fntColor],fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"11pt",fontWeight:"500"}}>{user.nama}</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"11pt",fontWeight:"500"}}>{user.ucapan}</p></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox2} className='w-100' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* BOX 7 */}
                    <section style={{backgroundImage:[dataWePernikahan?.tema?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox1} className='w-100' style={{transform:`${dataWePernikahan?.tema?.rotate}`}} />
                        <p className='text-center fw-bold mt-4 mb-5 courgette-regular' style={{color:[dataWePernikahan?.tema?.fntColor],fontSize:"25pt"}}>Website By Webew.id</p>
                        <h3 className='text-center fw-normal mt-4 courgette-regular' style={{color:[dataWePernikahan?.tema?.fntColor],marginBottom:"-2px"}}>Image On Freepik</h3>
                        <p style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "auto" }}>
                            <a href={dataWePernikahan?.links?.[0]?.url} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: dataWePernikahan?.fntColor,fontSize: "10pt",textDecoration: "none",}}>{dataWePernikahan?.links?.[0]?.pemilik}</a>
                            <a href={dataWePernikahan?.links?.[1]?.url} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: dataWePernikahan?.fntColor,fontSize: "10pt",textDecoration: "none",}}>{dataWePernikahan?.links?.[1]?.pemilik}</a>
                            <a href={dataWePernikahan?.links?.[2]?.url} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: dataWePernikahan?.fntColor,fontSize: "10pt",textDecoration: "none",}}>{dataWePernikahan?.links?.[2]?.pemilik}</a>
                        </p>
                        <h3 className='text-center fw-normal mt-4 courgette-regular' style={{color:[dataWePernikahan?.tema?.fntColor],marginBottom:"-2px"}}>Song On Youtube</h3>
                        <a href={link4} className="mb-4 bad-script-regular" aria-label="By" style={{display:"block",margin:"auto",textAlign:"center",color:[dataWePernikahan?.tema?.fntColor],fontSize:"10pt"}}>{dataWePernikahan?.musik}</a>
                        <img loading="lazy" src={dataWePernikahan?.tema?.bgImageBox1} className='w-100' style={{marginBottom:"20px"}}/>
                    </section>
                </section>
                {dataWePernikahan?.jenisWePernikahan === "aglaonema" || dataWePernikahan?.jenisWePernikahan === "agapanthus" || dataWePernikahan?.jenisWePernikahan === "amaryllis" || dataWePernikahan?.jenisWePernikahan === "alamanda"
                    ?<div>{kupuKupu.map((className, index) => (
                        <img loading="lazy" key={index} src={dataWePernikahan?.pajangan} className={className} />
                    ))}</div>
                    :<div>{daun.map((className, index) => (
                        <img loading="lazy" key={index} src={dataWePernikahan?.pajangan} className={className} />
                    ))}</div>
                }
            </section>

            </section>
        </div>
    )
}

export default WePernikahanHasil