// IMPORT REACT
import React,{ useState, useEffect, useMemo, lazy, Suspense } from 'react'
import axios from "axios"
import jwt_decode from "jwt-decode"
import { useParams, useNavigate } from "react-router-dom"

// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuSidebar = lazy(() => import('../Layout/MenuSideBar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbar = lazy(() => import('../Layout/MenuNavbar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbarBot = lazy(() => import('../Layout/MenuNavbarBot.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuDonasi = lazy(() => import('../Layout/MenuDonasi.jsx'));

const MenuWeWebsite = () => { 

    const { panggilanParams } = useParams();
    const [panggilan, setPanggilan] = useState('')
    const [expire, setExpire] = useState('')
    const [, setToken] = useState('')
    const navigate = useNavigate()
    // USEEFFECT

    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            if(panggilanParams !== decoded.panggilan){
                try {
                    await axios.delete('https://server13.webew.id/logoutBE')
                    navigate("/login/dashboard", { replace: true })
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-website", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })

    // PROSES LOOP JENIS WEBSITE CARD BODY
    const jenisWeWebsite = useMemo(() => [
        {
          id: 'website',
          title: 'Web Porto I',
          imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g1.png',
          alt: 'Website portofolio G1',
          price: 'Rp 5.000',
          tech: [
            {
              src: 'https://webew.id/img/desainWebsite/HTML 5.png',
              alt: 'Logo HTML 5',
            },
            {
              src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',
              alt: 'Logo CSS 3',
            },
            {
              src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',
              alt: 'Logo Bootstrap 5',
            },
          ],
          link: `/menu-we-website-download/${panggilan}/WebPortoI`,
        },
        {
          id: 'website',
          title: 'Web Porto II',
          imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g2.png',
          alt: 'Website portofolio G2',
          price: 'Rp 5.000',
          tech: [
            {
              src: 'https://webew.id/img/desainWebsite/HTML 5.png',
              alt: 'Logo HTML 5',
            },
            {
              src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',
              alt: 'Logo CSS 3',
            },
            {
              src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',
              alt: 'Logo Bootstrap 5',
            },
          ],
          link: `/menu-we-website-download/${panggilan}/WebPortoII`,
        },
        {
          id: 'website',
          title: 'Web Porto III',
          imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g3.png',
          alt: 'Website portofolio G3',
          price: 'Rp 5.000',
          tech: [
            {
              src: 'https://webew.id/img/desainWebsite/HTML 5.png',
              alt: 'Logo HTML 5',
            },
            {
              src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',
              alt: 'Logo CSS 3',
            },
            {
              src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',
              alt: 'Logo Bootstrap 5',
            },
          ],
          link: `/menu-we-website-download/${panggilan}/WebPortoIII`,
        },
        {
          id: 'website',
          title: 'Web Porto IV',
          imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g4.png',
          alt: 'Website portofolio G4',
          price: 'Rp 5.000',
          tech: [
            {
              src: 'https://webew.id/img/desainWebsite/HTML 5.png',
              alt: 'Logo HTML 5',
            },
            {
              src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',
              alt: 'Logo CSS 3',
            },
            {
              src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',
              alt: 'Logo Bootstrap 5',
            },
          ],
          link: `/menu-we-website-download/${panggilan}/WebPortoIV`,
        },
    ], [panggilan]);

    return ( 
        <div id="wrapper">

            {/* MODAL DONASI */}
              <Suspense fallback={<div></div>}>
                <MenuDonasi/>
              </Suspense>
            {/* TUTUP MODAL DONASI */}

            {/* SIDEBAR */}
             <Suspense fallback={<div></div>}>
                <MenuSidebar/>
              </Suspense>
            {/* TUTUP SIDEBAR */}

            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                  
                    {/* NAVBAR */}
                      <Suspense fallback={<div></div>}>
                        <MenuNavbar/>
                      </Suspense>
                    {/* TUTUP NAVBAR */}

                    {/* KONTEN */}
                      <div  className="wrap-menu px-1">
                        <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                          <div className="container-fluid">
                              <div className="row my-3">

                                  <div className="col-12 pb-2 pt-4">
                                      <h4 className='text-center fw-bold'>We Website</h4>
                                      <p className='text-center fw-semibold'>Download Source Code Website Dengan We Website</p>
                                  </div>

                              </div>
                              <div className="row mb-5 pb-4">
                                {jenisWeWebsite.map((data, index) => (

                                  <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3" id="we website">
                                      <div className="card mb-4 pt-3 pb-0 px-2" style={{ width: '100%' }}>
                                          <h5 className="card-title text-center mt-2 fw-bold">{data.title}</h5>
                                          <img loading="lazy" src={data.imgSrc} className="mx-auto img-fluid mt-1" style={{ maxHeight: '450px',borderRadius:"25px",display: 'block' }} alt={data.alt}/>
                                          <div className="card-body">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  {data.tech.map((tech, techIndex) => (
                                                    <td key={techIndex} width="33.3%">
                                                        <img loading="lazy" src={tech.src} className="img-fluid mx-auto" style={{ width: '40px', minHeight: '40px' ,display: 'block' }} alt={tech.alt}/>
                                                    </td>
                                                  ))}
                                                </tr>
                                              </tbody>
                                            </table>
                                            <p className="card-text-harga mt-3">Harga Saat Ini</p>
                                            <p className="card-text-rupiah">{data.price}</p>
                                            <a href={data.link} className="btn btn-webew-product mt-1 mb-3" aria-label="Produk webew.id">Lihat</a>
                                          </div>
                                      </div>
                                  </div>

                                ))}
                              </div>
                            </div>
                          </div>

                          {/* NAVBOT */}
                            <Suspense fallback={<div></div>}>
                              <MenuNavbarBot/>
                            </Suspense>
                          {/* TUTUP NAVBOT */}

                        </div>
                    {/* TUTUP KONTEN */}

                </div>
            {/* TUTUP KONTEN WRAPPER */}

        </div>
    )
}

export default MenuWeWebsite