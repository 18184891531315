// IMPORT REACT
import React,{ useState, useEffect, lazy, Suspense } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE PARAMS, USE NAVIGATE, LINK
import { useParams, useNavigate } from "react-router-dom"
// IMPORT HTML 2 CANVAS
import html2canvas from "html2canvas";
// IMPORT JS PDF
import { jsPDF } from "jspdf";
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuSidebar = lazy(() => import('../../Layout/MenuSideBar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbar = lazy(() => import('../../Layout/MenuNavbar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbarBot = lazy(() => import('../../Layout/MenuNavbarBot.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuDonasi = lazy(() => import('../../Layout/MenuDonasi.jsx'));
// MENU WE CV DOWNLOAD
const MenuWeCvDownload = () => { // BUKA KURUNG MENU WE CV DOWNLOAD
    // MEMBUAT VARIABEL TRANSAKSI
    const [namaa, setNamaa] = useState('')
    const [name, setName] = useState('')
    const [total,] = useState('5000')
    const [order_id, setOrder_id] = useState('')
    const [tokenTransaksi, setTokenTransaksi] = useState('')
    const [msg, setMsg] = useState('')
    // MEMBUAT VARIABEL WE CV DOWNLOAD
    const { panggilanParams } = useParams();
    const { jenisWeCvParams } = useParams();
    const [jenis, setJenis] = useState(jenisWeCvParams)
    const [panggilan, setPanggilan] = useState('')
    const [displayPembayaran, setDisplayPembayaran] = useState('')
    const [display, setDisplay] = useState('')
    const [urlFoto, setUrlFoto] = useState('')
    const [nama, setNama] = useState('')
    const [emailCv, setEmailCv] = useState('')
    const [noHandphone, setNoHandphone] = useState('')
    const [alamatRumah, setAlamatRumah] = useState('')
    const [kota, setKota] = useState('')
    const [kebangsaan, setKebangsaan] = useState('')
    const [kodePos, setKodePos] = useState('')
    const [tempatLahir, setTempatLahir] = useState('')
    const [tanggalLahir, setTanggalLahir] = useState('')
    const [jenisKelamin, setJenisKelamin] = useState('')
    const [statusKawin, setStatusKawin] = useState('')
    const [agama, setAgama] = useState('')
    const [tinggiBadan, setTinggiBadan] = useState('')
    const [beratBadan, setBeratBadan] = useState('')
    const [linkedIn, setLinkedIn] = useState('')
    const [websitee, setWebsitee] = useState('')
    const [namaPerusahaan1, setNamaPerusahaan1] = useState('')
    const [posisiPekerjaan1, setPosisiPekerjaan1] = useState('')
    const [tanggalMulaiK1, setTanggalMulaiK1] = useState('')
    const [tanggalSelesaiK1, setTanggalSelesaiK1] = useState('')
    const [namaPerusahaan2, setNamaPerusahaan2] = useState('')
    const [posisiPekerjaan2, setPosisiPekerjaan2] = useState('')
    const [tanggalMulaiK2, setTanggalMulaiK2] = useState('')
    const [tanggalSelesaiK2, setTanggalSelesaiK2] = useState('')
    const [namaPerusahaan3, setNamaPerusahaan3] = useState('')
    const [posisiPekerjaan3, setPosisiPekerjaan3] = useState('')
    const [tanggalMulaiK3, setTanggalMulaiK3] = useState('')
    const [tanggalSelesaiK3, setTanggalSelesaiK3] = useState('')
    const [namaPerusahaan4, setNamaPerusahaan4] = useState('')
    const [posisiPekerjaan4, setPosisiPekerjaan4] = useState('')
    const [tanggalMulaiK4, setTanggalMulaiK4] = useState('')
    const [tanggalSelesaiK4, setTanggalSelesaiK4] = useState('')
    const [fresGraduate, setFresGraduate] = useState(null)
    const [namaSekolah1, setNamaSekolah1] = useState('')
    const [programStudi1, setProgramStudi1] = useState('')
    const [tanggalMulaiP1, setTanggalMulaiP1] = useState('')
    const [tanggalSelesaiP1, setTanggalSelesaiP1] = useState('')
    const [namaSekolah2, setNamaSekolah2] = useState('')
    const [programStudi2, setProgramStudi2] = useState('')
    const [tanggalMulaiP2, setTanggalMulaiP2] = useState('')
    const [tanggalSelesaiP2, setTanggalSelesaiP2] = useState('')
    const [namaSekolah3, setNamaSekolah3] = useState('')
    const [programStudi3, setProgramStudi3] = useState('')
    const [tanggalMulaiP3, setTanggalMulaiP3] = useState('')
    const [tanggalSelesaiP3, setTanggalSelesaiP3] = useState('')
    const [namaSekolah4, setNamaSekolah4] = useState('')
    const [programStudi4, setProgramStudi4] = useState('')
    const [tanggalMulaiP4, setTanggalMulaiP4] = useState('')
    const [tanggalSelesaiP4, setTanggalSelesaiP4] = useState('')
    const [keahlian1, setKeahlian1] = useState('')
    const [keahlian2, setKeahlian2] = useState('')
    const [keahlian3, setKeahlian3] = useState('')
    const [keahlian4, setKeahlian4] = useState('')
    const [keahlian5, setKeahlian5] = useState('')
    const [keahlian6, setKeahlian6] = useState('')
    const [keahlian7, setKeahlian7] = useState('')
    const [keahlian8, setKeahlian8] = useState('')
    const [ceritaDiri, setCeritaDiri] = useState('')
    const [jenisCvG, setJenisCvG] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USE EFFECT
    useEffect(()=>{
        refreshToken()
        selectWeCv()
        selectTransaksi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setName(decoded.name)
            setExpire(decoded.exp)
            setDisplayPembayaran("none")
            if(panggilanParams !== decoded.panggilan){
                try {
                    await axios.delete('https://server13.webew.id/logoutBE')
                    navigate("/login/dashboard", { replace: true })
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-cv", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setName(decoded.name)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES MENAMPILKAN WE CV BERDASARKAN PANGGILAN BY PARAMS
    const selectWeCv = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeCvBE/${panggilanParams}`)
            setUrlFoto(response.data.urlFoto)
            setNama(response.data.nama)
            setEmailCv(response.data.emailCv)
            setNoHandphone(response.data.noHandphone)
            setAlamatRumah(response.data.alamatRumah)
            setKota(response.data.kota)
            setKebangsaan(response.data.kebangsaan)
            setKodePos(response.data.kodePos)
            setTempatLahir(response.data.tempatLahir)
            setTanggalLahir(response.data.tanggalLahir)
            setJenisKelamin(response.data.jenisKelamin)
            setStatusKawin(response.data.statusKawin)
            setAgama(response.data.agama)
            setTinggiBadan(response.data.tinggiBadan)
            setBeratBadan(response.data.beratBadan)
            setLinkedIn(response.data.linkedIn)
            setWebsitee(response.data.websitee)
            setNamaPerusahaan1(response.data.namaPerusahaan1)
            setPosisiPekerjaan1(response.data.posisiPekerjaan1)
            setTanggalMulaiK1(response.data.tanggalMulaiK1)
            setTanggalSelesaiK1(response.data.tanggalSelesaiK1)
            setNamaPerusahaan2(response.data.namaPerusahaan2)
            setPosisiPekerjaan2(response.data.posisiPekerjaan2)
            setTanggalMulaiK2(response.data.tanggalMulaiK2)
            setTanggalSelesaiK2(response.data.tanggalSelesaiK2)
            setNamaPerusahaan3(response.data.namaPerusahaan3)
            setPosisiPekerjaan3(response.data.posisiPekerjaan3)
            setTanggalMulaiK3(response.data.tanggalMulaiK3)
            setTanggalSelesaiK3(response.data.tanggalSelesaiK3)
            setNamaPerusahaan4(response.data.namaPerusahaan4)
            setPosisiPekerjaan4(response.data.posisiPekerjaan4)
            setTanggalMulaiK4(response.data.tanggalMulaiK4)
            setTanggalSelesaiK4(response.data.tanggalSelesaiK4)
            setFresGraduate(response.data.fresGraduate)
            setNamaSekolah1(response.data.namaSekolah1)
            setProgramStudi1(response.data.programStudi1)
            setTanggalMulaiP1(response.data.tanggalMulaiP1)
            setTanggalSelesaiP1(response.data.tanggalSelesaiP1)
            setNamaSekolah2(response.data.namaSekolah2)
            setProgramStudi2(response.data.programStudi2)
            setTanggalMulaiP2(response.data.tanggalMulaiP2)
            setTanggalSelesaiP2(response.data.tanggalSelesaiP2)
            setNamaSekolah3(response.data.namaSekolah3)
            setProgramStudi3(response.data.programStudi3)
            setTanggalMulaiP3(response.data.tanggalMulaiP3)
            setTanggalSelesaiP3(response.data.tanggalSelesaiP3)
            setNamaSekolah4(response.data.namaSekolah4)
            setProgramStudi4(response.data.programStudi4)
            setTanggalMulaiP4(response.data.tanggalMulaiP4)
            setTanggalSelesaiP4(response.data.tanggalSelesaiP4)
            setKeahlian1(response.data.keahlian1)
            setKeahlian2(response.data.keahlian2)
            setKeahlian3(response.data.keahlian3)
            setKeahlian4(response.data.keahlian4)
            setKeahlian5(response.data.keahlian5)
            setKeahlian6(response.data.keahlian6)
            setKeahlian7(response.data.keahlian7)
            setKeahlian8(response.data.keahlian8)
            setCeritaDiri(response.data.ceritaDiri)
            setJenisCvG(response.data.jenisCvG)
            const Seconds = new Date().getSeconds()
            const result = Math.random().toString(36).substring(2,12);
            setOrder_id(response.data.jenisCvG + result + Seconds)
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES TRANSAKSI
    const transaksi = async (e) => {
        e.preventDefault()
        try{
            const data = {
                nama: name,
                order_id: order_id,
                total: total,
                panggilan: panggilanParams
            }
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post("https://server13.webew.id/prosesTransaksiBE",data,config)
            setTokenTransaksi(response.data.tokenTransaksi)
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENYIMPAN TRANSAKSI KE DB
    const transaksiBerhasil = async (result) => {
        try {
            await axios.post("https://server13.webew.id/transaksiBerhasilBE", {
                nama: jenisCvG,
                order_id: result.order_id,  // Ambil order_id dari result
                total: result.gross_amount, // Pastikan sesuai dengan response Midtrans
                panggilan: panggilanParams
            })
            console.log("Transaksi berhasil disimpan ke database");
        } catch (error) {
            console.log("Error menyimpan transaksi:", error);
        }
    }
    // USE EFFECT TRANSAKSI
    useEffect(()=>{
        if(tokenTransaksi){
            window.snap.pay(tokenTransaksi, {
                onSuccess: async (result) => {
                    localStorage.setItem("Pembayaran", JSON.stringify(result))
                    setTokenTransaksi("")
                    // Panggil transaksiBerhasil dengan result dari Midtrans
                    await transaksiBerhasil(result);
                    window.location.reload()
                },
                onPending: (result) => {
                    window.location.reload()
                    localStorage.setItem("Pembayaran", JSON.stringify(result))
                    setTokenTransaksi("")
                },
                onError: (error) => {
                    window.location.reload()
                    if(error.response){
                        setMsg(error.response.data.msg)
                    }
                    setTokenTransaksi("")
                },
                onClose: () => {
                    window.location.reload()
                    setMsg("Anda belum menyelesaikan pembayaran")
                    setTokenTransaksi("")
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenTransaksi])
    useEffect(()=>{
        const midtransUrl = "https://app.midtrans.com/snap/snap.js"
        let scriptTag = document.createElement("script")
        scriptTag.src = midtransUrl
        const midtransClientKey = "Mid-client-D6LOR1Eecoo9s-wv"
        scriptTag.setAttribute("data-client-key", midtransClientKey)
        document.body.appendChild(scriptTag)
        return() => {
            document.body.removeChild(scriptTag)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // PROSES MENAMPILKAN TRANSAKSI BERDASARKAN PANGGILAN BY PARAMS
    const selectTransaksi = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectTransaksiBE/${panggilanParams}`, {
                params: { extra: jenis } // Mengirim variableLain sebagai query parameter
            })
            setNamaa(response.data?.nama)
        }catch(error){
            console.log(error)
        }
    }
    // BUTTON TUTUP DISPLAY
    const tutup = () => {
        setDisplay("none")
    }
    // DOWNLOAD PDF
    const downloadPdfDocument = () => {
        const input = document.getElementById("wrapperCvG")
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png')
                const pdf = new jsPDF('p', 'mm', 'a4')
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'PNG', 0, 0, width, height)
                pdf.save(`CV ${nama}.pdf`)
            })
    }
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div id="wrapper">
            {/* MODAL */}
                {/* MODAL DONASI */}
                <Suspense fallback={<div></div>}>
                    <MenuDonasi/>
                </Suspense>
                {/* TUTUP MODAL DONASI*/}
                {/* MODAL MESSAGE*/}
                <div style={{position: "fixed",zIndex: "9999",backgroundColor: "#282a3ae2",height: "100vh",width: "100%",display: [namaa && namaa === jenisCvG || display === "none" ? "none" : "flex"],alignItems: "center",justifyContent: "center"}}>
                    <div style={{backgroundColor: "white",width: "100%",maxWidth: "500px",padding: "20px",borderRadius: "20px",boxShadow: "-10px 10px 25px -15px #7f7f7f",textAlign: "center"}}>
                        {jenisCvG !== jenisWeCvParams 
                            ?<h6 style={{ color: "red", fontWeight: "700" }}>Maaf ya kamu belum isi form data, silahkan isi form data dulu ya</h6>
                            :<h6 className='card-text-rupiah mb-2' style={{ fontWeight: "700" }}>Hore Tema "{jenisWeCvParams}"<br/>CV Kamu Sudah Jadi<p style={{ margin: "20px 0 0 0", fontSize: "12pt", fontWeight: "400" }}>Silahkan dilihat ya, jika sudah cocok bisa ke pembayaran, dan kamu bisa mengunduh nya</p></h6>
                        }
                        {jenisCvG !== jenisWeCvParams 
                            ?<a href={`/menu-we-cv-form/${panggilan}/${jenisWeCvParams}`} className="btn btn-webew-product" aria-label="Kembali webew.id" style={{ marginTop: "18px" }}>Oke Siap</a>
                            :<button type="button" className="btn btn-webew-product" style={{ marginTop: "18px" }} onClick={tutup}>Oke Siap</button>
                        }
                    </div>
                </div>
                {/* TUTUP MODAL MESSAGE*/}
            {/* TUTUP MODAL */}
            {/* SIDEBAR */}
                <Suspense fallback={<div></div>}>
                    <MenuSidebar/>
                </Suspense>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
            <div id="content-wrapper">
                {/* NAVBAR */}
                    <Suspense fallback={<div></div>}>
                        <MenuNavbar/>
                    </Suspense>
                {/* TUTUP NAVBAR */}
                {/* KONTEN */}
                    <div  className="wrap-menu px-1 pb-5">
                        <div className="container-fluid pb-5">
                            <div className="row justify-content-center pt-4">
                                <div className="col-12 col-lg-6 mb-4">
                                    <div className="box p-4 pb-1">
                                        <a href={`/menu-we-cv-form/${panggilan}/${jenisWeCvParams}`} className="btn btn-webew-product" aria-label="Kembali webew.id" style={{width:"auto"}}><i className="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                                        <h3 className="text-center fw-bold" style={{marginTop:"10px"}}>Download We CV</h3>
                                        {namaa && namaa === jenisCvG
                                            ?<p className="text-center fw-normal">Silahkan klik tombol unduh agar bisa mendapatkan CV dalam bentuk PDF</p>
                                            :<p className="text-center fw-normal">Silahkan klik tombol beli agar bisa mendapatkan CV dalam bentuk PDF</p>
                                        }
                                        <div className="row justify-content-center">
                                            <div className="col-6 col-md-6 mb-4">
                                                {namaa && namaa === jenisCvG
                                                    ?<a href={`/menu-we-cv-download/${panggilan}/${jenisWeCvParams}`} className="btn btn-webew-product"><i className="bi bi-check-lg" style={{color:"white"}}></i> Sudah Dibeli</a> 
                                                    :<form onSubmit={transaksi}>
                                                        <input type="text" className="form-disable" value={name} readOnly/>
                                                        <input type="text" className="form-disable" value={order_id} readOnly/>
                                                        <input type="text" className="form-disable" value={total} readOnly/>
                                                        <input type="text" className="form-disable" value={panggilanParams} readOnly/>
                                                        <input type="submit" className="btn btn-webew-product" value="Beli Rp 5.000"/>
                                                    </form>
                                                }
                                            </div>
                                            <div className="col-6 col-md-6 mb-4">
                                                {namaa && namaa === jenisCvG
                                                    ?<button onClick={downloadPdfDocument} className="btn btn-webew-product"><i className="bi bi-download" style={{color:"white"}}></i> Unduh</button>
                                                    :<a href={`/menu-we-cv-download/${panggilan}/${jenisWeCvParams}`} className="btn btn-webew-product"><i className="bi bi-lock-fill" style={{color:"white"}}></i> Unduh</a> 
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" >
                                    <div className="box" id="We CV" style={{maxHeight: "88vh",overflow: "auto",borderRadius: "10px",border:"30px solid white"}}>
                                        {jenisCvG === "CVStandarI"
                                            ?<div className="wrapper-cvg1" id='wrapperCvG'>
                                                <div className="dasar-cvg1">
                                                    <div className="row">
                                                        <div className="box-judul-cv-cvg1">
                                                            <p className="judul-cv-cvg1">CURRICULUM VITAE</p>
                                                            <p className='text-ceritadiri-cvg1'>{ceritaDiri}</p>  
                                                        </div>
                                                        <div className="box-foto-cvg1">
                                                            <img loading="lazy" src={`https://server13.webew.id/images/${urlFoto}`} className="pas-foto-cvg1 rounded" alt="foto pas"/>
                                                        </div>
                                                    </div>
                                                    <p className="judul-datapribadi-cvg1 mb-0">Data Pribadi</p>
                                                    <div className="garis-cvg1"></div>
                                                    <div className="box-datapribadi-cvg1">
                                                        <table className='table-cvg1'>
                                                            <tbody>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1' width="27%">Nama Lengkap</td>
                                                                    <td className='teks-color-cvg1' width="5%">:</td>
                                                                    <td className='teks-color-cvg1' width="68%">{nama}</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1'>Tempat, Tanggal Lahir</td>
                                                                    <td className='teks-color-cvg1'>:</td>
                                                                    <td className='teks-color-cvg1'>{tempatLahir}, {tanggalLahir}</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1'>Agama</td>
                                                                    <td className='teks-color-cvg1'>:</td>
                                                                    <td className='teks-color-cvg1'>{agama}</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1'>Status Kawin</td>
                                                                    <td className='teks-color-cvg1'>:</td>
                                                                    <td className='teks-color-cvg1'>{statusKawin}</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1'>Jenis Kelamin</td>
                                                                    <td className='teks-color-cvg1'>:</td>
                                                                    <td className='teks-color-cvg1'>{jenisKelamin}</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1'>Tinggi Badan</td>
                                                                    <td className='teks-color-cvg1'>:</td>
                                                                    <td className='teks-color-cvg1'>{tinggiBadan} CM</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1'>Berat Badan</td>
                                                                    <td className='teks-color-cvg1'>:</td>
                                                                    <td className='teks-color-cvg1'>{beratBadan} KG</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1'>Nomor Handphone</td>
                                                                    <td className='teks-color-cvg1'>:</td>
                                                                    <td className='teks-color-cvg1'>{noHandphone}</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1'>Alamat Email</td>
                                                                    <td className='teks-color-cvg1'>:</td>
                                                                    <td className='teks-color-cvg1'>{emailCv}</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1'>Alamat Rumah</td>
                                                                    <td className='teks-color-cvg1'>:</td>
                                                                    <td className='teks-color-cvg1'>{alamatRumah}, {kota}, {kebangsaan}, {kodePos}</td>
                                                                </tr>
                                                                {linkedIn === ""
                                                                    ?<tr></tr>
                                                                    :<tr className='tr-cvg1'>
                                                                        <td className='teks-color-cvg1'>LinkedIn</td>
                                                                        <td className='teks-color-cvg1'>:</td>
                                                                        <td className='teks-color-cvg1'>{linkedIn}</td>
                                                                    </tr>
                                                                }
                                                                {websitee === ""
                                                                    ?<tr></tr>
                                                                    :<tr className='tr-cvg1'>
                                                                        <td className='teks-color-cvg1'>Website</td>
                                                                        <td className='teks-color-cvg1'>:</td>
                                                                        <td className='teks-color-cvg1'>{websitee}</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <p className="judul-datapribadi-cvg1 mb-0">Pengalaman Kerja</p>
                                                    <div className="garis-cvg1"></div>
                                                    <div className="box-pengalamankerja-cvg1">
                                                        {fresGraduate === "Fresh Graduate"
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg1 mt-1">{namaPerusahaan1} ({posisiPekerjaan1}), {tanggalMulaiK1} - {tanggalSelesaiK1}</p>
                                                        }
                                                        {namaPerusahaan2 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg1 text-pengalamankerja-cvg1">{namaPerusahaan2} ({posisiPekerjaan2}), {tanggalMulaiK2} - {tanggalSelesaiK2}</p>
                                                        }
                                                        {namaPerusahaan3 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg1 text-pengalamankerja-cvg1">{namaPerusahaan3} ({posisiPekerjaan3}), {tanggalMulaiK3} - {tanggalSelesaiK3}</p>
                                                        }
                                                        {namaPerusahaan4 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg1 text-pengalamankerja-cvg1">{namaPerusahaan4} ({posisiPekerjaan4}), {tanggalMulaiK4} - {tanggalSelesaiK4}</p>
                                                        }
                                                        {fresGraduate === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg1 text-pengalamankerja-cvg1">{fresGraduate}</p>
                                                        }
                                                    </div>
                                                    <p className="judul-datapribadi-cvg1 mb-0" style={{marginTop:"-13px"}}>Pendidikan</p>
                                                    <div className="garis-cvg1"></div>
                                                    <div className="box-pendidikan-cvg1">
                                                        <p className="teks-color-cvg1 mt-1">{namaSekolah1} ({programStudi1}), {tanggalMulaiP1} - {tanggalSelesaiP1}</p>
                                                        {namaSekolah2 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg1 text-pengalamankerja-cvg1">{namaSekolah2} ({programStudi2}), {tanggalMulaiP2} - {tanggalSelesaiP2}</p>
                                                        }
                                                        {namaSekolah3 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg1 text-pengalamankerja-cvg1">{namaSekolah3} ({programStudi3}), {tanggalMulaiP3} - {tanggalSelesaiP3}</p>
                                                        }
                                                        {namaSekolah4 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg1 text-pengalamankerja-cvg1">{namaSekolah4} ({programStudi4}), {tanggalMulaiP4} - {tanggalSelesaiP4}</p>
                                                        }
                                                    </div>
                                                    <p className="judul-datapribadi-cvg1 mb-0" style={{marginTop:"-13px"}}>Keahlian</p>
                                                    <div className="garis-cvg1"></div>
                                                    <div className="box-keahlian-cvg1 mt-1">
                                                        <table className='table-cvg1'>
                                                            <tbody>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1' width="50%">{keahlian1}</td>
                                                                    <td className='teks-color-cvg1' width="50%">{keahlian5}</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1' width="50%">{keahlian2}</td>
                                                                    <td className='teks-color-cvg1' width="50%">{keahlian6}</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1' width="50%">{keahlian3}</td>
                                                                    <td className='teks-color-cvg1' width="50%">{keahlian7}</td>
                                                                </tr>
                                                                <tr className='tr-cvg1'>
                                                                    <td className='teks-color-cvg1' width="50%">{keahlian4}</td>
                                                                    <td className='teks-color-cvg1' width="50%">{keahlian8}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            :<div style={{display:"none"}}></div>
                                        }
                                        {jenisCvG === "CVStandarII"
                                            ?<div className="wrapper-cvg2" id='wrapperCvG'>
                                                <div className="dasar-cvg2">
                                                    <div className="box-judul-cv-cvg2">
                                                        <p className="judul-cv-cvg2">CURRICULUM VITAE</p>
                                                        <p className='text-ceritadiri-cvg2'>{ceritaDiri}</p>  
                                                    </div>
                                                    <div className="garis-cvg2"></div>
                                                    <p className="judul-datapribadi-cvg2 mb-0">A. Data Pribadi</p>
                                                    <div className="row">
                                                        <div className="box-datapribadi-cvg2">
                                                            <table className='table-cvg2'>
                                                                <tbody>
                                                                    <tr className='tr-cvg2'>
                                                                        <td className='teks-color-cvg2' width="33%">Nama Lengkap</td>
                                                                        <td className='teks-color-cvg2' width="4%">:</td>
                                                                        <td className='teks-color-cvg2' width="63%">{nama}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg2'>
                                                                        <td className='teks-color-cvg2'>Tempat, Tanggal Lahir</td>
                                                                        <td className='teks-color-cvg2'>:</td>
                                                                        <td className='teks-color-cvg2'>{tempatLahir}, {tanggalLahir}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg2'>
                                                                        <td className='teks-color-cvg2'>Agama</td>
                                                                        <td className='teks-color-cvg2'>:</td>
                                                                        <td className='teks-color-cvg2'>{agama}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg2'>
                                                                        <td className='teks-color-cvg2'>Status Kawin</td>
                                                                        <td className='teks-color-cvg2'>:</td>
                                                                        <td className='teks-color-cvg2'>{statusKawin}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg2'>
                                                                        <td className='teks-color-cvg2'>Jenis Kelamin</td>
                                                                        <td className='teks-color-cvg2'>:</td>
                                                                        <td className='teks-color-cvg2'>{jenisKelamin}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg2'>
                                                                        <td className='teks-color-cvg2'>Tinggi Badan</td>
                                                                        <td className='teks-color-cvg2'>:</td>
                                                                        <td className='teks-color-cvg2'>{tinggiBadan} CM</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg2'>
                                                                        <td className='teks-color-cvg2'>Berat Badan</td>
                                                                        <td className='teks-color-cvg2'>:</td>
                                                                        <td className='teks-color-cvg2'>{beratBadan} KG</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg2'>
                                                                        <td className='teks-color-cvg2'>Nomor Handphone</td>
                                                                        <td className='teks-color-cvg2'>:</td>
                                                                        <td className='teks-color-cvg2'>{noHandphone}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="box-foto-cvg2">
                                                            <img loading="lazy" src={`https://server13.webew.id/images/${urlFoto}`} className="pas-foto-cvg2 rounded" alt="foto pas"/>
                                                        </div>
                                                    </div>
                                                    <div className='box-datapribadi2-cvg2'>
                                                        <table className='table-cvg2'>
                                                            <tbody>
                                                                <tr className='tr-cvg2'>
                                                                    <td className='teks-color-cvg2' width="22.5%">Alamat Email</td>
                                                                    <td className='teks-color-cvg2' width="2.7%">:</td>
                                                                    <td className='teks-color-cvg2' width="75%">{emailCv}</td>
                                                                </tr>
                                                                <tr className='tr-cvg2'>
                                                                    <td className='teks-color-cvg2'>Alamat Rumah</td>
                                                                    <td className='teks-color-cvg2'>:</td>
                                                                    <td className='teks-color-cvg2'>{alamatRumah}, {kota}, {kebangsaan}, {kodePos}</td>
                                                                </tr>
                                                                {linkedIn === ""
                                                                    ?<tr></tr>
                                                                    :<tr className='tr-cvg2'>
                                                                        <td className='teks-color-cvg2'>LinkedIn</td>
                                                                        <td className='teks-color-cvg2'>:</td>
                                                                        <td className='teks-color-cvg2'>{linkedIn}</td>
                                                                    </tr>
                                                                }
                                                                {websitee === ""
                                                                    ?<tr></tr>
                                                                    :<tr className='tr-cvg2'>
                                                                        <td className='teks-color-cvg2'>Website</td>
                                                                        <td className='teks-color-cvg2'>:</td>
                                                                        <td className='teks-color-cvg2'>{websitee}</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <p className="judul-datapribadi-cvg2 mb-0">B. Pengalaman Kerja</p>
                                                    <div className="box-pengalamankerja-cvg2">
                                                        {fresGraduate === "Fresh Graduate"
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg2">{namaPerusahaan1} ({posisiPekerjaan1}), {tanggalMulaiK1} - {tanggalSelesaiK1}</p>
                                                        }
                                                        {namaPerusahaan2 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg2 text-pengalamankerja-cvg2">{namaPerusahaan2} ({posisiPekerjaan2}), {tanggalMulaiK2} - {tanggalSelesaiK2}</p>
                                                        }
                                                        {namaPerusahaan3 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg2 text-pengalamankerja-cvg2">{namaPerusahaan3} ({posisiPekerjaan3}), {tanggalMulaiK3} - {tanggalSelesaiK3}</p>
                                                        }
                                                        {namaPerusahaan4 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg2 text-pengalamankerja-cvg2">{namaPerusahaan4} ({posisiPekerjaan4}), {tanggalMulaiK4} - {tanggalSelesaiK4}</p>
                                                        }
                                                        {fresGraduate === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg2 text-pengalamankerja-cvg2">{fresGraduate}</p>
                                                        }
                                                    </div>
                                                    <p className="judul-datapribadi-cvg2 mb-0" style={{marginTop:"-13px"}}>C. Pendidikan</p>
                                                    <div className="box-pendidikan-cvg2">
                                                        <p className="teks-color-cvg2">{namaSekolah1} ({programStudi1}), {tanggalMulaiP1} - {tanggalSelesaiP1}</p>
                                                        {namaSekolah2 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg2 text-pengalamankerja-cvg2">{namaSekolah2} ({programStudi2}), {tanggalMulaiP2} - {tanggalSelesaiP2}</p>
                                                        }
                                                        {namaSekolah3 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg2 text-pengalamankerja-cvg2">{namaSekolah3} ({programStudi3}), {tanggalMulaiP3} - {tanggalSelesaiP3}</p>
                                                        }
                                                        {namaSekolah4 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg2 text-pengalamankerja-cvg2">{namaSekolah4} ({programStudi4}), {tanggalMulaiP4} - {tanggalSelesaiP4}</p>
                                                        }
                                                    </div>
                                                    <p className="judul-datapribadi-cvg2 mb-0" style={{marginTop:"-13px"}}>D. Keahlian</p>
                                                    <div className="box-keahlian-cvg2">
                                                        <table className='table-cvg2'>
                                                            <tbody>
                                                                <tr className='tr-cvg2'>
                                                                    <td className='teks-color-cvg2' width="50%">{keahlian1}</td>
                                                                    <td className='teks-color-cvg2' width="50%">{keahlian5}</td>
                                                                </tr>
                                                                <tr className='tr-cvg2'>
                                                                    <td className='teks-color-cvg2' width="50%">{keahlian2}</td>
                                                                    <td className='teks-color-cvg2' width="50%">{keahlian6}</td>
                                                                </tr>
                                                                <tr className='tr-cvg2'>
                                                                    <td className='teks-color-cvg2' width="50%">{keahlian3}</td>
                                                                    <td className='teks-color-cvg2' width="50%">{keahlian7}</td>
                                                                </tr>
                                                                <tr className='tr-cvg2'>
                                                                    <td className='teks-color-cvg2' width="50%">{keahlian4}</td>
                                                                    <td className='teks-color-cvg2' width="50%">{keahlian8}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            :<div style={{display:"none"}}></div>
                                        }
                                        {jenisCvG === "CVStandarIII"
                                            ?<div className="wrapper-cvg3" id='wrapperCvG'>
                                                <div className="dasar-cvg3">
                                                    <div className="box-judul-cv-cvg3">
                                                        <p className="judul-cv-cvg3">CURRICULUM VITAE</p>
                                                        <p className='text-ceritadiri-cvg3'>{ceritaDiri}</p>  
                                                    </div>
                                                    <p className="judul-datapribadi-cvg3 mb-1">A. Data Pribadi</p>
                                                    <div className="row">
                                                        <div className="box-datapribadi-cvg3">
                                                            <table className='table-cvg3'>
                                                                <tbody>
                                                                    <tr className='tr-cvg3'>
                                                                        <td className='teks-color-cvg3' width="33%">Nama Lengkap</td>
                                                                        <td className='teks-color-cvg3' width="4%">:</td>
                                                                        <td className='teks-color-cvg3' width="63%">{nama}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg3'>
                                                                        <td className='teks-color-cvg3'>Tempat, Tanggal Lahir</td>
                                                                        <td className='teks-color-cvg3'>:</td>
                                                                        <td className='teks-color-cvg3'>{tempatLahir}, {tanggalLahir}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg3'>
                                                                        <td className='teks-color-cvg3'>Agama</td>
                                                                        <td className='teks-color-cvg3'>:</td>
                                                                        <td className='teks-color-cvg3'>{agama}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg3'>
                                                                        <td className='teks-color-cvg3'>Status Kawin</td>
                                                                        <td className='teks-color-cvg3'>:</td>
                                                                        <td className='teks-color-cvg3'>{statusKawin}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg3'>
                                                                        <td className='teks-color-cvg3'>Jenis Kelamin</td>
                                                                        <td className='teks-color-cvg3'>:</td>
                                                                        <td className='teks-color-cvg3'>{jenisKelamin}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg3'>
                                                                        <td className='teks-color-cvg3'>Tinggi Badan</td>
                                                                        <td className='teks-color-cvg3'>:</td>
                                                                        <td className='teks-color-cvg3'>{tinggiBadan} CM</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg3'>
                                                                        <td className='teks-color-cvg3'>Berat Badan</td>
                                                                        <td className='teks-color-cvg3'>:</td>
                                                                        <td className='teks-color-cvg3'>{beratBadan} KG</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg3'>
                                                                        <td className='teks-color-cvg3'>Nomor Handphone</td>
                                                                        <td className='teks-color-cvg3'>:</td>
                                                                        <td className='teks-color-cvg3'>{noHandphone}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="box-foto-cvg3">
                                                            <img loading="lazy" src={`https://server13.webew.id/images/${urlFoto}`} className="pas-foto-cvg3 rounded" alt="foto pas"/>
                                                        </div>
                                                    </div>
                                                    <div className='box-datapribadi2-cvg3'>
                                                        <table className='table-cvg3'>
                                                            <tbody>
                                                                <tr className='tr-cvg3'>
                                                                    <td className='teks-color-cvg3' width="22.5%">Alamat Email</td>
                                                                    <td className='teks-color-cvg3' width="2.7%">:</td>
                                                                    <td className='teks-color-cvg3' width="75%">{emailCv}</td>
                                                                </tr>
                                                                <tr className='tr-cvg3'>
                                                                    <td className='teks-color-cvg3'>Alamat Rumah</td>
                                                                    <td className='teks-color-cvg3'>:</td>
                                                                    <td className='teks-color-cvg3'>{alamatRumah}, {kota}, {kebangsaan}, {kodePos}</td>
                                                                </tr>
                                                                {linkedIn === ""
                                                                    ?<tr></tr>
                                                                    :<tr className='tr-cvg3'>
                                                                        <td className='teks-color-cvg3'>LinkedIn</td>
                                                                        <td className='teks-color-cvg3'>:</td>
                                                                        <td className='teks-color-cvg3'>{linkedIn}</td>
                                                                    </tr>
                                                                }
                                                                {websitee === ""
                                                                    ?<tr></tr>
                                                                    :<tr className='tr-cvg3'>
                                                                        <td className='teks-color-cvg3'>Website</td>
                                                                        <td className='teks-color-cvg3'>:</td>
                                                                        <td className='teks-color-cvg3'>{websitee}</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <p className="judul-datapribadi-cvg3 mb-1">B. Pengalaman Kerja</p>
                                                    <div className="box-pengalamankerja-cvg3">
                                                        {fresGraduate === "Fresh Graduate"
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg3">{namaPerusahaan1} ({posisiPekerjaan1}), {tanggalMulaiK1} - {tanggalSelesaiK1}</p>
                                                        }
                                                        {namaPerusahaan2 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg3 text-pengalamankerja-cvg3">{namaPerusahaan2} ({posisiPekerjaan2}), {tanggalMulaiK2} - {tanggalSelesaiK2}</p>
                                                        }
                                                        {namaPerusahaan3 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg3 text-pengalamankerja-cvg3">{namaPerusahaan3} ({posisiPekerjaan3}), {tanggalMulaiK3} - {tanggalSelesaiK3}</p>
                                                        }
                                                        {namaPerusahaan4 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg3 text-pengalamankerja-cvg3">{namaPerusahaan4} ({posisiPekerjaan4}), {tanggalMulaiK4} - {tanggalSelesaiK4}</p>
                                                        }
                                                        {fresGraduate === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg3 text-pengalamankerja-cvg3">{fresGraduate}</p>
                                                        }
                                                    </div>
                                                    <p className="judul-datapribadi-cvg3 mb-1" style={{marginTop:"-13px"}}>C. Pendidikan</p>
                                                    <div className="box-pendidikan-cvg3">
                                                        <p className="teks-color-cvg3">{namaSekolah1} ({programStudi1}), {tanggalMulaiP1} - {tanggalSelesaiP1}</p>
                                                        {namaSekolah2 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg3 text-pengalamankerja-cvg3">{namaSekolah2} ({programStudi2}), {tanggalMulaiP2} - {tanggalSelesaiP2}</p>
                                                        }
                                                        {namaSekolah3 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg3 text-pengalamankerja-cvg3">{namaSekolah3} ({programStudi3}), {tanggalMulaiP3} - {tanggalSelesaiP3}</p>
                                                        }
                                                        {namaSekolah4 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg3 text-pengalamankerja-cvg3">{namaSekolah4} ({programStudi4}), {tanggalMulaiP4} - {tanggalSelesaiP4}</p>
                                                        }
                                                    </div>
                                                    <p className="judul-datapribadi-cvg3 mb-1" style={{marginTop:"-13px"}}>D. Keahlian</p>
                                                    <div className="box-keahlian-cvg3">
                                                        <table className='table-cvg3'>
                                                            <tbody>
                                                                <tr className='tr-cvg3'>
                                                                    <td className='teks-color-cvg3' width="50%">{keahlian1}</td>
                                                                    <td className='teks-color-cvg3' width="50%">{keahlian5}</td>
                                                                </tr>
                                                                <tr className='tr-cvg3'>
                                                                    <td className='teks-color-cvg3' width="50%">{keahlian2}</td>
                                                                    <td className='teks-color-cvg3' width="50%">{keahlian6}</td>
                                                                </tr>
                                                                <tr className='tr-cvg3'>
                                                                    <td className='teks-color-cvg3' width="50%">{keahlian3}</td>
                                                                    <td className='teks-color-cvg3' width="50%">{keahlian7}</td>
                                                                </tr>
                                                                <tr className='tr-cvg3'>
                                                                    <td className='teks-color-cvg3' width="50%">{keahlian4}</td>
                                                                    <td className='teks-color-cvg3' width="50%">{keahlian8}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            :<div style={{display:"none"}}></div>
                                        }
                                        {jenisCvG === "CVStandarIV"
                                            ?<div className="wrapper-cvg4" id='wrapperCvG'>
                                                <div className="dasar-cvg4">
                                                    <div className="box-judul-cv-cvg4">
                                                        <p className="judul-cv-cvg4">CURRICULUM VITAE</p>
                                                        <p className='text-ceritadiri-cvg4'>{ceritaDiri}</p>  
                                                    </div>
                                                    <p className="judul-datapribadi-cvg4 mb-0">A. Data Pribadi</p>
                                                    <div className="row">
                                                        <div className="box-datapribadi-cvg4">
                                                            <table className='table-cvg4'>
                                                                <tbody>
                                                                    <tr className='tr-cvg4'>
                                                                        <td className='teks-color-cvg4' width="33%">Nama Lengkap</td>
                                                                        <td className='teks-color-cvg4' width="4%">:</td>
                                                                        <td className='teks-color-cvg4' width="63%">{nama}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg4'>
                                                                        <td className='teks-color-cvg4'>Tempat, Tanggal Lahir</td>
                                                                        <td className='teks-color-cvg4'>:</td>
                                                                        <td className='teks-color-cvg4'>{tempatLahir}, {tanggalLahir}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg4'>
                                                                        <td className='teks-color-cvg4'>Agama</td>
                                                                        <td className='teks-color-cvg4'>:</td>
                                                                        <td className='teks-color-cvg4'>{agama}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg4'>
                                                                        <td className='teks-color-cvg4'>Status Kawin</td>
                                                                        <td className='teks-color-cvg4'>:</td>
                                                                        <td className='teks-color-cvg4'>{statusKawin}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg4'>
                                                                        <td className='teks-color-cvg4'>Jenis Kelamin</td>
                                                                        <td className='teks-color-cvg4'>:</td>
                                                                        <td className='teks-color-cvg4'>{jenisKelamin}</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg4'>
                                                                        <td className='teks-color-cvg4'>Tinggi Badan</td>
                                                                        <td className='teks-color-cvg4'>:</td>
                                                                        <td className='teks-color-cvg4'>{tinggiBadan} CM</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg4'>
                                                                        <td className='teks-color-cvg4'>Berat Badan</td>
                                                                        <td className='teks-color-cvg4'>:</td>
                                                                        <td className='teks-color-cvg4'>{beratBadan} KG</td>
                                                                    </tr>
                                                                    <tr className='tr-cvg4'>
                                                                        <td className='teks-color-cvg4'>Nomor Handphone</td>
                                                                        <td className='teks-color-cvg4'>:</td>
                                                                        <td className='teks-color-cvg4'>{noHandphone}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="box-foto-cvg4">
                                                            <img loading="lazy" src={`https://server13.webew.id/images/${urlFoto}`} className="pas-foto-cvg4 rounded" alt="foto pas"/>
                                                        </div>
                                                    </div>
                                                    <div className='box-datapribadi2-cvg4'>
                                                        <table className='table-cvg4'>
                                                            <tbody>
                                                                <tr className='tr-cvg4'>
                                                                    <td className='teks-color-cvg4' width="22.5%">Alamat Email</td>
                                                                    <td className='teks-color-cvg4' width="2.7%">:</td>
                                                                    <td className='teks-color-cvg4' width="75%">{emailCv}</td>
                                                                </tr>
                                                                <tr className='tr-cvg4'>
                                                                    <td className='teks-color-cvg4'>Alamat Rumah</td>
                                                                    <td className='teks-color-cvg4'>:</td>
                                                                    <td className='teks-color-cvg4'>{alamatRumah}, {kota}, {kebangsaan}, {kodePos}</td>
                                                                </tr>
                                                                {linkedIn === ""
                                                                    ?<tr></tr>
                                                                    :<tr className='tr-cvg4'>
                                                                        <td className='teks-color-cvg4'>LinkedIn</td>
                                                                        <td className='teks-color-cvg4'>:</td>
                                                                        <td className='teks-color-cvg4'>{linkedIn}</td>
                                                                    </tr>
                                                                }
                                                                {websitee === ""
                                                                    ?<tr></tr>
                                                                    :<tr className='tr-cvg4'>
                                                                        <td className='teks-color-cvg4'>Website</td>
                                                                        <td className='teks-color-cvg4'>:</td>
                                                                        <td className='teks-color-cvg4'>{websitee}</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <p className="judul-datapribadi-cvg4 mb-0">B. Pengalaman Kerja</p>
                                                    <div className="box-pengalamankerja-cvg4">
                                                        {fresGraduate === "Fresh Graduate"
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg4">{namaPerusahaan1} ({posisiPekerjaan1}), {tanggalMulaiK1} - {tanggalSelesaiK1}</p>
                                                        }
                                                        {namaPerusahaan2 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg4 text-pengalamankerja-cvg4">{namaPerusahaan2} ({posisiPekerjaan2}), {tanggalMulaiK2} - {tanggalSelesaiK2}</p>
                                                        }   
                                                        {namaPerusahaan3 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg4 text-pengalamankerja-cvg4">{namaPerusahaan3} ({posisiPekerjaan3}), {tanggalMulaiK3} - {tanggalSelesaiK3}</p>
                                                        }
                                                        {namaPerusahaan4 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg4 text-pengalamankerja-cvg4">{namaPerusahaan4} ({posisiPekerjaan4}), {tanggalMulaiK4} - {tanggalSelesaiK4}</p>
                                                        }
                                                        {fresGraduate === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg4 text-pengalamankerja-cvg4">{fresGraduate}</p>
                                                        }
                                                    </div>
                                                    <p className="judul-datapribadi-cvg4 mb-0" style={{marginTop:"-13px"}}>C. Pendidikan</p>
                                                    <div className="box-pendidikan-cvg4">
                                                        <p className="teks-color-cvg4">{namaSekolah1} ({programStudi1}), {tanggalMulaiP1} - {tanggalSelesaiP1}</p>
                                                        {namaSekolah2 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg4 text-pengalamankerja-cvg4">{namaSekolah2} ({programStudi2}), {tanggalMulaiP2} - {tanggalSelesaiP2}</p>
                                                        }
                                                        {namaSekolah3 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg4 text-pengalamankerja-cvg4">{namaSekolah3} ({programStudi3}), {tanggalMulaiP3} - {tanggalSelesaiP3}</p>
                                                        }
                                                        {namaSekolah4 === ""
                                                            ?<p></p>
                                                            :<p className="teks-color-cvg4 text-pengalamankerja-cvg4">{namaSekolah4} ({programStudi4}), {tanggalMulaiP4} - {tanggalSelesaiP4}</p>
                                                        }
                                                    </div>
                                                    <p className="judul-datapribadi-cvg4 mb-0" style={{marginTop:"-13px"}}>D. Keahlian</p>
                                                    <div className="box-keahlian-cvg4">
                                                        <table className='table-cvg4'>
                                                            <tbody>
                                                                <tr className='tr-cvg4'>
                                                                    <td className='teks-color-cvg4' width="50%">{keahlian1}</td>
                                                                    <td className='teks-color-cvg4' width="50%">{keahlian5}</td>
                                                                </tr>
                                                                <tr className='tr-cvg4'>
                                                                    <td className='teks-color-cvg4' width="50%">{keahlian2}</td>
                                                                    <td className='teks-color-cvg4' width="50%">{keahlian6}</td>
                                                                </tr>
                                                                <tr className='tr-cvg4'>
                                                                    <td className='teks-color-cvg4' width="50%">{keahlian3}</td>
                                                                    <td className='teks-color-cvg4' width="50%">{keahlian7}</td>
                                                                </tr>
                                                                <tr className='tr-cvg4'>
                                                                    <td className='teks-color-cvg4' width="50%">{keahlian4}</td>
                                                                    <td className='teks-color-cvg4' width="50%">{keahlian8}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            :<div style={{display:"none"}}></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* NAVBOT */}
                        <Suspense fallback={<div></div>}>
                            <MenuNavbarBot/>
                        </Suspense>
                    </div>
                {/* TUTUP KONTEN */}
            </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU WE CV DOWNLOAD
// EKSPOR
export default MenuWeCvDownload