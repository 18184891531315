// IMPORT REACT
import React,{ useState, useEffect, useMemo, lazy, Suspense } from 'react'
import axios from "axios"
import jwt_decode from "jwt-decode" 
import { useParams, useNavigate } from "react-router-dom"

// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuSidebar = lazy(() => import('../Layout/MenuSideBar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbar = lazy(() => import('../Layout/MenuNavbar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbarBot = lazy(() => import('../Layout/MenuNavbarBot.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuDonasi = lazy(() => import('../Layout/MenuDonasi.jsx'));

const MenuWeCv = () => {
    
    const { panggilanParams } = useParams();
    const [panggilan, setPanggilan] = useState('')
    const [expire, setExpire] = useState('')
    const [, setToken] = useState('')
    const navigate = useNavigate()

    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            if(panggilanParams !== decoded.panggilan){
                try {
                    await axios.delete('https://server13.webew.id/logoutBE')
                    navigate("/login/dashboard", { replace: true })
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-cv", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })

    // PROSES LOOP JENIS CV
    const jenisWeCv = useMemo(() =>[
        { title: 'CV Standar I', imgSrc: 'https://webew.id/img/bikinCV/cvg1.webp', alt: 'Bikin CV Standar I', createLink: `/menu-we-cv-form/${panggilan}/CVStandarI` },
        { title: 'CV Standar II', imgSrc: 'https://webew.id/img/bikinCV/cvg2.webp', alt: 'Bikin CV Standar II', createLink: `/menu-we-cv-form/${panggilan}/CVStandarII` },
        { title: 'CV Standar III', imgSrc: 'https://webew.id/img/bikinCV/cvg3.webp', alt: 'Bikin CV Standar III', createLink: `/menu-we-cv-form/${panggilan}/CVStandarIII` },
        { title: 'CV Standar IV', imgSrc: 'https://webew.id/img/bikinCV/cvg4.webp', alt: 'Bikin CV Standar IV', createLink: `/menu-we-cv-form/${panggilan}/CVStandarIV` },
    ], [panggilan]);

    return (
        <div id="wrapper">

            {/* MODAL DONASI */}
                <Suspense fallback={<div></div>}>
                    <MenuDonasi/>
                </Suspense>
            {/* TUTUP MODAL DONASI */}

            {/* SIDEBAR */}
                <Suspense fallback={<div></div>}>
                    <MenuSidebar/>
                </Suspense>
            {/* TUTUP SIDEBAR */}

            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">

                    {/* NAVBAR */}
                        <Suspense fallback={<div></div>}>
                            <MenuNavbar/>
                        </Suspense>
                    {/* TUTUP NAVBAR */}

                    {/* KONTEN */}
                        <div  className="wrap-menu px-1">
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">

                                        <div className="col-12 pb-2 pt-4">
                                            <h4 className='text-center' style={{fontWeight:'700'}}>We CV</h4>
                                            <p className='text-center fw-semibold'>Buat CV Secara Online Dengan We CV</p>
                                        </div>

                                    </div>
                                    <div className="row mb-5 pb-4">
                                        {jenisWeCv.map((data, index) => (

                                            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3" id="cv">
                                                <div className='card mb-4 pt-3 pb-0 px-2' style={{ width: '100%' }}>
                                                    <h5 className='card-title text-center mt-2 fw-bold'>{data.title}</h5>
                                                    <img loading="lazy" src={data.imgSrc}className='mx-auto img-fluid mt-1'style={{ maxHeight: '450px',display: 'block' }}alt={data.alt}/>
                                                    <div className='card-body'>
                                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                                        <p className='card-text-rupiah'>Rp 5.000</p>
                                                        <a href={data.createLink} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Bikin</a>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                            </div>

                                {/* NAVBOT */}
                                <Suspense fallback={<div></div>}>
                                    <MenuNavbarBot/>
                                </Suspense>
                                {/* TUTUP NAVBOT */}

                        </div>
                    {/* TUTUP KONTEN */}

                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    )
} 

export default MenuWeCv