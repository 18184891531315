// IMPORT REACT
import React, { useState, lazy, Suspense } from 'react'

// IMPORT NAVBAR BOTTOM DAN MODAL SECARA LAZY LOADING
const NavbarBot = lazy(() => import('../../Layout/NavbarBot.jsx'));
const Modal = lazy(() => import('../../Layout/Modal.jsx'));

const WeNabung = () => {

    return (
        <div className='background-home' style={{marginTop:'55px'}}>

            {/* MODAL IKLAN */}
                <Suspense fallback={<div></div>}>
                    <Modal/>
                </Suspense>
            {/* TUTUP MODAL IKLAN */}

            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>

                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>We Nabung</h2>
                                <p className='text-webew-home fw-normal'>Yuk catat pemasukan atau pengeluaran keuangan kamu dan lihat progress tabungan kamu dengan We Nabung.</p>
                                <a href={'/'} className='btn btn-webew-product' aria-label='Kembali webew.id'><i className="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                            </div>
                            
                            <div className='col-12 col-lg-6'>
                                <img loading="lazy" src='https://webew.id/img/25559969_7094814.webp' className='img-webew-home mx-auto mt-4' style={{width: '100%',minHeight:'200px',display: 'block' }} alt='by Vectorjuice on freepik'/>
                            </div>

                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}

            <img loading="lazy" src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>

            {/* BOX WE ULTAH */}
                <section>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            
                            <div className='col-12 col-lg-6'>
                                <div className="box p-4">
                                    <h3 className='text-center fw-bold'>Buka We Nabung</h3>
                                    <p className='text-webew-home fw-normal'>Silahkan Login untuk Masuk ke halaman We Nabung. Kamu bisa mencatat pemasukan atau pengeluaran keuanganmu dan melihat progress tabungan kamu sudah sejauh mana</p>
                                    <a href={'https://webew.id/login/menu-we-nabung'} className='btn btn-webew-product' aria-label='Produk webew.id'><i className="bi bi-box-arrow-in-right"></i> Login</a>
                                </div>
                            </div>

                        </div>
                    </div>   
                </section>
            {/* TUTUP BOX WE ULTAH */}
            
            <img loading="lazy" src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>

            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>

                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_25559969.htm#page=4&query=programming&position=7&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Freepik di Freepik'>
                                    <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img loading="lazy" src='https://webew.id/img/credit/25559969_7094814.webp' className='mx-auto'  style={{width: '100%',minHeight:'110px',display: 'block' }} alt='by Freepik on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Freepik on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}

            {/* NAVBAR BOTTOM */}
                <Suspense fallback={<div></div>}>
                    <NavbarBot />
                </Suspense>
            {/* TUTUP NAVBAR BOTTOM */}

        </div>
    )
}

export default WeNabung