
// IMPORT REACT
import React,{ useRef, useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const WeUltahHasil = () => {
    
    const { kode } = useParams();
    const [displayUcapan, setDisplayUcapan] = useState('')
    const [displaySuka, setDisplaySuka] = useState('none')
    const [displayTerimakasih, setDisplayTerimakasih] = useState('none')
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    
    const [data, setData] = useState({
        nama: "",
        urlFoto: "",
        tempatLahir: "",
        tanggalLahir: "",
        ucapan: "",
        warna: "",
    });
    
    // PROSES MENAMPILKAN DATA WE ULTAH BERDASARKAN KODE BY PARAMS
    const selectWeUltahByKode = useCallback(async () => {
        try {
            const response = await axios.get(`https://server13.webew.id/selectWeUltahByKodeBE/${kode}`);
            setData(response.data);
        } catch (error) {
            console.error(error.response?.data?.msg || "Terjadi kesalahan.");
        }
    }, [kode]);

    useEffect(() => {
        selectWeUltahByKode();
    }, [selectWeUltahByKode]);

    // BUTTON COBA KLIK INI
    const buttonCobaKlikIni = () => {
        setDisplayUcapan('none')
        setDisplaySuka('')
    }

    // BUTTON SUKA
    const buttonSuka = () => {
        setDisplayUcapan('none')
        setDisplaySuka('none')
        setDisplayTerimakasih('')
    }
    // BUTTON TERIMAKASIH
    const buttonTerimakasih = () => {
        setDisplayUcapan('')
        setDisplaySuka('none')
        setDisplayTerimakasih('none')
    }
    // BUTTON PLAY MUSIC
    const handlePlay = () => {
        audioRef.current.play()
        setIsPlaying(true)
    }
    // BUTTON PAUSE MUSIC
    const handlePause = () => {
        audioRef.current.pause()
        setIsPlaying(false)
    }
    // BUTTON PLAY PAUSE MUSIC
    function handlePlayPause() {
        if (isPlaying) {
            handlePause()
        } else {
            handlePlay()
        }
    }
    
    return (
        <div className='background-home' style={{backgroundColor:data.warna,minHeight:'100vh'}}>            
            <p className='animate__animated animate__bounceOutUp animate__delay-3s' style={{position:'fixed',width:'100%',height:'100vh',backgroundColor:data.warna,paddingTop:'40vh',textAlign:'center',fontSize:'8rem',color:'rgb(78, 78, 78)',textShadow:'3px 3px white'}}>1</p>
            <p className='animate__animated animate__bounceOutUp animate__delay-2s' style={{position:'fixed',width:'100%',height:'100vh',backgroundColor:data.warna,paddingTop:'40vh',textAlign:'center',fontSize:'8rem',color:'rgb(78, 78, 78)',textShadow:'3px 3px white'}}>2</p>
            <p className='animate__animated animate__bounceOutUp animate__delay-1s' style={{position:'fixed',width:'100%',height:'100vh',backgroundColor:data.warna,paddingTop:'40vh',textAlign:'center',fontSize:'8rem',color:'rgb(78, 78, 78)',textShadow:'3px 3px white'}}>3</p>
            <div id='loader' className='container'>
                <div className='row' style={{paddingTop:'80px'}}>
                    <div className='col-12 col-md-5'>
                        <div id='box' className='box p-3 animate__animated animate__tada animated__slow animate__delay-4s' style={{backgroundColor:'white',minHeight:'180px'}}>
                            <img loading="lazy" src={'https://server13.webew.id/images/'+data.urlFoto} className='mx-auto d-block animate__animated animate__pulse animate__infinite animate__slow' alt='foto' style={{minWidth:'170px',maxWidth:'170px',borderRadius:'30px'}}/>
                        </div>
                        <div id='box' className='box p-4 my-4 animate__animated animate__rubberBand animate__delay-5s' style={{backgroundColor:'white',minHeight:'100px'}}>
                            <p className='fw-bolder text-center animate__animated animate__pulse animate__infinite animate__slow' style={{marginTop:'0'}}>{data.nama}</p>
                            <p className='fw-semibold text-center animate__animated animate__pulse animate__infinite animate__slow' style={{marginTop:'-8px'}}>Telah lahir di {data.tempatLahir}</p>
                            <p className='fw-semibold text-center animate__animated animate__pulse animate__infinite animate__slow' style={{marginTop:'-8px',marginBottom:'0'}}>Pada tanggal {data.tanggalLahir}</p>
                        </div>
                        <figure className='animate__animated animate__pulse animate__infinite animate__slow'>
                            <div className='mx-auto d-block'>
                                <audio ref={audioRef} src='/song/songUltah.mp3' autoPlay loop preload='none'/>
                                <button onClick={handlePlayPause} style={{width:'100%',height:'60px',border:'none',borderRadius:'20px',backgroundColor:'white',color:'grey'}}>
                                    <span>
                                    {isPlaying ? <i className='bi bi-pause-circle' style={{fontSize:'30px'}}></i> :<i className='bi bi-play-circle' style={{fontSize:'30px'}}></i>}
                                    </span>
                                </button>
                            </div>
                            <a href='https://www.youtube.com/watch?v=QlxarnB7bpA' className='text-center mx-auto d-block fw-bold' aria-label='song ultah'>Song By Danial Saxman</a>
                        </figure>
                    </div>
                    <div className='col-12 col-md-7'>
                        <div id='box' className='box p-4 mb-4 animate__animated animate__rubberBand animate__delay-5s' style={{backgroundColor:'white',minHeight:'180px'}}>
                            <h5 className='fw-bolder text-center animate__animated animate__pulse animate__infinite animate__slow'>Selamat Ulang Tahun</h5>
                            <p className='mt-3 fw-semibold animate__animated animate__pulse animate__infinite animate__slow'>Ucapan dari seseorang :</p>
                            <div style={{height:'2px', width:'100%', backgroundColor:'#858585', marginTop:'-12px',marginBottom:'10px'}}></div>
                            <p className='mt-3 fw-semibold' style={{display:displayUcapan}}>{data.ucapan}</p>
                            <button onClick={buttonCobaKlikIni} className='btn animate__animated animate__pulse animate__infinite animate__slow' style={{border:'5px solid #545454',borderRadius:'20px',color:'#545454',fontWeight:'600',display:displayUcapan,width:'100%'}}>Coba klik ini ya</button>
                            <p className='mt-3 fw-semibold' style={{display:displaySuka}}>Kamu suka atau ngga di ucapin ulang tahun ini ?</p>
                            <div className='row'>
                                <div className='col-6'>
                                    <button onClick={buttonSuka} className='btn animate__animated animate__pulse animate__infinite animate__slow' style={{border:'5px solid #545454',borderRadius:'20px',color:'#545454',fontWeight:'600',display:displaySuka,width:'100%'}}>Suka dong</button>
                                </div>
                                <div className='col-6'>
                                    <button className='btn btn-ngga' style={{border:'5px solid #545454',borderRadius:'20px',color:'#545454',fontWeight:'600',display:displaySuka,width:'100%'}}>Ngga suka</button>
                                </div>
                            </div>
                            <p className='mt-2 fw-semibold' style={{display:displayTerimakasih}}>Yey kamu suka, terimakasih ya</p>
                            <button onClick={buttonTerimakasih} className='btn animate__animated animate__pulse animate__infinite animate__slow' style={{border:'5px solid #545454',borderRadius:'20px',color:'#545454',fontWeight:'600',display:displayTerimakasih,width:'100%'}}>Terimakasih kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WeUltahHasil