// IMPORT REACT
import React,{ useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion";
import { useParams } from "react-router-dom"

const WeAqiqahContoh = () => {
    
    const [dataWeAqiqah, setDataWeAqiqah] = useState({})

    const [showPage, setShowPage] = useState(true);
    const [showMainPage, setShowMainPage] = useState(false);
    const { jenisWeAqiqahParams } = useParams();

    // USEEFFECT
    useEffect(()=>{
        if (jenisWeAqiqahParams) {
            const data = {
                colorI: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "linear-gradient(180deg,#c4ebff,white)",
                    backgroundImage2: "linear-gradient(to top,#c4ebff,white)",
                    border: "3px solid #c4ebff",
                    border2: "none",
                    color: "#ffffff",
                    link: "https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph",
                    pemilik: "By studioredcup",
                },
                colorII: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "linear-gradient(180deg,#f5dcff,white)",
                    backgroundImage2: "linear-gradient(to top,#f5dcff,white)",
                    border: "3px solid #f5dcff",
                    border2: "none",
                    color: "#ffffff",
                    link: "https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph",
                    pemilik: "By studioredcup",
                },
                colorIII: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "linear-gradient(180deg,#ffdcdc,white)",
                    backgroundImage2: "linear-gradient(to top,#ffdcdc,white)",
                    border: "3px solid #ffdcdc",
                    border2: "none",
                    color: "#ffffff",
                    link: "https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph",
                    pemilik: "By studioredcup",
                },
                colorIV: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "linear-gradient(180deg,#dcfff0,white)",
                    backgroundImage2: "linear-gradient(to top,#dcfff0,white)",
                    border: "3px solid #dcfff0",
                    border2: "none",
                    color: "#ffffff",
                    link: "https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph",
                    pemilik: "By studioredcup",
                },
                flowerI: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/17437651_5631800.webp')",
                    backgroundImage2: "#ffffff99",
                    border: "3px solid #de8f8f",
                    border2: "3px solid #de8f8f",
                    color: "#ffffff",
                    link: "https://www.freepik.com/free-vector/watercolor-copy-space-background-with-floral-hand-drawn-elements_12263072.htm#page=2&query=flower%20background&position=27&from_view=search&track=ais",
                    pemilik: "By freepik",
                },
                flowerII: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12552268_5023096.webp')",
                    backgroundImage2: "#ffffff99",
                    border: "3px solid #768280",
                    border2: "3px solid #768280",
                    color: "#ffffff",
                    link: "https://www.freepik.com/free-vector/watercolor-background-with-hand-drawn-elements_12552268.htm?query=flower%20background#from_view=detail_alsolike",
                    pemilik: "By pikisuperstar",
                },
                flowerIII: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/8845961_4004353.webp')",
                    backgroundImage2: "#ffffff99",
                    border: "3px solid #a57fc1",
                    border2: "3px solid #a57fc1",
                    color: "#ffffff",
                    link: "https://www.freepik.com/free-vector/powder-pastel-with-hand-drawn-elements-background_8845961.htm#page=3&query=flower%20background&position=8&from_view=search&track=ais",
                    pemilik: "By pikisuperstar",
                },
                flowerIV: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12557301_5014255.webp')",
                    backgroundImage2: "#ffffff99",
                    border: "3px solid #6599ba",
                    border2: "3px solid #6599ba",
                    Color: "#ffffff",
                    Link: "https://www.freepik.com/free-vector/watercolor-background-with-hand-drawn-elements_12557301.htm?query=flower%20background#from_view=detail_alsolike",
                    Pemilik: "By Freepik",
                },
                whiteI: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/15628817_5655049.webp')",
                    backgroundImage2: "#ffffff99",
                    border: "none",
                    border2: "none",
                    color: "#fffff",
                    link: "https://www.freepik.com/free-vector/gradient-white-monochrome-background_15628817.htm#query=white%20background&position=32&from_view=search&track=ais",
                    pemilik: "By Freepik",
                },
                whiteII: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12230268_4931029.webp')",
                    backgroundImage2: "#ffffff99",
                    border: "none",
                    border2: "none",
                    color: "#fffff",
                    link: "https://www.freepik.com/free-vector/smooth-white-wave-background_12230268.htm#query=white%20background&position=17&from_view=search&track=ais",
                    pemilik: "By pikisuperstar",
                },
                whiteIII: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12220552_4935872.webp')",
                    backgroundImage2: "#ffffff99",
                    border: "none",
                    border2: "none",
                    color: "#fffff",
                    link: "https://www.freepik.com/free-vector/white-abstract-background_12220552.htm#page=3&query=white%20background&position=20&from_view=search&track=ais",
                    pemilik: "By Freepik",
                },
                whiteIV: {
                    backgroundColor: "#0012294d",
                    backgroundImage1: "url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/14571701_5487739.webp')",
                    backgroundImage2: "#ffffff99",
                    border: "none",
                    border2: "none",
                    color: "#fffff",
                    link: "https://www.freepik.com/free-vector/paper-style-smooth-background_14571701.htm#page=4&query=white%20background&position=10&from_view=search&track=ais",
                    pemilik: "By Freepik",
                },
            }
            setDataWeAqiqah(data[jenisWeAqiqahParams] || {});
        }
    }, [jenisWeAqiqahParams])

    // BUTTON BUKA UNDANGAN
    const handleBukaUndangan = () => {
        setShowPage(false);
        setShowMainPage(true);
    }
    
    return ( 
        <div className="background-home">

            {/* COVER UNDANGAN */}
            <AnimatePresence>
                {showPage && (
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor: `${dataWeAqiqah?.backgroundColor}`,zIndex:"1",overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight:"100vh",width:"100%",zIndex:"2"}}>
                                <motion.div style={{backgroundImage:`${dataWeAqiqah?.backgroundImage1}`,height:"auto",width:"100%",zIndex:"2",borderRadius:"20px",padding:"30px"}}initial={{ scale: 0.1 }}animate={{ scale: 1 }}exit={{ opacity: 0, y:-500 }}transition={{ duration: 1 }}>
                                    <img loading="lazy" src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/lovely-newborn-asian-baby-sleeping-furry-cloth.webp" className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:`0px 10px 25px -14px ${dataWeAqiqah?.btnColorCD}`}} alt="pengantin" />
                                    <h5 className='fw-bold bad-script-regular text-center' style={{marginTop:"30px",color:[dataWeAqiqah?.fntColor]}}>Kepada Yth. Bpk/Ibu/Saudara/i</h5>
                                    <p className='fw-normal courgette-regular m-0 text-center' style={{color:[dataWeAqiqah?.fntColor],fontSize:"40pt"}}>Fiscal</p>
                                    <button onClick={handleBukaUndangan} className="form form-control fw-bold bad-script-regular" style={{backgroundColor:`${dataWeAqiqah?.color}`,borderRadius:"20px",border:`${dataWeAqiqah?.border}`,color:"rgb(108, 108, 108)",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)",fontSize:"14pt"}}><i className="bi bi-envelope-paper-heart-fill"></i> Buka Undangan <i className="bi bi-envelope-paper-heart-fill"></i></button>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                )}
            </AnimatePresence>

            {/* MAIN PAGE */}
            <AnimatePresence>
                {showMainPage && (
                    <motion.div initial={{ y:500 }}animate={{ y:0 }}transition={{ duration: 1 }}>                              
                        <section style={{backgroundImage:`${dataWeAqiqah?.backgroundImage1}`,width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                            {/* BOX 1 */}
                            <div className="container-fluid">
                                <div className="row justify-content-center p-1">
                                    <div className="col-12" style={{margin:"10px 0 10px 0"}}>
                                        <div className='box p-4' style={{backgroundImage:`${dataWeAqiqah?.backgroundImage2}`,backgroundColor:`${dataWeAqiqah?.backgroundImage2}`,width:"100%",height:"auto",border:`${dataWeAqiqah?.border2}`}}>
                                            <div className="row">
                                                <div className="col-12 col-lg-6">
                                                    <h1 className='text-center mt-2 mb-4' style={{fontFamily:"initial"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                                                    <h3 className='text-center fw-bold bad-script-regular mb-4 pb-2'>Tasyakuran Aqiqah</h3>
                                                    <img loading="lazy" src="https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/lovely-newborn-asian-baby-sleeping-furry-cloth.webp" className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 20px -20px #8e8e8e"}} alt="Aqiqahan" />
                                                    <h5 className='text-center fw-normal bad-script-regular mt-4 pt-2 mb-4'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami</h5>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <h4 className='text-center fw-bold courgette-regular mb-4' style={{fontSize:"30pt"}}>Asrul Dahlan Pangestu</h4>
                                                    <h5 className='text-center fw-normal bad-script-regular mb-4'>Berjenis kelamin laki-laki <br/> pada 20 Februari 2023</h5>
                                                    <h5 className='text-center fw-normal bad-script-regular'>Yang akan dilaksanakan pada :</h5>
                                                    <h5 className='text-center fw-bold bad-script-regular'>Senin, 27 Februari 2023</h5>
                                                    <h5 className='text-center fw-normal bad-script-regular fw-bold'>10.00 - 14.00</h5>
                                                    <h5 className='text-center fw-normal bad-script-regular mb-4'>Jl. Anggrek 22, Blok A.33 No.24, RT. 021 RW. 020, Desa Sukamaju, Tambun Selatan</h5>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-4 mb-2">
                                                            <a href="https://www.google.com" aria-label="Google Maps">
                                                                <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-normal bad-script-regular" style={{backgroundColor:`${dataWeAqiqah?.color}`,border:`${dataWeAqiqah?.border}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108,108,108)",fontSize:"14pt"}}/>
                                                            </a>
                                                        </div>
                                                        <div className="col-12 col-md-4 mb-4">
                                                            <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                                <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 py-2 px-4 fw-normal bad-script-regular" style={{backgroundColor:`${dataWeAqiqah?.color}`,border:`${dataWeAqiqah?.border}`,borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"0 10px 20px -15px rgb(108,108,108)",fontSize:"14pt"}}/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <h5 className='text-center fw-normal bad-script-regular mb-4'>Semoga Allah SWT menjadikan anak yang sholeh, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h5>
                                                    <h5 className='text-center fw-normal bad-script-regular mb-3'>Kami yang berbahagia</h5>
                                                    <p className='text-center fw-bold courgette-regular' style={{fontSize:"30pt"}}>Aji & Siti</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* BOX 2 */}
                            <div className='p-4' style={{backgroundImage:`${dataWeAqiqah?.backgroundImage2}`,backgroundColor:`${dataWeAqiqah?.backgroundImage2}`,width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",border:`${dataWeAqiqah?.border2}`}}>
                                <p className='text-center fw-bold my-4 courgette-regular' style={{fontSize:"25pt"}}>Website By Webew.id</p>
                                <h3 className='text-center fw-normal courgette-regular' style={{marginBottom:"-2px"}}>Image On Freepik</h3>
                                <p style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "auto" }}>
                                    <a href="https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph" className=" bad-script-regular" aria-label="By studioredcup"style={{textAlign: "center",fontSize: "10pt",textDecoration: "none",}}>By studioredcup</a>
                                    <a href={dataWeAqiqah?.link} className=" bad-script-regular" aria-label={dataWeAqiqah?.pemilik}style={{textAlign: "center",fontSize: "10pt",textDecoration: "none",}}>{dataWeAqiqah?.pemilik}</a>
                                </p>
                            </div>
                        </section>  
                    </motion.div>
                )}
            </AnimatePresence>

        </div>
    )
}

export default WeAqiqahContoh