// IMPORT REACT
import React, {useState, useRef, useMemo, lazy, Suspense} from 'react'

// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const NavbarBot = lazy(() => import('../../Layout/NavbarBot.jsx'));

const WePernikahan = () => {

    const [selectedViewLink, setSelectedViewLink] = useState("");
    const [selectedNama, setSelectedNama] = useState("");
    const modalRef = useRef(null);

    // BUTTON VIEW MODAL
    const handleBuatClick = (viewLink, nama) => {
        setSelectedViewLink(viewLink);
        setSelectedNama(nama);
        const modal = new window.bootstrap.Modal(modalRef.current);
        modal.show();
    };

    // PROSES LOOP JENIS WE PERNIKAHAN
    const jenisWePernikahan = useMemo(() => [
        { viewLink: '/we-pernikahan/aglaonema', nama: 'Aglaonema', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/aglaonema.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/agapanthus', nama: 'Agapanthus', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/agapanthus.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/alamanda', nama: 'Alamanda', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/alamanda.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/amaryllis', nama: 'Amaryllis', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/amaryllis.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/bnwI', nama: 'Bnw I', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnwI.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/bnwII', nama: 'Bnw II', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnwII.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/bnwIII', nama: 'Bnw III', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnwIII.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/bnwIV', nama: 'Bnw IV', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnwIV.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/blegold', nama: 'BleGold', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blegold.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/blepink', nama: 'BlePink', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blepink.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/bletosca', nama: 'BleTosca', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bletosca.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/blewhite', nama: 'BleWhite', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blewhite.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/cute', nama: 'Cute', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/cute.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/adorable', nama: 'Adorable', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/adorable.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/beauty', nama: 'Beauty', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/beauty.webp', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/cool', nama: 'Cool', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/cool.webp', alt: 'Undangan Digital Pernikahan' }
    ], []);

    return (
        <div className='background-home' style={{marginTop:'55px'}}>

            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>

                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>We Pernikahan</h2>
                                <p className='text-webew-home fw-normal'>Buat undangan digital disini, tinggal pilih tema undangan digital, isi form data dengan benar, dan undangan digital sudah jadi</p>
                                <a href={'/'} className='btn btn-webew-home' aria-label='Kembali webew.id'><i className="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                            </div>

                            <div className='col-12 col-lg-6'>
                                <img loading="lazy" src='https://webew.id/img/19212427_Business people arranging appointment in digital booking app.webp' className='img-webew-home mx-auto mt-4' style={{width: '100%',minHeight:'200px',display: 'block' }} alt='by Freepik on freepik'/>
                            </div>

                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}

            <img loading="lazy" src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>

            {/* JENIS WE PERNIKAHAN */}
                <section>
                    <div className='container'>

                        {/* Fitur We Pernikahan */}
                        <div className="row justify-content-center">

                            <div className="col-10 col-sm-12 col-md-10 col-lg-8">
                                <div className="card mb-4 pt-3 px-2" style={{width:"100%"}}>
                                    <h5 className='card-title fw-bold text-center mt-2 mb-3'>Fitur harga Rp45.000</h5>
                                    <div style={{backgroundColor:"grey", width:"100%", height:"1px"}}></div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <p><i className="bi bi-check2-circle"></i> Halaman Penerimaan Tamu</p>
                                                <p><i className="bi bi-check2-circle"></i> Countdown Acara Pernikahan</p>
                                                <p><i className="bi bi-check2-circle"></i> Profil Pasangan Dengan Detail</p>
                                                <p><i className="bi bi-check2-circle"></i> Social Media Pengantin</p>
                                                <p><i className="bi bi-check2-circle"></i> Simpan Kalender Acara</p>
                                                <p><i className="bi bi-check2-circle"></i> Rute Google Maps</p>
                                                <p><i className="bi bi-check2-circle"></i> Pilihan Ayat Al-Quran</p>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <p><i className="bi bi-check2-circle"></i> Gallery Pengantin (Max 6 Foto)</p>
                                                <p><i className="bi bi-check2-circle"></i> Kado Pengantin (Cashless)</p>
                                                <p><i className="bi bi-check2-circle"></i> Ucapan Dan Doa Live</p>
                                                <p><i className="bi bi-check2-circle"></i> Musik Indonesia, Inggris, Islami</p>
                                                <p><i className="bi bi-check2-circle"></i> Isi Data Sendiri</p>
                                                <p className='fw-bold'><i className="bi bi-check2-circle"></i> Masa AktiF Selamanya</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        {/* Tutup Fitur We Pernikahan */}

                        {/* Jenis We Pernikahan */}
                        <div className='row'>
                            {jenisWePernikahan.map((data, index) => (

                                <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3'>
                                    <button onClick={() => handleBuatClick(data.viewLink, data.nama)} className='card mb-4 p-4' style={{ width: '100%' }}>
                                        <div className="row justify-content-center text-center mx-auto d-block">
                                            <div className="col-12 mb-2">
                                                <h5 className='fw-bold text-center'>{data.nama}</h5>
                                            </div>
                                            <div className="col-12 mt-4">
                                                <img loading="lazy" src={data.imgSrc} className='mx-auto img-fluid' style={{ maxHeight: '450px',borderRadius:"30px",border:"6px solid black",display: 'block' }} alt={data.alt} />
                                            </div>
                                        </div>
                                    </button>
                                </div>

                            ))}
                        </div>
                        {/* Tutup Jenis We Pernikahan */}

                        {/* Modal Bootstrap */}
                        <div className="modal fade" id="buatModal" ref={modalRef} tabIndex="-1" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body text-center p-4">
                                        <div className="row">
                                            
                                            <div className="col-12 my-4">
                                                <button className='btn btn-webew-product py-2' onClick={() => window.location.href = `${selectedViewLink}`}>Lihat Contoh Undangan</button>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <button className='btn btn-webew-product py-2' onClick={() => window.location.href = `https://webew.id/login/menu-we-pernikahan`}>Buat Undangan Sendiri</button>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <a className='btn btn-webew-product py-2' href={`https://api.whatsapp.com/send?phone=6287788362272&text=Halo admin webewid, saya ingin memesan Undangan Digital Pernikahan dengan tema "${selectedNama}"`} aria-label='Whatsapp'>Pesan Lewat WhatsApp</a>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <button type="button" className="btn btn-logout mx-auto py-2 px-4" data-bs-dismiss="modal" aria-label="Close">Batal</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Tutup Modal Bootstrap */}

                    </div>
                </section>
            {/* TUTUP JENIS WE PERNIKAHAN */}

            <img loading="lazy" src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>

            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>

                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/business-people-arranging-appointment-digital-booking-app_19212427.htm#page=4&query=app&position=22&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Pch-vector Freepik'>
                                    <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img loading="lazy" src='https://webew.id/img/credit/19212427_Business people arranging appointment in digital booking app.webp' className='mx-auto d-block'  style={{width: '100%',minHeight:'120px',display: 'block' }} alt='by Pch-vector on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Pch-vector on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}

           {/* NAVBAR BOTTOM */}
                <Suspense fallback={<div></div>}>
                    <NavbarBot />
                </Suspense>
            {/* TUTUP NAVBAR BOTTOM */}

        </div>
    )
}

export default WePernikahan