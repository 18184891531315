import React from "react";

export default function LoadingPage({ progress }) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100 position-fixed top-0 start-0"style={{backgroundColor: "#282a3a8b",backdropFilter: "blur(10px)",zIndex: 1050}}>
        <div className="fs-5 fw-bold mb-2" style={{ color: "white" }}>Tunggu Ya</div>
        <div className="bg-white rounded-pill overflow-hidden" style={{ width: "50%", height: "20px"}}>
            <div className="bg-primary h-100"style={{ width: `${progress}%`, transition: "width 0.3s ease-in-out" }}></div>
        </div>
        <div className="fs-5 fw-bold mt-2" style={{ color: "white" }}>{Math.round(progress)}%</div>
  </div>
  );
}
