// IMPORT REACT
import React,{ useState, useEffect, lazy, Suspense } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE PARAMS, USE NAVIGATE, LINK
import { useParams, useNavigate } from "react-router-dom"
// IMPORT LOADING PAGE
import LoadingPage from '../../Layout/LoadingPage.jsx'
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuSidebar = lazy(() => import('../../Layout/MenuSideBar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbar = lazy(() => import('../../Layout/MenuNavbar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbarBot = lazy(() => import('../../Layout/MenuNavbarBot.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuDonasi = lazy(() => import('../../Layout/MenuDonasi.jsx'));
// MENU WE AQIQAH FORM
const MenuWeAqiqahForm = () => { // KURUNG BUKA MENU WE AQIQAH FORM
    // MEMBUAT VARIABEL WE AQIQAH FORM
    const { jenisWeAqiqahParams } = useParams();
    const { panggilanParams } = useParams();
    const [panggilan, setPanggilan] = useState('')
    const [msg, setMsg] = useState("")
    const [foto, setFoto] = useState('')
    const [namaAnak, setNamaAnak] = useState('')
    const [jenisKelamin, setJenisKelamin] = useState('')
    const [tanggalLahir, setTanggalLahir] = useState('')
    const [namaBapak, setNamaBapak] = useState('')
    const [namaIbu, setNamaIbu] = useState('')
    const [hariAcara, setHariAcara] = useState('')
    const [tanggalAcara, setTanggalAcara] = useState('')
    const [jamMulai, setJamMulai] = useState('')
    const [jamAkhir, setJamAkhir] = useState('')
    const [alamat, setAlamat] = useState('')
    const [linkGMaps, setLinkGMaps] = useState('')
    // MEMBUAT VARIABEL LOADER HALAMAN
    const [loading, setLoading] = useState(false); // State untuk loading
    const [progress, setProgress] = useState(0); // Progress loading bar
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USE EFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            if(panggilanParams !== decoded.panggilan){
                try {
                    await axios.delete('https://server13.webew.id/logoutBE')
                    navigate("/login/dashboard", { replace: true })
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-aqiqah", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES MENGAMBIL FILE FOTO DARI FORM
    function handleUploadChange(e){
        let uploaded = e.target.files[0]
        setFoto(uploaded)
    }
    // PROSES MENYIMPAN WE AQIQAH KE DB
    const createWeAqiqah = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("foto", foto)
        formData.append("namaAnak", namaAnak)
        formData.append("jenisKelamin", jenisKelamin)
        formData.append("tanggalLahir", tanggalLahir)
        formData.append("namaBapak", namaBapak)
        formData.append("namaIbu", namaIbu)
        formData.append("hariAcara", hariAcara)
        formData.append("tanggalAcara", tanggalAcara)
        formData.append("jamMulai", jamMulai)
        formData.append("jamAkhir", jamAkhir)
        formData.append("alamat", alamat)
        formData.append("linkGMaps", linkGMaps)
        formData.append("panggilan", panggilan)
        formData.append("jenisUndanganDigitalAqiqah", jenisWeAqiqahParams)
        try{
            setLoading(true);
            setProgress(0);
            let fakeProgress = 0;
            const fastLoading = setInterval(() => {
                fakeProgress += 10;
                setProgress(fakeProgress);
                if (fakeProgress >= 80) {
                    clearInterval(fastLoading);
                }
            }, 30);
            await axios.patch("https://server13.webew.id/createWeAqiqahBE", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            },
            {
                onDownloadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    if (total) {
                        let percentCompleted = Math.round((loaded / total) * 100);
                        setProgress(Math.max(80, percentCompleted)); // Pastikan tidak turun dari 80%
                    } else {
                        // Jika total tidak tersedia, buat progres naik perlahan
                        setProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : prevProgress));
                    }
                }
            });
             // Redirect langsung ke halaman tujuan
             setProgress(100); // Selesaikan progress
             setTimeout(() => {
                 setLoading(false);
                 navigate(`/menu-we-aqiqah-hasil/${panggilan}/${jenisWeAqiqahParams}`, { replace: true })
             }, 500);
        } catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                {loading && <LoadingPage progress={progress} />}
            {/* MODAL DONASI */}
                <Suspense fallback={<div></div>}>
                    <MenuDonasi/>
                </Suspense>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <Suspense fallback={<div></div>}>
                    <MenuSidebar/>
                </Suspense>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <Suspense fallback={<div></div>}>
                            <MenuNavbar/>
                        </Suspense>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="wrap-menu px-1 pb-5">
                            <div className="container-fluid pb-5">
                                <div className="row justify-content-center pt-4">
                                    <div className="col-12 col-lg-4 mb-4">
                                        <div className="col-12">
                                            <div className="box pt-3 pb-4 px-3">
                                                <a href={`/menu-we-aqiqah/${panggilan}`} className="btn btn-webew-product" style={{width:"auto"}}><i className="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                                                <h3 className="title-webew-home" style={{marginTop:"10px"}}>Form We Aqiqah</h3>
                                                <p className="text-webew-home">Silahkan isi form We Aqiqah ini ya</p>
                                                <a href={`/menu-we-aqiqah-hasil/${panggilan}/${jenisWeAqiqahParams}`} className="btn btn-webew-product mx-auto d-block">Lihat Punya Saya</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-8">
                                        <div className="box p-4">
                                            <form onSubmit={createWeAqiqah}>
                                                <div className="accordion" id="accordionPanelsStayOpenExample" style={{marginTop:"-20px"}}>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">Isi Form Untuk Kelengkapan Data</button>
                                                        </h2>
                                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                                            <div className="accordion-body">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="foto">Foto (JPEG/PNG)</label>
                                                                        <input type="file" id="foto" className="form-control form" accept="image/*" onChange={handleUploadChange} required/>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="namaAnak">Nama Anak</label>
                                                                        <input type="text" id="namaAnak" className="form-control form" maxLength="40" value={namaAnak} onChange={(e)=>setNamaAnak(e.target.value)} required/>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="jenisKelamin">Jenis Kelamin</label>
                                                                        <select className="form-select form" id="jenisKelamin" aria-label="jenisKelamin" value={jenisKelamin} onChange={(e)=>setJenisKelamin(e.target.value)} required>
                                                                            <option defaultValue={"Laki-Laki"}>Pilih</option>
                                                                            <option value="Laki-Laki">Laki-Laki</option>
                                                                            <option value="Perempuan">Perempuan</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="tanggalLahir">Tanggal Lahir</label>
                                                                        <input type="date" id="tanggalLahir" className="form-control form" maxLength="40" value={tanggalLahir} onChange={(e)=>setTanggalLahir(e.target.value)} required/>
                                                                        <input type="text" id="jenisWeAqiqah" className="form-disable" value={jenisWeAqiqahParams} readOnly/>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="namaBapak">Nama Bapak</label>
                                                                        <input type="text" id="namaBapak" className="form-control form" maxLength="40" value={namaBapak} onChange={(e)=>setNamaBapak(e.target.value)} required/>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="namaIbu">Nama Ibu</label>
                                                                        <input type="text" id="namaIbu" className="form-control form" maxLength="40" value={namaIbu} onChange={(e)=>setNamaIbu(e.target.value)} required/>
                                                                    </div>
                                                                    <input type="text" id="panggilan" className="form-disable" value={panggilan} readOnly/>
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="hariAcara">Hari Acara</label>
                                                                        <select className="form-select form" id="hariAcara" aria-label="hariAcara" value={hariAcara} onChange={(e)=>setHariAcara(e.target.value)} required>
                                                                            <option defaultValue={"Senin"}>Pilih</option>
                                                                            <option defaultValue="Senin">Senin</option>
                                                                            <option defaultValue="Selasa">Selasa</option>
                                                                            <option defaultValue="Rabu">Rabu</option>
                                                                            <option defaultValue="Kamis">Kamis</option>
                                                                            <option defaultValue="Jumat">Jumat</option>
                                                                            <option defaultValue="Sabtu">Sabtu</option>
                                                                            <option defaultValue="Minggu">Minggu</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="tanggalAcara">Tanggal Acara</label>
                                                                        <input type="date" id="tanggalAcara" className="form-control form" maxLength="40" value={tanggalAcara} onChange={(e)=>setTanggalAcara(e.target.value)} required/>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="jamMulai">Jam Mulai</label>
                                                                        <input type="time" id="jamMulai" className="form-control form" maxLength="40" value={jamMulai} onChange={(e)=>setJamMulai(e.target.value)} required/>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="jamAkhir">Jam Akhir</label>
                                                                        <input type="time" id="jamAkhir" className="form-control form" maxLength="40" value={jamAkhir} onChange={(e)=>setJamAkhir(e.target.value)} required/>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="alamat">Alamat</label>
                                                                        <input type="text" id="alamat" className="form-control form" maxLength="40" value={alamat} onChange={(e)=>setAlamat(e.target.value)} required/>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                        <label style={{fontSize:"10pt"}} htmlFor="linkGMaps">Link GMaps</label>
                                                                        <input type="text" id="linkGMaps" className="form-control form" maxLength="40" value={linkGMaps} onChange={(e)=>setLinkGMaps(e.target.value)} required/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h6 style={{textAlign:"center",marginTop:"20px",marginBottom:"-20px",color:"red"}}>{msg}</h6>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <input type="submit" className="btn btn-webew-product mt-4 py-4" value="Proses Bikin We Aqiqah"/>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            {/* NAVBOT */}
                                <Suspense fallback={<div></div>}>
                                    <MenuNavbarBot/>
                                </Suspense>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* KONTEN WRAPPER */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU WE AQIQAH FORM
// EKSPOR
export default MenuWeAqiqahForm