// IMPORT REACT
import React,{ useRef, useState, useEffect } from 'react'
// NAVBAR
const Navbar = () => {
    // MEMBUAT VARIABEL DONASI
    const [salinBca, setSalinBca] = useState('')
    const [salinBri, setSalinBri] = useState('')
    const [salinBni, setSalinBni] = useState('')
    const [salinLinkAja, setSalinLinkAja] = useState('')
    const [salinMandiri, setSalinMandiri] = useState('')
    const [salinOvo, setSalinOvo] = useState('')
    // MEMBUAT VARIABEL BUTTON SALIN DONASI
    const inputRefBca = useRef(null);
    const inputRefBni = useRef(null);
    const inputRefBri = useRef(null);
    const inputRefLinkAja = useRef(null);
    const inputRefMandiri = useRef(null);
    const inputRefOvo = useRef(null);
    // MEMBUAT VARIABEL BUTTON DOWNLOAD
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstallable, setIsInstallable] = useState(false);
    // USE EFFECT
    useEffect(()=>{
        refresh()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        // Mendeteksi event beforeinstallprompt
        const handler = (e) => {
          e.preventDefault();
          setDeferredPrompt(e);
          setIsInstallable(true); // Tampilkan tombol
        };
        window.addEventListener('beforeinstallprompt', handler);
        return () => window.removeEventListener('beforeinstallprompt', handler);
    }, []);
    // DOWNLOAD INSTALL
    const handleInstallClick = async () => {
        if (deferredPrompt) {
          deferredPrompt.prompt(); // Memunculkan prompt instalasi
          const { outcome } = await deferredPrompt.userChoice;
          if (outcome === 'accepted') {
            console.log('Pengguna menerima instalasi');
          } else {
            console.log('Pengguna menolak instalasi');
          }
          setDeferredPrompt(null); // Reset prompt setelah digunakan
          setIsInstallable(false);
        }
    };
    // REFRESH
    const refresh = async() => {
        setSalinBca('122081211156521')
        setSalinBni('081211156521')
        setSalinBri('081211156521')
        setSalinLinkAja('87788362272')
        setSalinMandiri('893081211156521')
        setSalinOvo('087788362272')
    }
    // MODAL DONASI BCA
    function salinRekBca(e) {
        inputRefBca.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // MODAL DONASI BNI
    function salinRekBni(e) {
        inputRefBni.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // MODAL DONASI BRI
    function salinRekBri(e) {
        inputRefBri.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // MODAL DONASI LINK AJA
    function salinRekLinkAja(e) {
        inputRefLinkAja.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // MODAL DONASI MANDIRI
    function salinRekMandiri(e) {
        inputRefMandiri.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // MODAL DONASI OVO
    function salinRekOvo(e) {
        inputRefOvo.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* MODAL DONASI */}
                {/* MODAL DONASI BCA */}
                <div className='modal fade' id='staticBackdropBCA' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <button type='button' className='btn btn-logout mx-auto py-1 px-3' data-bs-dismiss='modal' aria-label='Tutup'>Tutup</button>
                            </div>
                            <div className='modal-body'>
                                <h5 className='text-center fw-bold'>Kirim hadiah Ke Webew Dengan BCA</h5>
                                <h6 className='text-center'>Silahkan Salin No Virtual Account</h6>
                                <div className='row justify-content-center mb-3'>
                                    <div className='col-12 col-md-6'>
                                        <input type='text' name='link1' id='link1' className='form-control form mt-3' ref={inputRefBca} value={salinBca} readOnly/>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <button type='button' className='btn btn-webew-product' style={{marginTop:'16.5px'}} onClick={salinRekBca}>Salin</button>
                                    </div>
                                </div>
                                <h6 className='mt-2'>Petunjuk dengan MBanking :</h6>
                                <h6>1. Buka aplikasi mobile BCA</h6>
                                <h6>2. Pilih M-Transfer</h6>
                                <h6>3. Pilih BCA Virtual Account</h6>
                                <h6>4. Masukan nomer virtual account, lalu klik send</h6>
                                <h6>5. Masukan nominal uang yang akan diberikan ke webew (min. 10.000)</h6>
                                <h6>6. Lalu proses deh</h6>
                                <h6>Terimakasih ya</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI BRI */}
                <div className='modal fade' id='staticBackdropBRI' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <button type='button' className='btn btn-logout mx-auto py-1 px-3' data-bs-dismiss='modal' aria-label='Tutup'>Tutup</button>
                            </div>
                            <div className='modal-body'>
                                <h5 className='text-center fw-bold'>Kirim hadiah Ke Webew Dengan BRI</h5>
                                <h6 className='text-center'>Silahkan Salin No Handphone Webew</h6>
                                <div className='row justify-content-center mb-3'>
                                    <div className='col-12 col-md-6'>
                                        <input type='text' name='link2' id='link2' className='form-control form mt-3' ref={inputRefBri} value={salinBri} readOnly/>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <button type='button' className='btn btn-webew-product' style={{marginTop:'16.5px'}} onClick={salinRekBri}>Salin</button>
                                    </div>
                                </div>
                                <h6 className='mt-2'>Petunjuk dengan MBanking :</h6>
                                <h6>1. Buka aplikasi mobile BRI</h6>
                                <h6>2. Masuk ke menu Dompet Digital</h6>
                                <h6>3. Pilih Top-Up baru, lalu ShopeePay</h6>
                                <h6>4. Masukan nomer handphone webew</h6>
                                <h6>5. Masukan nominal uang yang akan diberikan ke webew (min. 10.000)</h6>
                                <h6>6. Lalu proses deh</h6>
                                <h6>Terimakasih ya</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI BNI */}
                <div className='modal fade' id='staticBackdropBNI' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <button type='button' className='btn btn-logout mx-auto py-1 px-3' data-bs-dismiss='modal' aria-label='Tutup'>Tutup</button>
                            </div>
                            <div className='modal-body'>
                                <h5 className='text-center fw-bold'>Kirim hadiah Ke Webew Dengan BNI</h5>
                                <h6 className='text-center'>Silahkan Salin No Handphone Webew</h6>
                                <div className='row justify-content-center mb-3'>
                                    <div className='col-12 col-md-6'>
                                        <input type='text' name='link3' id='link3' className='form-control form mt-3' ref={inputRefBni} value={salinBni}readOnly/>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <button type='button' className='btn btn-webew-product' style={{marginTop:'16.5px'}} onClick={salinRekBni}>Salin</button>
                                    </div>
                                </div>
                                <h6 className='mt-2'>Petunjuk dengan MBanking :</h6>
                                <h6>1. Buka aplikasi mobile BNI</h6>
                                <h6>2. Pilih E-Wallet, lalu ShopeePay</h6>
                                <h6>3. Pilih menu input baru</h6>
                                <h6>4. Masukan nomer handphone webew</h6>
                                <h6>5. Masukan nominal uang yang akan diberikan ke webew (min. 10.000)</h6>
                                <h6>6. Lalu proses deh</h6>
                                <h6>Terimakasih ya</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI DANA */}
                <div className='modal fade' id='staticBackdropDana' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <button type='button' className='btn btn-logout mx-auto py-1 px-3' data-bs-dismiss='modal' aria-label='Tutup'>Tutup</button>
                            </div>
                            <div className='modal-body'>
                                <h5 className='text-center fw-bold'>Kirim hadiah Ke Webew Dengan Dana</h5>
                                <img loading="lazy" src='/img/donasi/qrDana.jpeg' className='d-block mx-auto' style={{minHeight:'200px',maxHeight:'300px'}} alt='QR Code Dana Webew' />
                                <a href='https://link.dana.id/qr/k0ici5or' className='text-center fw-semibold d-block mx-auto' style={{color:'blue'}}>https://link.dana.id/qr/k0ici5or</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI LINK AJA */}
                <div className='modal fade' id='staticBackdropLinkAja' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <button type='button' className='btn btn-logout mx-auto py-1 px-3' data-bs-dismiss='modal' aria-label='Tutup'>Tutup</button>
                            </div>
                            <div className='modal-body'>
                                <h5 className='text-center fw-bold'>Kirim hadiah Ke Webew Dengan LinkAja</h5>
                                <h6 className='text-center'>Silahkan Salin No Handphone Webew</h6>
                                <div className='row justify-content-center mb-4'>
                                    <div className='col-12 col-md-6'>
                                        <input type='text' name='link4' id='link4' className='form-control form mt-3' ref={inputRefLinkAja} value={salinLinkAja} readOnly/>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <button type='button' className='btn btn-webew-product' style={{marginTop:'16.5px'}} onClick={salinRekLinkAja}>Salin</button>
                                    </div>
                                </div>
                                <h6 className='mt-2'>Petunjuk :</h6>
                                <h6>1. Buka aplikasi mobile LinkAja</h6>
                                <h6>2. Klik tombol kirim uang</h6>
                                <h6>3. Pilih metode kirim uang 'nomor telepon'</h6>
                                <h6>4. Masukan nomer handphone webew diatas</h6>
                                <h6>Terimakasih ya</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI MANDIRI */}
                <div className='modal fade' id='staticBackdropMandiri' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <button type='button' className='btn btn-logout mx-auto py-1 px-3' data-bs-dismiss='modal' aria-label='Tutup'>Tutup</button>
                            </div>
                            <div className='modal-body'>
                                <h5 className='text-center fw-bold'>Kirim hadiah Ke Webew Dengan Mandiri</h5>
                                <h6 className='text-center'>Silahkan Salin No Virtual Account</h6>
                                <div className='row justify-content-center mb-3'>
                                    <div className='col-12 col-md-6'>
                                        <input type='text' name='link5' id='link5' className='form-control form mt-3' ref={inputRefMandiri} value={salinMandiri} readOnly/>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <button type='button' className='btn btn-webew-product' style={{marginTop:'16.5px'}} onClick={salinRekMandiri}>Salin</button>
                                    </div>
                                </div>
                                <h6 className='mt-2'>Petunjuk dengan MBanking :</h6>
                                <h6>1. Buka aplikasi mobile Mandiri</h6>
                                <h6>2. Pilih Top-Up, lalu pilih E-wallet</h6>
                                <h6>3. Pilih penyedia layanan ShopeePay</h6>
                                <h6>4. Masukan nomer Virtual Account webew</h6>
                                <h6>5. Masukan nominal uang yang akan diberikan ke webew (min. 10.000)</h6>
                                <h6>6. Lalu proses deh</h6>
                                <h6>Terimakasih ya</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI OVO */}
                <div className='modal fade' id='staticBackdropOvo' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <button type='button' className='btn btn-logout mx-auto py-1 px-3' data-bs-dismiss='modal' aria-label='Tutup'>Tutup</button>
                            </div>
                            <div className='modal-body'>
                                <h5 className='text-center fw-bold'>Kirim hadiah Ke Webew Dengan Ovo</h5>
                                <h6 className='text-center'>Silahkan Salin No Handphone Webew</h6>
                                <div className='row justify-content-center mb-4'>
                                    <div className='col-12 col-md-6'>
                                        <input type='text' name='link6' id='link6' className='form-control form mt-3' ref={inputRefOvo} value={salinOvo} readOnly/>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <button type='button' className='btn btn-webew-product' style={{marginTop:'16.5px'}} onClick={salinRekOvo}>Salin</button>
                                    </div>
                                </div>
                                <h6 className=''>Caranya :</h6>
                                <h6 className=''>1. Buka aplikasi mobile Ovo</h6>
                                <h6 className=''>2. Klik tombol Transfer</h6>
                                <h6 className=''>3. Pilih metode 'ke sesama Ovo'</h6>
                                <h6 className=''>4. Masukan nomer handphone webew Diatas</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI PAYPAL */}
                <div className='modal fade' id='staticBackdropPaypal' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <button type='button' className='btn btn-logout mx-auto py-1 px-3' data-bs-dismiss='modal' aria-label='Tutup'>Tutup</button>
                            </div>
                            <div className='modal-body'>
                                <h5 className='text-center fw-bold'>Kirim hadiah Ke Webew Dengan Paypal</h5>
                                <h6 className='text-center'>Klik Link Paypal Webew Dibawah Ini</h6>
                                <a href='https://www.paypal.me/mauladafa' className='text-center fw-semibold d-block mx-auto' style={{color:'blue'}}>https://www.paypal.me/mauladafa</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DONASI SHOPEEPAY */}
                <div className='modal fade' id='staticBackdropShopeePay' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <button type='button' className='btn btn-logout mx-auto py-1 px-3' data-bs-dismiss='modal' aria-label='Tutup'>Tutup</button>
                            </div>
                            <div className='modal-body'>
                                <h5 className='text-center fw-bold'>Kirim hadiah Ke Webew Dengan ShopeePay</h5>
                                <img loading="lazy" src='/img/donasi/qrShopeePay.jpeg' className='d-block mx-auto' style={{minHeight:'200px',maxHeight:'300px'}} alt='QR Code Dana Webew' />
                                <a href='https://wsa.wallet.airpay.co.id/qr/00fa712ffe34185e22d2' className='text-center fw-semibold d-block mx-auto' style={{color:'blue'}}>https://wsa.wallet.airpay.co.id/qr/00fa712ffe34185e22d2</a>
                            </div>
                        </div>
                    </div>
                </div>
            {/* TUTUP MODAL DONASI */}
            {/* NAVBAR */}
            <nav className='navbar navbar-expand-lg fixed-top nav-bar'>
                <div className='container'>
                    {isInstallable && (<button onClick={handleInstallClick} className='btn btn-login px-3 py-2' style={{color:"white",width:"auto",fontSize:"11pt"}}>Install Webew</button>)}
                    <a className='navbar-brand' href='/#' aria-label='webew.id' style={{marginLeft:"20px"}}><img loading="lazy" src='/webew.png' style={{maxHeight:'25px',marginTop:'-7px'}} alt='logo webew' /></a>
                    <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation' style={{border:'none'}}>
                        <i className='bi bi-grid-fill' style={{fontSize:'22px'}}></i>
                    </button>
                    <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                        <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                            <li className='nav-item'><a className='nav-link' aria-current='page' href='/' aria-label='Home'>Home</a></li>
                            <li className='nav-item'><a className='nav-link' aria-current='page' href='/#about' aria-label='About'>About</a></li>
                            <li className='nav-item dropdown'>
                                <a className='nav-link dropdown-toggle' href='/#' aria-label='Produk' role='button' data-bs-toggle='dropdown' aria-expanded='false'>Produk</a>
                                <ul className='dropdown-menu'>
                                    <li><a className='dropdown-item' href={'/we-pernikahan'} aria-label='We Pernikahan' style={{fontSize:'13pt'}}><i className="bi bi-grid"></i> We Pernikahan</a></li>
                                    <li><a className='dropdown-item' href={'/we-aqiqah'} aria-label='We Aqiqah' style={{fontSize:'13pt'}}><i className="bi bi-grid"></i> We Aqiqah</a></li>
                                    <li><a className='dropdown-item' href={'/we-website'} aria-label='We Website' style={{fontSize:'13pt'}}><i className="bi bi-grid"></i> We Website</a></li>
                                    <li><a className='dropdown-item' href={'/we-cv'} aria-label='We CV' style={{fontSize:'13pt'}}><i className="bi bi-grid"></i> We CV</a></li>
                                    <li><a className='dropdown-item' href={'/we-nabung'} aria-label='We Nabung' style={{fontSize:'13pt'}}><i className="bi bi-grid"></i> We Nabung</a></li>
                                    <li><a className='dropdown-item' href={'/we-ultah'} aria-label='We Ultah' style={{fontSize:'13pt'}}><i className="bi bi-grid"></i> We Ultah</a></li>
                                </ul>
                            </li>
                            <li className='nav-item dropdown'>
                                <a className='nav-link dropdown-toggle' href='/#' aria-label='Donasi' role='button' data-bs-toggle='dropdown' aria-expanded='false'>Kirim Hadiah</a>
                                <ul className='dropdown-menu'>
                                    <li><button type='button' className='dropdown-item' data-bs-toggle='modal' data-bs-target='#staticBackdropBCA'><i className='bi-credit-card'></i>BCA</button></li>
                                    <li><button type='button' className='dropdown-item' data-bs-toggle='modal' data-bs-target='#staticBackdropBNI'><i className='bi-credit-card'></i>BNI</button></li>
                                    <li><button type='button' className='dropdown-item' data-bs-toggle='modal' data-bs-target='#staticBackdropBRI'><i className='bi-credit-card'></i>BRI</button></li>
                                    <li><button type='button' className='dropdown-item' data-bs-toggle='modal' data-bs-target='#staticBackdropDana'><i className='bi-credit-card'></i>Dana</button></li>
                                    <li><button type='button' className='dropdown-item' data-bs-toggle='modal' data-bs-target='#staticBackdropLinkAja'><i className='bi-credit-card'></i>LinkAja</button></li>
                                    <li><button type='button' className='dropdown-item' data-bs-toggle='modal' data-bs-target='#staticBackdropMandiri'><i className='bi-credit-card'></i>Mandiri</button></li>
                                    <li><button type='button' className='dropdown-item' data-bs-toggle='modal' data-bs-target='#staticBackdropOvo'><i className='bi-credit-card'></i>Ovo</button></li>
                                    <li><button type='button' className='dropdown-item' data-bs-toggle='modal' data-bs-target='#staticBackdropPaypal'><i className='bi-credit-card'></i>Paypal</button></li>
                                    <li><button type='button' className='dropdown-item' data-bs-toggle='modal' data-bs-target='#staticBackdropShopeePay'><i className='bi-credit-card'></i>ShopeePay</button></li>
                                </ul>
                            </li>
                        </ul>
                        <div className='d-flex justify-content-center' role='search'>
                            <a href='http://www.youtube.com/@webewid' aria-label='Tiktok'><i className='bi-sosmed bi-youtube'></i></a>
                            <a href='https://www.tiktok.com/@fish_call' aria-label='Tiktok'><i className='bi-sosmed bi-tiktok'></i></a>
                            <a href='https://www.instagram.com/webew.id/' aria-label='Instagram'><i className='bi-sosmed bi-instagram'></i></a>
                            <a href='https://api.whatsapp.com/send?phone=6287788362272' aria-label='WhatsApp'><i className='bi-sosmed bi-whatsapp wa'></i></a>
                            <a href='https://webew.id/login/dashboard' className='btn btn-login' type='button' aria-label='Login'>Login</a>
                        </div>
                    </div>
                </div>
            </nav>
            {/* TUTUP NAVBAR */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP NAVBAR
// EKSPOR
export default Navbar