// IMPORT REACT
import React,{ useState, useEffect, lazy, Suspense } from 'react'
import axios from "axios"
import jwt_decode from "jwt-decode"
import LoadingPage from '../Layout/LoadingPage.jsx'
import { useParams, useNavigate } from "react-router-dom"

// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuSidebar = lazy(() => import('../Layout/MenuSideBar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbar = lazy(() => import('../Layout/MenuNavbar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbarBot = lazy(() => import('../Layout/MenuNavbarBot.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuDonasi = lazy(() => import('../Layout/MenuDonasi.jsx'));

const MenuWeUltah = () => { 
    
    const { panggilanParams } = useParams();
    const [msg, setMsg] = useState("")
    const [nama, setNama] = useState('')
    const [foto, setFoto] = useState('')
    const [tempatLahir, setTempatLahir] = useState('')
    const [tanggalLahir, setTanggalLahir] = useState('')
    const [ucapan, setUcapan] = useState('')
    const [warna, setWarna] = useState('#000000')
    const [panggilan, setPanggilan] = useState('')
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [expire, setExpire] = useState('')
    const [, setToken] = useState('')
    const navigate = useNavigate()

    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            if(panggilanParams !== decoded.panggilan){
                try {
                    await axios.delete('https://server13.webew.id/logoutBE')
                    navigate("/login/dashboard", { replace: true })
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-ultah", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })

    // PROSES MENGAMBIL FILE FOTO DARI FORM
    function handleUploadChange(e){
        let uploaded = e.target.files[0]
        setFoto(uploaded)
    }
    
    // PROSES MENYIMPAN WE ULTAH KE DB
    const createWeUltah = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("nama", nama)
        formData.append("foto", foto)
        formData.append("tempatLahir", tempatLahir)
        formData.append("tanggalLahir", tanggalLahir)
        formData.append("ucapan", ucapan)
        formData.append("warna", warna)
        formData.append("panggilan", panggilan)
        try{
            setLoading(true);
            setProgress(0);
            let fakeProgress = 0;
            const fastLoading = setInterval(() => {
                fakeProgress += 10;
                setProgress(fakeProgress);
                if (fakeProgress >= 80) {
                    clearInterval(fastLoading);
                }
            }, 30);
            await axios.post("https://server13.webew.id/createWeUltahBE", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            },
            {
                onDownloadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    if (total) {
                        let percentCompleted = Math.round((loaded / total) * 100);
                        setProgress(Math.max(80, percentCompleted)); // Pastikan tidak turun dari 80%
                    } else {
                        // Jika total tidak tersedia, buat progres naik perlahan
                        setProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : prevProgress));
                    }
                }
            });
            // Redirect langsung ke halaman tujuan
            setProgress(100); // Selesaikan progress
            setTimeout(() => {
                setLoading(false);
                navigate(`/menu-we-ultah-salin-kode/${panggilan}`, { replace: true })
            }, 500);
        } catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    
    return (
        <div id="wrapper">

            {/* LOADER */}
                {loading && <LoadingPage progress={progress} />}
            {/* TUTUP LOADER */}
            
            {/* MODAL DONASI */}
               <Suspense fallback={<div></div>}>
                    <MenuDonasi/>
                </Suspense>
            {/* TUTUP MODAL DONASI */}

            {/* SIDEBAR */}
                <Suspense fallback={<div></div>}>
                    <MenuSidebar/>
                </Suspense>
            {/* TUTUP SIDEBAR */}

            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">

                    {/* NAVBAR */}
                        <Suspense fallback={<div></div>}>
                            <MenuNavbar/>
                        </Suspense>
                    {/* TUTUP NAVBAR */}

                    {/* KONTEN */}
                        <div  className="wrap-menu px-1">
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">

                                        <div className="col-12 pb-2 pt-4">
                                            <h4 className='text-center fw-bold'>We Ultah</h4>
                                            <p className='text-center fw-semibold'>Buat Ucapan Ulang Tahun Secara Online Dengan We Ultah</p>
                                        </div>

                                    </div>
                                    <div className="row justify-content-center mb-5 pb-4">

                                        <div className="col-12 col-lg-6 mb-4 order-1 order-md-0">
                                            <div className="box p-4" style={{width: "100%", height:"auto"}}>
                                                <form onSubmit={createWeUltah}>
                                                    <div className="row">

                                                        <div className="col-12">
                                                            <div className="row">

                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="nama">Nama Lengkap</label>
                                                                    <input type="text" id="nama" className="form-control form" title='Masukan nama yang ultah' value={nama} onChange={(e)=>setNama(e.target.value)} required/>
                                                                </div>

                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="foto">Foto (JPEG/PNG)</label>
                                                                    <input type="file" id="foto" className="form-control form" accept="image/*" title='Masukan foto max 2MB' onChange={handleUploadChange} required/>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <div className="row">

                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="tempatLahir">Tempat Lahir</label>
                                                                    <input type="text" id="tempatLahir" className="form-control form" title='Masukan tempat lahir' value={tempatLahir} onChange={(e)=>setTempatLahir(e.target.value)} required/>
                                                                </div>

                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="tanggalLahir">Tanggal Lahir</label>
                                                                    <input type="date" id="tanggalLahir" className="form-control form" title='Masukan tanggal lahir' value={tanggalLahir} onChange={(e)=>setTanggalLahir(e.target.value)} required/>
                                                                    <input type="text" id="panggilan" className="form-disable" value={panggilan} readOnly/>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <div className="row">

                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="ucapan">Ucapan Kata</label>
                                                                    <input type="text" id="ucapan" className="form-control form" title='Berikan ucapan kata untuk dia' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required/>
                                                                </div>

                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <label style={{fontSize:"10pt"}}  htmlFor="warna">Warna Latar</label>
                                                                    <input type="color" id="warna" className="form-control-color form-control form" title="Pilih warna latar" style={{width:"100%"}} value={warna} onChange={(e)=>setWarna(e.target.value)} required/>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <h6 style={{textAlign:"center",marginTop:"5px",color:"red"}}>{msg}</h6>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <input type="submit" className="btn btn-webew-product mt-2 py-4" value="Proses Bikin CV"/>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-4 mb-4 order-0 order-md-1">

                                            <div className="col-12">
                                                <div className="box p-4" style={{width: "100%",minHeight:"auto"}}>
                                                    <p className='text-center fw-normal'>Sudah pernah buat sebelumnya ? Jika sudah tekan tombol dibawah ini, jika belum silahkan isi form dulu yaa</p>
                                                    <div className="row justify-content-center">

                                                        <div className="col-12 col-sm-7">
                                                            <a href={`/menu-we-ultah-salin-kode/${panggilan}`} className="btn btn-webew-product my-2">Lanjut</a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* NAVBOT */}
                                <Suspense fallback={<div></div>}>
                                    <MenuNavbarBot/>
                                </Suspense>
                            {/* TUTUP NAVBOT */}

                        </div>
                    {/* TUTUP KONTEN */}

                </div>
            {/* TUTUP KONTEN WRAPPER */}

        </div>
    ) 
}

export default MenuWeUltah