// IMPORT REACT
import React,{ useState, useMemo, lazy, Suspense } from 'react'
import { useParams} from 'react-router-dom'

// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const NavbarBot = lazy(() => import('../../Layout/NavbarBot.jsx'));

const WeWebsiteContoh = () => {

    const { jenisWeWebsiteParams } = useParams();
    
    // PROSES LOOP TEMA WEBSITE
    const image = useMemo(() => {
        const imageMap = {
            WebPortoI: '/img/desainWebsite/ImgWebsiteG/PortoG1.webp',
            WebPortoII: '/img/desainWebsite/ImgWebsiteG/PortoG2.webp',
            WebPortoIII: '/img/desainWebsite/ImgWebsiteG/PortoG3.webp',
            WebPortoIV: '/img/desainWebsite/ImgWebsiteG/PortoG4.webp',
        };
        return imageMap[jenisWeWebsiteParams] || ''; // Default jika tidak cocok
    }, [jenisWeWebsiteParams]);
    
    return ( //BUKA KURUNG RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='weWebsite'>
                    <div className='container'>
                        <div className='row'>

                            <div className='col-12 col-lg-6 px-3'>
                                <div className="row">

                                    <div className="col-12">
                                        <div className="box p-4 mt-4">
                                            <a href={'/we-website'} className='btn btn-webew-product mb-2' aria-label='Kembali webew.id' style={{width:"auto"}}><i className="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                                            <h3 className='text-center fw-bold'>{jenisWeWebsiteParams}</h3>
                                            <p className='text-center fw-normal my-2'>Website portofolio yang memiliki 1 halaman dan sudah responsive di segala device dan juga terdapat Animate On Scroll.</p>
                                            <a href={'https://webew.id/login/menu-we-website'} className='btn btn-webew-product mt-2' aria-label='Produk webew.id'><i className='bi bi-download'></i> Download</a>
                                        </div>
                                    </div>
                                    
                                    <div className="col-12">
                                        <div className="box mt-4">
                                            <img loading="lazy" src="https://webew.id/img/banner.png" alt="banner webew.id" className='mx-auto' style={{width:"100%",maxHeight:"35.4vh",borderRadius:"20px",display: 'block' }}/>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='col-12 col-lg-6'>
                                <div className="box my-4"style={{maxHeight: "80vh",overflow: "auto",borderRadius: "30px",border:"30px solid white"}}>
                                    <img loading="lazy" src={image} className="img-fluid mx-auto" width="480px" alt="website" style={{borderRadius:"0px",display:'block' }}/>
                                </div>
                            </div>

                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}

            {/* NAVBAR BOTTOM */}
                <Suspense fallback={<div></div>}>
                    <NavbarBot />
                </Suspense>
            {/* TUTUP NAVBAR BOTTOM */}

        </div>
    )
}

export default WeWebsiteContoh