// IMPORT REACT
import React,{ useState, useEffect, useMemo, lazy, Suspense } from 'react'
import axios from "axios"
import jwt_decode from "jwt-decode"
import { useParams, useNavigate } from "react-router-dom"

// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuSidebar = lazy(() => import('../Layout/MenuSideBar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbar = lazy(() => import('../Layout/MenuNavbar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbarBot = lazy(() => import('../Layout/MenuNavbarBot.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuDonasi = lazy(() => import('../Layout/MenuDonasi.jsx'));

const MenuWeAqiqah = () => {

    const { panggilanParams } = useParams();
    const [panggilan, setPanggilan] = useState('')
    const [expire, setExpire] = useState('')
    const [, setToken] = useState('')
    const navigate = useNavigate()
    
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            if(panggilanParams !== decoded.panggilan){
                try {
                    await axios.delete('https://server13.webew.id/logoutBE')
                    navigate("/login/dashboard", { replace: true })
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-aqiqah", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })    

    // PROSES LOOP JENIS AQIQAH CARD BODY
    const jenisWeAqiqah = useMemo(() => [
        { title: 'White I', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/whiteI.webp', viewLink: '/we-aqiqah/whiteI', createLink: `/menu-we-aqiqah-form/${panggilan}/whiteI` },
        { title: 'White II', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/whiteII.webp', viewLink: '/we-aqiqah/whiteII', createLink: `/menu-we-aqiqah-form/${panggilan}/whiteII` },
        { title: 'White III', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/whiteIII.webp', viewLink: '/we-aqiqah/whiteIII', createLink: `/menu-we-aqiqah-form/${panggilan}/whiteIII` },
        { title: 'White IV', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/whiteIV.webp', viewLink: '/we-aqiqah/whiteIV', createLink: `/menu-we-aqiqah-form/${panggilan}/whiteIV` },
        { title: 'Flower I', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flowerI.webp', viewLink: '/we-aqiqah/flowerI', createLink: `/menu-we-aqiqah-form/${panggilan}/flowerI` },
        { title: 'Flower II', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flowerII.webp', viewLink: '/we-aqiqah/flowerII', createLink: `/menu-we-aqiqah-form/${panggilan}/flowerII` },
        { title: 'Flower III', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flowerIII.webp', viewLink: '/we-aqiqah/flowerIII', createLink: `/menu-we-aqiqah-form/${panggilan}/flowerIII` },
        { title: 'Flower IV', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flowerIV.webp', viewLink: '/we-aqiqah/flowerIV', createLink: `/menu-we-aqiqah-form/${panggilan}/flowerIV` },
        { title: 'Color I', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/colorI.webp', viewLink: '/we-aqiqah/colorI', createLink: `/menu-we-aqiqah-form/${panggilan}/colorI` },
        { title: 'Color II', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/colorII.webp', viewLink: '/we-aqiqah/colorII', createLink: `/menu-we-aqiqah-form/${panggilan}/colorII` },
        { title: 'Color III', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/colorIII.webp', viewLink: '/we-aqiqah/colorIII', createLink: `/menu-we-aqiqah-form/${panggilan}/colorIII` },
        { title: 'Color IV', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/colorIV.webp', viewLink: '/we-aqiqah/colorIV', createLink: `/menu-we-aqiqah-form/${panggilan}/colorIV` },
    ], [panggilan]);

    return ( 
        <div id="wrapper">

           {/* MODAL DONASI */}
                <Suspense fallback={<div></div>}>
                    <MenuDonasi/>
                </Suspense>
            {/* TUTUP MODAL DONASI */}

            {/* SIDEBAR */}
                <Suspense fallback={<div></div>}>
                    <MenuSidebar/>
                </Suspense>
            {/* TUTUP SIDEBAR */}

            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">

                    {/* NAVBAR */}
                        <Suspense fallback={<div></div>}>
                            <MenuNavbar/>
                        </Suspense>
                    {/* TUTUP NAVBAR */}

                    {/* KONTEN */}
                        <div className="wrap-menu px-1">
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">

                                        <div className="col-12 pb-2 pt-4">
                                            <h4 className='text-center' style={{fontWeight:'700'}}>We Aqiqah</h4>
                                            <p className='text-center fw-semibold'>Buat Undangan Aqiqah Digital Dengan We Aqiqah</p>
                                        </div>

                                    </div>
                                    <div className="row mb-5 pb-4">
                                        {jenisWeAqiqah.map((data, index) => (

                                            <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3'id="aqiqah">
                                                <div className='card mb-4 pt-3 pb-0 px-2' style={{ width: '100%' }}>
                                                    <h5 className='card-title text-center mt-2 fw-bold'>{data.title}</h5>
                                                    <img loading="lazy" src={data.imgSrc} className='mx-auto img-fluid mt-1' style={{ maxHeight: '450px',borderRadius:"30px",border:"6px solid black",display: 'block' }} alt={data.title} />
                                                    <div className='card-body'>
                                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <a href={data.viewLink} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                                            </div>
                                                            <div className='col-6'>
                                                                <a href={data.createLink} className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* NAVBOT */}
                                <Suspense fallback={<div></div>}>
                                    <MenuNavbarBot/>
                                </Suspense>
                            {/* TUTUP NAVBOT */}

                        </div>
                    {/* TUTUP KONTEN */}

                </div>
            {/* TUTUP KONTEN WRAPPER */}

        </div>
    )
}

export default MenuWeAqiqah