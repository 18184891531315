// IMPORT REACT
import React,{ useRef, useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion";
import { useParams } from "react-router-dom"

const WePernikahanContoh = () => { 
    
    const [dataWePernikahan, setDataWePernikahan] = useState({})

    const [showPage, setShowPage] = useState(true);
    const [showMainPage, setShowMainPage] = useState(false);
    const [bukaUndangan, setBukaUndangan] = useState('none')
    const [isPlaying, setIsPlaying] = useState(false);
    const { jenisWePernikahanParams } = useParams();
    const audioRef = useRef(null);
    const elementsRef = useRef([]);

    useEffect(() => {
        if (jenisWePernikahanParams) {
            const data = {
                agapanthus: {
                    pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_agapanthus.png",
                    btnColorCD: "#0081b0",
                    btnColor: "#f3fbff",
                    fntColor: "rgb(108, 108, 108)",
                    borderBtn: "3px solid #0081b0",
                    bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#f8ffff2f",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg"
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#4fd0ff15",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri...",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5 (Gallery)
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6 (Bank)
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        { url: "https://www.freepik.com/free-vector/summer-pattern-background-zoom_8845952.htm", pemilik: "pikisuperstar" },
                        { url: "https://www.freepik.com/free-vector/gradient-tropical-leaves-background_14309031.htm", pemilik: "Freepik" },
                        { url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm", pemilik: "Freepik" },
                        { url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB", pemilik: "The Way You Look Me - Christian Bautista" }
                    ],
                    rotate: "rotate(-180deg)"
                },
                aglaonema: {
                    pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_aglaonema.png",
                    btnColorCD: "green",
                    btnColor: "#f4fff3",
                    fntColor: "rgb(108, 108, 108)",
                    borderBtn: "3px solid green",
                    bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#fafff82f",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg"
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#5eff4f15",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri...",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5 (Gallery)
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6 (Bank)
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        { url: "https://www.freepik.com/free-vector/hand-painted-watercolor-tropical-leaves-background_13900114.htm#query=tropical%20background&position=3&from_view=search&track=ais", pemilik: "pikisuperstar" },
                        { url: "https://www.freepik.com/free-vector/realistic-tropical-leaves-background_13991173.htm#page=2&query=tropical&position=3&from_view=search&track=sph", pemilik: "pikisuperstar" },
                        { url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm", pemilik: "Freepik" },
                        { url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB", pemilik: "The Way You Look Me - Christian Bautista" }
                    ],
                    rotate: "rotate(-180deg)"
                },
                alamanda: {
                    pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_alamanda.png",
                    btnColorCD: "#b05500",
                    btnColor: "#fffaf3",
                    fntColor: "rgb: 108, 108, 108,",
                    borderBtn: "3px solid #b05500",
                    bgCover: "url: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp',",
                    bgImageTop: "url: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg',",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png",
                    imagePenganti: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#ffa44f15",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda : kebesaran,-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda : kebesaran Allah, bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://www.freepik.com/free-vector/realistic-autumn-background_9260611.htm#page=6&query=yellow%20leaf&position=16&from_view=search&track=ais",pemilik: "pikisuperstar"},
                        {url: "https://www.freepik.com/free-vector/flat-autumn-forest-leaves-background_5113181.htm#page=3&query=yellow%20leaf%20background&position=20&from_view=search&track=ais",pemilik: "freepik"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"}
                    ],
                    rotate: "rotate(-180deg)"
                },
                amaryllis: {
                    pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_amaryllis.png",
                    btnColorCD: "#b00000",
                    btnColor: "#fff3f3",
                    fntColor: "rgb(108, 108, 108)",
                    borderBtn: "3px solid #b00000",
                    bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#fff8f82f",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#ff4f4f15",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5045257.htm#page=3&query=read%20leaf%20background&position=14&from_view=search&track=ais",pemilik: "freepik"},
                        {url: "https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5020958.htm#page=7&query=red%20leaf%20background&position=34&from_view=search&track=ais",pemilik: "freepik"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"}
                    ],
                    rotate: "rotate(-180deg)",
                },
                adorable: {
                    btnColorCD: "#00b07e",
                    btnColor: "#f3fff8",
                    fntColor: "rgb(0, 58, 41)",
                    borderBtn: "3px solid #00b07e",
                    bgCover: "linear-gradient(to bottom,rgb(221, 255, 237),rgb(221, 255, 237)",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#f8fffd2f",
                    // Box 1
                    bgImageBox1: "",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#4fffc115",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotate: "rotate(-180deg)",
                },
                cute: {
                    btnColorCD: "rgb(176, 0, 135)",
                    btnColor: "#fff3fe",
                    fntColor: "rgb(61, 0, 47)",
                    borderBtn: "3px solid rgb(176, 0, 135)",
                    bgCover: "linear-gradient(to bottom, #f1c9fe,rgb(245, 214, 255)",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#fff8fc2f",
                    // Box 1
                    bgImageBox1: "",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#ff4fb915",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotate: "rotate(-180deg)",
                },
                beauty: {
                    btnColorCD: "rgb(176, 59, 0)",
                    btnColor: "#fff7f3",
                    fntColor: "rgb(68, 26, 0)",
                    borderBtn: "3px solid rgb(176, 59, 0)",
                    bgCover: "linear-gradient(to bottom, #fed5c9,rgb(255, 225, 215)",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#fffbf82f",
                    // Box 1
                    bgImageBox1: "",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#ffa44f15",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotate: "rotate(-180deg)",
                },
                cool: {
                    btnColorCD: "rgb(0, 147, 176)",
                    btnColor: "#f3fcff",
                    fntColor: "rgb(0, 46, 55)",
                    borderBtn: "3px solid rgb(0, 147, 176)",
                    bgCover: "linear-gradient(to bottom, rgb(212, 240, 255),rgb(212, 240, 255)",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#f8ffff2f",
                    // Box 1
                    bgImageBox1: "",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#4fdcff15",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotate: "rotate(-180deg)",
                },
                blegold: {
                    btnColorCD: "rgb(176, 135, 0)",
                    btnColor: "#392f00",
                    fntColor: "rgb(255, 220, 80)",
                    borderBtn: "3px solid rgb(176, 135, 0)",
                    borderBoxLeft: "3px solid rgb(255, 204, 0)",
                    borderBoxRight: "3px solid rgb(255, 204, 0)",
                    bgCover: "linear-gradient(to bottom,rgb(70, 61, 0),rgb(0, 0, 0)",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#fffef82f",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2G.png",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#ffed4f15",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5G.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph",pemilik: "macrovector"},
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotate: "rotate(-180deg)",
                },
                blepink: {
                    btnColorCD: "rgb(255, 44, 209)",
                    btnColor: "rgb(67, 0, 62)",
                    fntColor: "rgb(255, 169, 249)",
                    borderBtn: "3px solid rgb(255, 190, 237)",
                    borderBoxLeft: "3px solid rgb(255, 190, 237)",
                    borderBoxRight: "3px solid rgb(255, 190, 237)",
                    bgCover: "linear-gradient(to bottom,rgb(74, 0, 77),rgb(0, 0, 0)",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#fff8fd2f",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2P.png",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#ff4fd015",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5P.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph",pemilik: "macrovector"},
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotate: "rotate(-180deg)",
                },
                bletosca: {
                    btnColorCD: "rgb(0, 130, 133)",
                    btnColor: "#004748",
                    fntColor: "rgb(185, 254, 255)",
                    borderBtn: "3px solid rgb(0, 251, 255)",
                    borderBoxLeft: "3px solid rgb(0, 247, 255)",
                    borderBoxRight: "3px solid rgb(0, 247, 255)",
                    bgCover: "linear-gradient(to bottom,rgb(0, 62, 64),rgb(0, 0, 0)",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#f8ffff2f",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2T.png",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#4ffcff15",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5T.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph",pemilik: "macrovector"},
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotate: "rotate(-180deg)",
                },
                blewhite: {
                    btnColorCD: "rgb(0, 0, 0)",
                    btnColor: "rgb(73, 73, 73)",
                    fntColor: "rgb(255, 255, 255)",
                    borderBtn: "3px solid rgb(255, 255, 255)",
                    borderBoxLeft: "3px solid rgb(255, 255, 255)",
                    borderBoxRight: "3px solid rgb(255, 255, 255)",
                    bgCover: "linear-gradient(to bottom,rgb(76, 76, 76),rgb(0, 0, 0)",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#ffffff2f",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2W.png",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#ffffff15",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5W.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph",pemilik: "macrovector"},
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotate: "rotate(-180deg)",
                },
                bnwI: {
                    pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png",
                    btnColorCD: "rgb(108, 108, 108)",
                    btnColor: "white",
                    fntColor: "rgb(108, 108, 108)",
                    borderBtn: "3px solid rgb(108, 108, 108)",
                    bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#e8e8e82f",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb1.png",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#43434315",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb2.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-vector/elegant-wedding-decorative-floral-background_9853423.htm#query=black%20and%20white%20flower%20background&position=10&from_view=search&track=ais",pemilik: "Harryarts"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotate: "rotate(-180deg)",
                },
                bnwII: {
                    pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png",
                    btnColorCD: "rgb(108, 108, 108)",
                    btnColor: "white",
                    fntColor: "rgb(108, 108, 108)",
                    borderBtn: "3px solid rgb(108, 108, 108)",
                    bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#e8e8e82f",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb5.png",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#43434315",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb4.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-vector/beautiful-hand-drawn-wedding-ornament-floral-background_9853397.htm#page=3&query=black%20and%20white%20flower%20background&position=3&from_view=search&track=ais",pemilik: "Harryarts"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotat: "rotate(-180deg)",
                },
                bnwIII: {
                    pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png",
                    btnColorCD: "rgb(108, 108, 108)",
                    btnColor: "white",
                    fntColor: "rgb(108, 108, 108)",
                    borderBtn: "3px solid rgb(108, 108, 108)",
                    bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#e8e8e82f",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb8.png",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#43434315",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-vector/hand-drawn-spring-background_12806202.htm#query=black%20and%20white%20flower%20background&position=38&from_view=search&track=ais",pemilik: "Freepik"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotate: "rotate(-180deg)",
                },
                bnwIV: {
                    pajangan: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png",
                    btnColorCD: "rgb(108, 108, 108)",
                    btnColor: "white",
                    fntColor: "rgb(108, 108, 108)",
                    borderBtn: "3px solid rgb(108, 108, 108)",
                    bgCover: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')",
                    bgImageCover: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg",
                    bgImageTop: "url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')",
                    bgColor: "#e8e8e82f",
                    // Box 1
                    bgImageBox1: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb10.png",
                    imagePengantin: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                    ],
                    namaPengantin: ["Romeo Romeano", "Juliet Julieto"],
                    namaInstagramPengantin: ["romeo_", "juliet12"],
                    urutanPengantin: ["pertama", "kedua"],
                    namaOrangTuaPengantin: {
                        bapak: ["Abdul Aziz", "Aziz Abdul"],
                        ibu: ["Nuraini", "Aininuroh"]
                    },
                    // Box 2
                    bgColorBox2: "#43434315",
                    akad: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "08.00",
                        jamAkhir: "11.00"
                    },
                    resepsi: {
                        hari: "Sabtu",
                        tanggal: "13 Mei 2022",
                        jamMulai: "11.00",
                        jamAkhir: "17.00"
                    },
                    zonaWaktu: "WIB",
                    bgImageBox2: "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb11.png",
                    // Box 3
                    lokasi: {
                        akad: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        },
                        resepsi: {
                            alamat: "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia",
                            maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid"
                        }
                    },
                    // Box 4
                    ayat: "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”",
                    surah: "QS. Ar-Rum ayat 21",
                    // Box 5
                    imageGallery: [
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg",
                        "https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"
                    ],
                    // Box 6
                    bank: [
                        { nama: "Mandiri", pemilik: "Romeo", norek: "12345679292929292" },
                        { nama: "BCA", pemilik: "Juliet", norek: "12345679292929292" }
                    ],
                    // Link Foto & Lagu
                    links: [
                        {url: "https://webew.id",pemilik: "Webew.id"},
                        {url: "https://www.freepik.com/free-vector/floral-background-with-sketchy-style_2416402.htm#page=5&query=black%20and%20white%20flower%20background&position=13&from_view=search&track=ais",pemilik: "Freepik"},
                        {url: "https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm",pemilik: "Freepik"},
                        {url: "https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB",pemilik: "The Way You Look Me - Christian Bautista"},
                    ],
                    rotate: "rotate(-180deg)",
                },
            };
            setDataWePernikahan(data[jenisWePernikahanParams] || {});
        }
    }, [jenisWePernikahanParams]);
    
    // ANIMASI SCROLL
    useEffect(() => {
        if (!Array.isArray(elementsRef.current[0])) {
            elementsRef.current[0] = [];
        }
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        animateElement(entry.target);
                    }
                });
            },
            {
                threshold: 0,
                rootMargin: "0px 0px 0px 0px",
            }
        );
        elementsRef.current[0].forEach((el) => observer.observe(el));
        return () => observer.disconnect();
    }, []); // Efek ini hanya dipanggil sekali saat komponen pertama kali dirender
    const animateElement = (element) => {
        let start = null;
        const duration = 1200; // Durasi animasi dalam milidetik
        const initialScale = 0.5; // Ukuran awal (kecil)
        const targetScale = 1; // Ukuran akhir (normal)
        const initialOpacity = 0; // Transparan awal
        const targetOpacity = 1; // Transparan akhir
        const step = (timestamp) => {
          if (!start) start = timestamp;
          const progress = Math.min((timestamp - start) / duration, 1); // Hitung progres animasi (0 ke 1)
          // Lerp (Linear Interpolation) untuk scale dan opacity
          const currentScale = initialScale + (targetScale - initialScale) * progress;
          const currentOpacity = initialOpacity + (targetOpacity - initialOpacity) * progress;
          // Atur gaya elemen
          element.style.transform = `scale(${currentScale})`;
          element.style.opacity = currentOpacity;
          if (progress < 1) {
            requestAnimationFrame(step);
          }
        };
        requestAnimationFrame(step);
    };

    // BUTTON BUKA UNDANGAN
    const handleBukaUndangan = () => {
        audioRef.current.play()
        setIsPlaying(true)
        setShowPage(false);
        setShowMainPage(true);
        setBukaUndangan("");
    }

    // BUTTON PLAY MUSIC
    const handlePlay = () => {
        audioRef.current.play()
        setIsPlaying(true)
    }

    // BUTTON PAUSE MUSIC
    const handlePause = () => {
        audioRef.current.pause()
        setIsPlaying(false)
    }

    // BUTTON PLAY PAUSE MUSIC
    const handlePlayPause = () => {
        if (isPlaying) {
            handlePause()
        } else {
            handlePlay()
        }
    }

    // CLASSNAME PAJANGAN 
    const kupuKupu = [
        "moving-image-1",
        "moving-image-2",
        "moving-image-3",
        "moving-image-4",
        "moving-image-5",
    ];
    const daun = [
        "moving-image-6",
        "moving-image-7",
        "moving-image-8",
        "moving-image-9",
        "moving-image-10",
    ];

    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className="background-home">

             <section>

                {/* COVER UNDANGAN */}
                <AnimatePresence>
                   {showPage && (
                        <div style={{position:"fixed",minHeight:"100vh",width:"100%",background: `linear-gradient(to top, ${dataWePernikahan?.btnColorCD} -100%, ${dataWePernikahan?.bgColorBox2}) 100%`,zIndex:"1",overflow:"auto"}}>
                            <div className="container-fluid">
                                <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight:"100vh",width:"100%",zIndex:"2"}}>
                                    <motion.div className="px-4 py-4" style={{backgroundImage:[dataWePernikahan?.bgCover],height:"auto",width:"auto",borderRadius:"20px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}initial={{ scale: 0.1 }}animate={{ scale: 1 }}exit={{ opacity: 0, y:-500 }}transition={{ duration: 1 }}>
                                        <img loading="lazy" src={dataWePernikahan?.bgImageCover} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:`0px 10px 25px -14px ${dataWePernikahan?.btnColorCD}`}} alt="pengantin" />
                                        <h5 className='fw-bold bad-script-regular text-center' style={{marginTop:"30px",color:[dataWePernikahan?.fntColor]}}>Kepada Yth. Bpk/Ibu/Saudara/i</h5>
                                        <p className='fw-normal courgette-regular m-0 text-center' style={{color:[dataWePernikahan?.fntColor],fontSize:"40pt"}}>Fiscal</p>
                                        <button onClick={handleBukaUndangan} className="form form-control fw-bold bad-script-regular" style={{backgroundColor:[dataWePernikahan?.btnColor],padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.btnColorCD}`,color:[dataWePernikahan?.fntColor],border:[dataWePernikahan?.borderBtn],fontSize:"14pt"}}><i className="bi bi-envelope-paper-heart-fill"></i> Buka Undangan <i className="bi bi-envelope-paper-heart-fill"></i></button>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    )}
                </AnimatePresence>

                {/* AUDIO */}
                <div className="fixed-bottom" style={{display:bukaUndangan}}>
                    <audio ref={audioRef} src="/song/The Way You Look Me - Christian Bautista.mp3" autoPlay loop preload='none'/>
                    <button onClick={handlePlayPause} style={{width:"100%",height:"auto",border:"none",borderTopLeftRadius:"30px",borderTopRightRadius:"30px",background: `linear-gradient(-45deg, ${dataWePernikahan?.btnColorCD} -200%, ${dataWePernikahan?.btnColor} 70%)`,borderTop:[dataWePernikahan?.borderBtn]}}>
                        {isPlaying ? <p style={{color:[dataWePernikahan?.fntColor],fontWeight:"600",margin:"0",padding:"0",fontSize:"10pt"}}><i className="bi bi-pause-circle" style={{fontSize:"18px"}}></i> {dataWePernikahan?.links?.[3]?.pemilik} </p>:<p style={{color:[dataWePernikahan?.fntColor],fontWeight:"600",margin:"0",padding:"0",fontSize:"10pt"}}><i className="bi bi-play-circle" style={{fontSize:"18px"}}></i> {dataWePernikahan?.links?.[3]?.pemilik}</p>}                                           
                    </button>
                </div>

                {/* MAIN PAGE */}
                <section style={{overflow:"auto",height:"100vh"}}>

                    {/* COVER */}
                    <section style={{backgroundImage:`${dataWePernikahan?.bgImageTop}`,width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{background: `linear-gradient(to top, ${dataWePernikahan?.btnColorCD} -100%, ${dataWePernikahan?.bgColor}) 100%`,width:"100%",height:"100vh",display:[bukaUndangan]}}>
                            <AnimatePresence>
                                {showMainPage && (
                                    <motion.div initial={{ y:500 }}animate={{ y:0 }}transition={{ duration: 1 }}>
                                        <div className="marginTopWePernikahan" style={{width:"100%",marginBottom:"18px",textAlign:"center",textShadow:`0 0 20px ${dataWePernikahan?.btnColorCD}`}}>
                                            <p className='fw-bold m-0 courgette-regular' style={{color:"white",fontSize:"40pt"}}>Romeo & Juliet</p>
                                            <h2 className='fw-bold bad-script-regular' style={{color:"white",fontVariant:"small-caps", marginTop:"-10px"}}>Sabtu, 13 Januari 2022</h2>
                                        </div>
                                        <div className='px-1' style={{width: "100%", marginBottom:"18px" }}>
                                            <table style={{ textAlign: "center", width: "100%" }}>
                                                <tbody>
                                                    <tr style={{ textAlign: "center", width: "100%" }}>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.btnColorCD} -200%, ${dataWePernikahan?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Hari</div>
                                                        </td>
                                                        <td style={{ width: "2%" }}></td>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.btnColorCD} -200%, ${dataWePernikahan?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Jam</div>
                                                        </td>
                                                        <td style={{ width: "2%" }}></td>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.btnColorCD} -200%, ${dataWePernikahan?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Menit</div>
                                                        </td>
                                                        <td style={{ width: "2%" }}></td>
                                                        <td className="p-3"style={{color: [dataWePernikahan?.fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${dataWePernikahan?.btnColorCD} -200%, ${dataWePernikahan?.btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${dataWePernikahan?.btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                            <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Detik</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </section>
                    
                    <section style={{display:bukaUndangan}}>

                        {/* BOX 1 */}
                        <section style={{backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img loading="lazy" src={dataWePernikahan?.bgImageBox1} className='w-100' style={{transform:`${dataWePernikahan?.rotate}`}} />
                            <p className='text-center fw-bold m-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],fontFamily:"initial",fontSize:"23pt"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</p>
                            <h6 className='text-center fw-normal m-0 mb-2 bad-script-regular'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor]}}>Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tbody>
                                    <tr>
                                        <td ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"45%"}}><img loading="lazy" src={dataWePernikahan?.imagePengantin?.[0]} className='img-fluid'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",minHeight:"210px",maxHeight:"210px",borderRadius:"30px",boxShadow:`10px 10px 25px -18px ${dataWePernikahan?.btnColorCD}`,borderLeft:[dataWePernikahan?.borderBoxLeft]}} alt="calon pengantin pria" /></td>
                                        <td ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"5%"}}><div className="text-center bi bi-suit-heart-fill"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"25px",color:[dataWePernikahan?.fntColor]}}></div></td>
                                        <td ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"45%"}}><img loading="lazy" src={dataWePernikahan?.imagePengantin?.[1]} className='img-fluid'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",minHeight:"210px",maxHeight:"210px",borderRadius:"30px",boxShadow:`-10px 10px 25px -18px ${dataWePernikahan?.btnColorCD}`,borderRight:[dataWePernikahan?.borderBoxRight]}} alt="calon pengantin Wanita" /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-3">
                                    <div className="col-12 col-md-5">
                                        <p className='text-center fw-bold courgette-regular m-0'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],fontSize:"30pt"}}>{dataWePernikahan?.namaPengantin?.[0]}</p>
                                        <h6 className='text-center fw-normal bad-script-regular opacity-50'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],marginBottom:"0"}}><i className="bi bi-instagram"style={{color:[dataWePernikahan?.fntColor],fontSize:"9pt"}}></i> {dataWePernikahan?.namaInstagramPengantin?.[0]}</h6>
                                        <h6 className='text-center fw-normal bad-script-regular'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor]}}>Putra {dataWePernikahan?.urutanPengantin?.[0]} dari<br/>Bapak {dataWePernikahan?.namaOrangTuaPengantin?.bapak?.[0]} & Ibu {dataWePernikahan?.namaOrangTuaPengantin?.ibu?.[0]}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center fw-normal courgette-regular mt-2"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"25pt",color:[dataWePernikahan?.fntColor]}}>&</div></div>
                                    <div className="col-12 col-md-5">
                                        <p className='text-center fw-bold courgette-regular m-0'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],fontSize:"30pt"}}>{dataWePernikahan?.namaPengantin?.[1]}</p>
                                        <h6 className='text-center fw-normal bad-script-regular opacity-50'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],marginBottom:"0"}}><i className="bi bi-instagram"style={{color:[dataWePernikahan?.fntColor],fontSize:"9pt"}}></i> {dataWePernikahan?.namaInstagramPengantin?.[1]} </h6>
                                        <h6 className='text-center fw-normal bad-script-regular'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor]}}>Putri {dataWePernikahan?.urutanPengantin?.[1]} dari<br/>Bapak {dataWePernikahan?.namaOrangTuaPengantin?.bapak?.[1]} & Ibu {dataWePernikahan?.namaOrangTuaPengantin?.ibu?.[1]}</h6>
                                    </div>
                                </div>
                            </div>
                            <img loading="lazy" src={dataWePernikahan?.bgImageBox1} className='w-100'/>
                        </section>

                        {/* BOX 2 */}
                        <section style={{backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${dataWePernikahan?.bgColorBox2}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                                <div className="px-3 pt-3">
                                                    <div className='bi bi-calendar2-event text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Akad Nikah</p>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor]}}>{dataWePernikahan?.akad?.hari}</h6>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[dataWePernikahan?.fntColor]}}>{dataWePernikahan?.akad?.tanggal}</h6>
                                                    <h6 className='text-center fw-normal pb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[dataWePernikahan?.fntColor]}}>{dataWePernikahan?.akad?.jamMulai} sd {dataWePernikahan?.akad?.jamAkhir} {dataWePernikahan?.zonaWaktu}</h6>
                                                    <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                        <button className="form form-control fw-bold mb-4 mt-3 px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[dataWePernikahan?.btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.btnColorCD}`,color:[dataWePernikahan?.fntColor],border:[dataWePernikahan?.borderBtn],fontSize:"12pt"}}><i className="bi bi-calendar-heart"></i> Simpan Kalender</button>
                                                    </a>
                                                </div>
                                                <img loading="lazy" src={dataWePernikahan?.bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                                <div className="33 pt-3">
                                                    <div className='bi bi-calendar2-event text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Resepsi Nikah</p>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor]}}>{dataWePernikahan?.resepsi?.hari}</h6>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[dataWePernikahan?.fntColor]}}>{dataWePernikahan?.resepsi?.tanggal}</h6>
                                                    <h6 className='text-center fw-normal pb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[dataWePernikahan?.fntColor]}}>{dataWePernikahan?.resepsi?.jamMulai} sd {dataWePernikahan?.resepsi?.jamAkhir} {dataWePernikahan?.zonaWaktu}</h6>
                                                    <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                        <button className="form form-control fw-bold mb-4 mt-3 px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[dataWePernikahan?.btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.btnColorCD}`,color:[dataWePernikahan?.fntColor],border:[dataWePernikahan?.borderBtn],fontSize:"12pt"}}><i className="bi bi-calendar-heart"></i> Simpan Kalender</button>
                                                    </a>
                                                </div>
                                                <img loading="lazy" src={dataWePernikahan?.bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* BOX 3 */}
                        <section style={{backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img loading="lazy" src={dataWePernikahan?.bgImageBox1} className='w-100' style={{transform:`${dataWePernikahan?.rotate}`}} />
                            <div className='bi bi-map text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.fntColor]}}></div>
                            <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Alamat Nikah</p>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4 mb-3">
                                        <h1 className='text-center fw-normal courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor]}}>Akad Nikah</h1>
                                        <h6 className='text-center fw-normal mb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor]}}>{dataWePernikahan?.lokasi?.akad?.alamat}</h6>
                                        <a href={dataWePernikahan?.lokasi?.akad?.maps} aria-label="Google Maps">
                                            <button className="form form-control fw-bold px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[dataWePernikahan?.btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.btnColorCD}`,color:[dataWePernikahan?.fntColor],border:[dataWePernikahan?.borderBtn],fontSize:"12pt"}}><i className="bi bi-geo-alt"></i> Lihat Maps</button>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4 mb-3">
                                        <h1 className='text-center fw-normal courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor]}}>Resepsi Nikah</h1>
                                        <h6 className='text-center fw-normal mb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor]}}>{dataWePernikahan?.lokasi?.resepsi?.alamat}</h6>
                                        <a href={dataWePernikahan?.lokasi?.resepsi?.maps} aria-label="Google Maps">
                                            <button className="form form-control fw-bold px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[dataWePernikahan?.btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.btnColorCD}`,color:[dataWePernikahan?.fntColor],border:[dataWePernikahan?.borderBtn],fontSize:"12pt"}}><i className="bi bi-geo-alt"></i> Lihat Maps</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img loading="lazy" src={dataWePernikahan?.bgImageBox1} className='w-100'/>
                        </section>

                        {/* BOX 4 */}
                        <section style={{backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${dataWePernikahan?.bgColorBox2}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                                <div className="p-3">
                                                    <h6 className='text-center fw-normal mt-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor]}}>{dataWePernikahan?.ayat}</h6>
                                                    <h2 className='text-center fw-bold pt-1 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor]}}>({dataWePernikahan?.surah})</h2>
                                                </div>
                                                <img loading="lazy" src={dataWePernikahan?.bgImageBox2} className='w-100' style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* BOX 5 */}
                        <section style={{backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img loading="lazy" src={dataWePernikahan?.bgImageBox1} className='w-100' style={{transform:`${dataWePernikahan?.rotate}`}} />
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <div className='bi bi-images text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.fntColor]}}></div>
                                        <p className='text-center fw-bold pt-0 mb-4 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Galeri Foto</p>
                                        <div className="container-fluid">
                                            <div className='rowes justify-content-center'>
                                                <div className="column">
                                                    <a href={dataWePernikahan?.imageGallery?.[0]} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery?.[0]} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`-10px 10px 25px -18px ${dataWePernikahan?.btnColorCD}`}}/></a>
                                                    <a href={dataWePernikahan?.imageGallery?.[1]} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery?.[1]} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`-10px 10px 25px -18px ${dataWePernikahan?.btnColorCD}`}}/></a>
                                                    <a href={dataWePernikahan?.imageGallery?.[2]} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery?.[2]} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`-10px 10px 25px -18px ${dataWePernikahan?.btnColorCD}`}}/></a>
                                                </div>
                                                <div className="column">
                                                    <a href={dataWePernikahan?.imageGallery?.[3]} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery?.[3]} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`10px 10px 25px -18px ${dataWePernikahan?.btnColorCD}`}}/></a>
                                                    <a href={dataWePernikahan?.imageGallery?.[4]} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery?.[4]} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`10px 10px 25px -18px ${dataWePernikahan?.btnColorCD}`}}/></a>
                                                    <a href={dataWePernikahan?.imageGallery?.[5]} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img loading="lazy" src={dataWePernikahan?.imageGallery?.[5]} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight],boxShadow:`10px 10px 25px -18px ${dataWePernikahan?.btnColorCD}`}}/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img loading="lazy" src={dataWePernikahan?.bgImageBox1} className='w-100' />
                        </section>

                        {/* BOX 6 */}
                        <section style={{backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${dataWePernikahan?.bgColorBox2}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center py-4 px-2">
                                        <div className="col-12 col-md-10 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                                <div className="p-3">
                                                    <div className='bi bi-credit-card text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 pb-3 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Kado Pernikahan</p>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-5 mb-4">
                                                            <div>
                                                                <div className="box p-4" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",background: `linear-gradient(-45deg, ${dataWePernikahan?.btnColorCD} -200%, ${dataWePernikahan?.btnColor} 70%)`,boxShadow:`10px 10px 25px -17px ${dataWePernikahan?.btnColorCD}`}}>
                                                                    <p className='fw-bold bad-script-regular' style={{color:[dataWePernikahan?.fntColor],textAlign:"right",fontSize:"15pt",fontStyle:"italic"}}><i className="bi bi-bank"></i> Bank {dataWePernikahan?.bank?.[0]?.nama}</p>
                                                                    <div className="row mb-4">
                                                                        <div className="col-2"><i className="bi bi-cpu" style={{color:[dataWePernikahan?.fntColor],fontSize:"40px"}}></i></div>
                                                                        <div className="col-10"><p className='fw-bold mt-3 px-1 bad-script-regular' style={{color:[dataWePernikahan?.fntColor],fontSize:"12pt"}}>An. {dataWePernikahan?.bank?.[0]?.pemilik}</p></div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <input type="text" id='noRek1' className="fw-bold bad-script-regular" style={{backgroundColor:"transparent",border:"none",color:[dataWePernikahan?.fntColor],fontSize:"14pt"}} value={dataWePernikahan?.bank?.[0]?.norek}/>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <button type="button" className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[dataWePernikahan?.fntColor],fontSize:"12pt"}}><i className="bi bi-copy fw-bold"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-5 mb-4">
                                                            <div>
                                                                <div className="box p-4" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",background: `linear-gradient(-45deg, ${dataWePernikahan?.btnColorCD} -200%, ${dataWePernikahan?.btnColor} 70%)`,boxShadow:`10px 10px 25px -17px ${dataWePernikahan?.btnColorCD}`}}>
                                                                    <p className='fw-bold bad-script-regular' style={{color:[dataWePernikahan?.fntColor],textAlign:"right",fontSize:"15pt",fontStyle:"italic"}}><i className="bi bi-bank"></i> Bank {dataWePernikahan?.bank?.[1]?.nama}</p>
                                                                    <div className="row mb-4">
                                                                        <div className="col-2"><i className="bi bi-cpu" style={{color:[dataWePernikahan?.fntColor],fontSize:"40px"}}></i></div>
                                                                        <div className="col-10"><p className='fw-bold mt-3 px-1 bad-script-regular' style={{color:[dataWePernikahan?.fntColor]}}>An. {dataWePernikahan?.bank?.[1]?.pemilik}</p></div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <input type="text" id='noRek1' className="fw-bold bad-script-regular" style={{backgroundColor:"transparent",border:"none",color:[dataWePernikahan?.fntColor],fontSize:"14pt"}} value={dataWePernikahan?.bank?.[1]?.norek}/>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <button type="button" className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[dataWePernikahan?.fntColor]}}><i className="bi bi-copy fw-bold"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                                <img loading="lazy" src={dataWePernikahan?.bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                                <div className="p-3">
                                                    <div className='bi bi-chat-square-text text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Beri Ucapan</p>
                                                    <form ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)"}}>
                                                        <label className='mx-2 fw-normal bad-script-regular' htmlFor="nama" style={{color:[dataWePernikahan?.fntColor],fontSize:"13pt"}}>Nama</label>
                                                        <input type="text" id='nama' name='nama' className='form form-control mb-2 bad-script-regular' required style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${dataWePernikahan?.btnColorCD}`}} maxLength="100" />
                                                        <label className='mx-2 fw-normal bad-script-regular' htmlFor="status"style={{color:[dataWePernikahan?.fntColor],fontSize:"13pt"}}>Status</label>
                                                        <select className="form-select form mb-2 bad-script-regular" id="status" name='status' aria-label="status" required style={{boxShadow:`-10px 10px 25px -16px ${dataWePernikahan?.btnColorCD}`}}>
                                                            <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                            <option value="Saudara">Saudara</option>
                                                            <option value="Atasan Kerja">Atasan Kerja</option>
                                                            <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                            <option value="Dosen/Guru">Dosen/Guru</option>
                                                            <option value="Teman Kerja">Teman Kerja</option>
                                                            <option value="Teman Sekolah">Teman Sekolah</option>
                                                            <option value="Teman">Teman</option>
                                                        </select>
                                                        <label className='mx-2 fw-normal bad-script-regular' htmlFor="ucapan"style={{color:[dataWePernikahan?.fntColor],fontSize:"13pt"}}>Ucapan</label>
                                                        <input type="text" id='ucapan' name='ucapan' className='form form-control mb-4 bad-script-regular' required style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${dataWePernikahan?.btnColorCD}`}} maxLength="300"/>
                                                        <button type="submit"  className="form form-control fw-bold mb-4 mt-5 bad-script-regular" style={{backgroundColor:[dataWePernikahan?.btnColor],padding:"5px",boxShadow:`-10px 10px 25px -14px ${dataWePernikahan?.btnColorCD}`,color:[dataWePernikahan?.fntColor],border:[dataWePernikahan?.borderBtn],fontSize:"15pt"}}><i className="bi bi-send"></i> Kirim</button>
                                                    </form>
                                                </div>
                                                <img loading="lazy" src={dataWePernikahan?.bgImageBox2} className='w-100' />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[dataWePernikahan?.borderBoxLeft],borderRight:[dataWePernikahan?.borderBoxRight]}}>
                                                <div className="p-3">
                                                    <div className='bi bi-chat-square-text text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[dataWePernikahan?.fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 pb-3 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[dataWePernikahan?.fntColor],fontSize:"30pt",marginTop:"-10px"}}>Ucapan Live</p>
                                                    <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                        <table>
                                                            <tbody>
                                                                <tr className='mt-2' >
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:[dataWePernikahan?.fntColor],fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"11pt",fontWeight:"500"}}>Akang</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"11pt",fontWeight:"500"}}>Halo, selamat wedding ya, semoga samawa</p></td>
                                                                </tr>
                                                                <tr className='mt-2' >
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:[dataWePernikahan?.fntColor],fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"11pt",fontWeight:"500"}}>Agro</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"11pt",fontWeight:"500"}}>Asik nikah</p></td>
                                                                </tr>
                                                                <tr className='mt-2' >
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:[dataWePernikahan?.fntColor],fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"11pt",fontWeight:"500"}}>Kolah</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"11pt",fontWeight:"500"}}>Selamat menempuh hidup baru</p></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <img loading="lazy" src={dataWePernikahan?.bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* BOX 7 */}
                        <section style={{backgroundImage:[dataWePernikahan?.bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img loading="lazy" src={dataWePernikahan?.bgImageBox1} className='w-100' style={{transform:`${dataWePernikahan?.rotate}`}} />
                            <p className='text-center fw-bold mt-4 mb-5 courgette-regular' style={{color:[dataWePernikahan?.fntColor],fontSize:"25pt"}}>Website By Webew.id</p>
                            <h3 className='text-center fw-normal mt-4 courgette-regular' style={{color:[dataWePernikahan?.fntColor],marginBottom:"-2px"}}>Image On Freepik</h3>
                            <p style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "auto" }}>
                                <a href={dataWePernikahan?.links?.[0]?.pemilik} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: dataWePernikahan?.fntColor,fontSize: "10pt",textDecoration: "none",}}>{dataWePernikahan?.links?.[0]?.pemilik}</a>
                                <a href={dataWePernikahan?.links?.[1]?.pemilik} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: dataWePernikahan?.fntColor,fontSize: "10pt",textDecoration: "none",}}>{dataWePernikahan?.links?.[1]?.pemilik}</a>
                                <a href={dataWePernikahan?.links?.[2]?.pemilik} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: dataWePernikahan?.fntColor,fontSize: "10pt",textDecoration: "none",}}>{dataWePernikahan?.links?.[2]?.pemilik}</a>
                            </p>
                            <h3 className='text-center fw-normal mt-4 courgette-regular' style={{color:[dataWePernikahan?.fntColor],marginBottom:"-2px"}}>Song On Youtube</h3>
                            <a href={dataWePernikahan?.links?.[3]?.pemilik} className="mb-4 bad-script-regular" aria-label="By" style={{display:"block",margin:"auto",textAlign:"center",color:[dataWePernikahan?.fntColor],fontSize:"10pt"}}>{dataWePernikahan?.links?.[3]?.pemilik}</a>
                            <img loading="lazy" src={dataWePernikahan?.bgImageBox1} className='w-100' style={{marginBottom:"20px"}}/>
                        </section>
                    </section>

                    {/* PAJANGAN */}
                    {jenisWePernikahanParams === "aglaonema" || jenisWePernikahanParams === "agapanthus" || jenisWePernikahanParams === "amaryllis" || jenisWePernikahanParams === "alamanda"
                        ?<div>{kupuKupu.map((className, index) => (
                            <img loading="lazy" key={index} src={dataWePernikahan?.pajangan} className={className} />
                        ))}</div>
                        :<div>{daun.map((className, index) => (
                            <img loading="lazy" key={index} src={dataWePernikahan?.pajangan} className={className} />
                        ))}</div>
                    }

                </section>
            </section>
        </div>
    )
}

export default WePernikahanContoh