// IMPORT REACT
import React, { useState, useMemo, lazy, Suspense } from 'react'

// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const NavbarBot = lazy(() => import('../../Layout/NavbarBot.jsx'));

const WeCv = () => {
    
    // PROSES LOOP JENIS CV
    const jenisWeCv = useMemo(() => [
        { title: 'CV Standar I', imgSrc: 'https://webew.id/img/bikinCV/cvg1.webp', alt: 'Buat CV Standar I' },
        { title: 'CV Standar II', imgSrc: 'https://webew.id/img/bikinCV/cvg2.webp', alt: 'Buat CV Standar II' },
        { title: 'CV Standar III', imgSrc: 'https://webew.id/img/bikinCV/cvg3.webp', alt: 'Buat CV Standar III' },
        { title: 'CV Standar IV', imgSrc: 'https://webew.id/img/bikinCV/cvg4.webp', alt: 'Buat CV Standar IV' },
    ], []);

    return (
        <div className='background-home' style={{marginTop:'55px'}}>

            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>

                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>We CV</h2>
                                <p className='text-webew-home fw-normal'>Buat CV online dengan mudah dan cepat disini, tinggal pilih desain, isi form data dengan benar, lalu download Cv nya</p>
                                 <a href={'/'} className='btn btn-webew-product' aria-label='Kembali webew.id'><i className="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                            </div>

                            <div className='col-12 col-lg-6'>
                                <img loading="lazy" src='https://webew.id/img/6976378_4565.webp' className='img-webew-home mx-auto mt-4' style={{width: '100%',minHeight:'200px',display: 'block' }} alt='by Freepik on freepik'/>
                            </div>

                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}

            <img loading="lazy" src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>

            {/* JENIS WE CV */}
                <section>
                    <div className='container'>
                        <div className='row'>
                            {jenisWeCv.map((data, index) => (
                                <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3'>
                                    <a href={'https://webew.id/login/menu-we-cv'} aria-label='Produk webew.id'>
                                        <div className='card mb-4 pt-3 p-4' style={{ width: '100%'}}>
                                            <h5 className='card-title fw-bold text-center mt-2 mb-3'>{data.title}</h5>
                                            <img loading="lazy" src={data.imgSrc}className='mx-auto img-fluid my-2'style={{ maxHeight: '450px',display: 'block' }}alt={data.alt}/>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            {/* TUTUP JENIS WE CV */}

            <img loading="lazy" src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>

            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>

                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/recruit-agent-analyzing-candidates_6976378.htm#page=4&position=14&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Pch-vector Freepik'>
                                    <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img loading="lazy" src='https://webew.id/img/credit/6976378_4565.webp' className='mx-auto'  style={{width: '100%',minHeight:'120px',display: 'block' }} alt='by Pch-vector on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Pch-vector on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </section>
            {/* TUTUP CREIT IMAGE */}

            {/* NAVBAR BOTTOM */}
                <Suspense fallback={<div></div>}>
                    <NavbarBot />
                </Suspense>
            {/* NAVBAR BOTTOM */}

        </div>
    )
}

export default WeCv