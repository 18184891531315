// IMPORT REACT
import React,{ useState, useEffect, useMemo, lazy, Suspense } from 'react'
import axios from "axios"
import jwt_decode from "jwt-decode"
import { useParams, useNavigate } from "react-router-dom"

// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuSidebar = lazy(() => import('../Layout/MenuSideBar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbar = lazy(() => import('../Layout/MenuNavbar.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuNavbarBot = lazy(() => import('../Layout/MenuNavbarBot.jsx'));
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const MenuDonasi = lazy(() => import('../Layout/MenuDonasi.jsx'));

const MenuWePernikahan = () => {
    
    const { panggilanParams } = useParams();
    const [panggilan, setPanggilan] = useState('')
    const [expire, setExpire] = useState('')
    const [, setToken] = useState('')
    const navigate = useNavigate()

    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            if(panggilanParams !== decoded.panggilan){
                try {
                    await axios.delete('https://server13.webew.id/logoutBE')
                    navigate("/login/dashboard", { replace: true })
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-pernikahan", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })

    // PROSES LOOP JENIS WE PERNIKAHAN
    const jenisWePernikahan = useMemo(() => [
        { createLink: `/menu-we-pernikahan-form/${panggilan}/aglaonema`, viewLink: '/we-pernikahan/aglaonema', nama: 'Aglaonema', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/aglaonema.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/agapanthus`, viewLink: '/we-pernikahan/agapanthus', nama: 'Agapanthus', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/agapanthus.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/alamanda`, viewLink: '/we-pernikahan/alamanda', nama: 'Alamanda', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/alamanda.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/amaryllis`, viewLink: '/we-pernikahan/amaryllis', nama: 'Amaryllis', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/amaryllis.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnwI`, viewLink: '/we-pernikahan/bnwI', nama: 'Bnw I', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnwI.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnwII`, viewLink: '/we-pernikahan/bnwII', nama: 'Bnw II', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnwII.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnwIII`, viewLink: '/we-pernikahan/bnwIII', nama: 'Bnw III', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnwIII.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnwIV`, viewLink: '/we-pernikahan/bnwIV', nama: 'Bnw IV', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnwIV.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/blegold`, viewLink: '/we-pernikahan/blegold', nama: 'BleGold', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blegold.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/blepink`, viewLink: '/we-pernikahan/blepink', nama: 'BlePink', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blepink.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bletosca`, viewLink: '/we-pernikahan/bletosca', nama: 'BleTosca', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bletosca.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/blewhite`, viewLink: '/we-pernikahan/blewhite', nama: 'BleWhite', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blewhite.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/cute`, viewLink: '/we-pernikahan/cute', nama: 'Cute', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/cute.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/adorable`, viewLink: '/we-pernikahan/adorable', nama: 'Adorable', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/adorable.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/beauty`, viewLink: '/we-pernikahan/beauty', nama: 'Beauty', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/beauty.webp', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/cool`, viewLink: '/we-pernikahan/cool', nama: 'Cool', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/cool.webp', alt: 'Undangan Digital Pernikahan' }
    ], [panggilan]);

    return ( 
        <div id="wrapper">

            {/* MODAL DONASI */}
                <Suspense fallback={<div></div>}>
                    <MenuDonasi/>
                </Suspense>
            {/* TUTUP MODAL DONASI */}

            {/* SIDEBAR */}
                <Suspense fallback={<div></div>}>
                    <MenuSidebar/>
                </Suspense>
            {/* TUTUP SIDEBAR */}

            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">

                    {/* NAVBAR */}
                        <Suspense fallback={<div></div>}>
                            <MenuNavbar/>
                        </Suspense>
                    {/* TUTUP NAVBAR */}

                    {/* KONTEN */}
                        <div className="wrap-menu px-1">
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">

                                        <div className="col-12 pb-2 pt-4">
                                            <h4 className='text-center fw-bold'>We Pernikahan</h4>
                                            <p className='text-center fw-semibold'>Buat Undangan Pernikahan Digital Dengan We Pernikahan</p>
                                        </div>

                                    </div>
                                    <div className="row mb-5 pb-4">
                                    {jenisWePernikahan.map((data, index) => (

                                            <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3' id='pernikahan'>
                                                <div className='card mb-4 pt-3 pb-0 px-2' style={{ width: '100%' }}>
                                                    <h5 className='card-title text-center mt-2 fw-bold'>{data.nama}</h5>
                                                    <img loading="lazy" src={data.imgSrc} className='mx-auto img-fluid mt-1' style={{ maxHeight: '450px',borderRadius:"30px",border:"6px solid black",display: 'block' }} alt={data.alt} />
                                                    <div className='card-body'>
                                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <a href={data.viewLink} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                                            </div>
                                                            <div className='col-6'>
                                                                <a href={data.createLink} className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* NAVBOT */}
                                <Suspense fallback={<div></div>}>
                                    <MenuNavbarBot/>
                                </Suspense>
                            {/* TUTUP NAVBOT */}

                        </div>
                    {/* TUTUP KONTEN */}

                </div>
            {/* TUTUP KONTEN WRAPPER */}

        </div>
    ) 
} 

export default MenuWePernikahan