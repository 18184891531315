// IMPORT REACT
import React,{ useState, lazy, Suspense } from 'react'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate } from 'react-router-dom'
// IMPORT LOADING PAGE
import LoadingPage from '../../Layout/LoadingPage.jsx'
// IMPORT RECAPTCHA
import ReCAPTCHA from 'react-google-recaptcha';
// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const NavbarBot = lazy(() => import('../../Layout/NavbarBot.jsx'));
// LOGIN
const Login = () => { // KURUNG BUKA LOGIN
    // MEMBUAT VARIABEL LOGIN
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    // MEMBUAT VARIABEL MESSAGE DARI BACKEND
    const [msg, setMsg] = useState('')
    // MEMBUAT VARIABEL LOADER HALAMAN
    const [loading, setLoading] = useState(false); // State untuk loading
    const [progress, setProgress] = useState(0); // Progress loading bar
    // MEMBUAT VARIABEL CAPTCHA
    const [recaptchaToken, setRecaptchaToken] = useState(null); // State untuk token reCAPTCHA
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // PROSES LOGIN
    const login = async (e) => {
        e.preventDefault();
        if (!recaptchaToken) {
            setMsg('Silakan selesaikan reCAPTCHA terlebih dahulu.');
            return;
        }
        try {
            setLoading(true);
            setProgress(0);
            let fakeProgress = 0;
            const fastLoading = setInterval(() => {
                fakeProgress += 10;
                setProgress(fakeProgress);
                if (fakeProgress >= 80) {
                    clearInterval(fastLoading);
                }
            }, 30);
            const response = await axios.post('https://server13.webew.id/loweminBE', {
                email: email,
                password: password,
                recaptchaToken: recaptchaToken
            },
            {
                onDownloadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    if (total) {
                        let percentCompleted = Math.round((loaded / total) * 100);
                        setProgress(Math.max(80, percentCompleted)); // Pastikan tidak turun dari 80%
                    } else {
                        // Jika total tidak tersedia, buat progres naik perlahan
                        setProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : prevProgress));
                    }
                }
            });
            // Decode token untuk mendapatkan data admin
            const decoded = jwt_decode(response.data.accessToken);
            // Redirect langsung ke dashboard admin berdasarkan panggilan
            setProgress(100); // Selesaikan progress
            setTimeout(() => {
                setLoading(false);
                navigate(`/dashmin/${decoded.panggilan}`, { replace: true });
            }, 0);
        } catch (error) {
            setProgress(100);
            setTimeout(() => setLoading(false), 0);
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };
    
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* LOADER */}
                {loading && <LoadingPage progress={progress} />}
            {/* TUTUP LOADER */}
            {/* BOX LOGIN */}
                <div className='container mb-3' style={{marginTop:'110px'}}>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-xl-10'>
                            <div className='box p-4'>
                                <div className='row justify-content-center'>
                                    <div className='col-11 col-md-6 col-lg-5 order-1 order-md-0'>
                                        <h4 className='text-center' style={{fontWeight:'700',marginTop:'10px'}}>Login Admin</h4>
                                        <form onSubmit={login}>
                                            <h6 style={{textAlign:'center',marginTop:'15px',marginBottom:'-10px',color:'red'}}>{msg}</h6>
                                            <label htmlFor='email' className='mx-2'></label>
                                            <input type='email' id='email' className='form-control form mb-2' placeholder='Masukan email' value={email} onChange={(e) => setEmail(e.target.value)} required autoFocus/>
                                            <label htmlFor='password' className='mx-2'></label>
                                            <input type='password' id='password' className='form-control form mb-4' placeholder='Masukan password' value={password} onChange={(e) => setPassword(e.target.value)} required/>
                                            <div style={{transform:"scale(0.7)",display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
                                                <ReCAPTCHA sitekey="6LfwydIqAAAAAI41N6xqgQUNstb2fLSrZ15XCTNT" onChange={(token) => setRecaptchaToken(token)} hl="id"/>
                                            </div>
                                            <input type='submit' className='btn btn-webew-login mb-4 mt-3' value='Login'/>
                                            <a href={'/rewemin'} className='fw-semibold' style={{color:'rgb(0, 86, 247)', margin:'auto', display:'block', textAlign:'center', fontSize:'10pt'}}>Register</a>
                                            <a href={'/lupwemin'} className='fw-semibold' style={{color:'rgb(0, 86, 247)', margin:'auto', display:'block', textAlign:'center', fontSize:'10pt'}}>Lupa Password?</a>
                                        </form>
                                    </div>
                                    <div className='col-11 col-md-6 col-lg-5 order-0 order-md-1 mt-2 mb-3'>
                                        <img loading="lazy" src='https://webew.id/img/4957136_4957136.jpg' alt='login' style={{ width: '100%', maxWidth: '400px',display: 'block' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* TUTUP BOX LOGIN */}
            {/* NAVBAR BOTTOM */}
                 <Suspense fallback={<div></div>}>
                    <NavbarBot />
                </Suspense>
            {/* TUTUP NAVBAR BOTTOM */}
            {/* LINK CREDIT FOTO */}
                <a href={'https://www.freepik.com/free-vector/mobile-login-concept-illustration_4957136.htm#query=login%20vector&position=3&from_view=search&track=ais&uuid=3af634d7-f497-4f14-9cc2-9bfe74419701'}><p className='text-center fw-semibold' style={{fontSize:'9pt',color:'#d1d1d1',marginBottom:'0px'}}>Image by storyset</p></a>
            {/* TUTUP LINK CREDIT FOTO */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP LOGIN
// EKSPOR
export default Login