// IMPORT REACT
import React, {useState, useMemo, lazy, Suspense} from 'react'

// IMPORT NAVBAR BOTTOM SECARA LAZY LOADING
const NavbarBot = lazy(() => import('../../Layout/NavbarBot.jsx'));

const WeWebsite = () => {

  // PROSES LOOP TEMA WEBSITE
  const jenisWeWebsite = useMemo(() => [
    {id: '1',title: 'Web Porto I',imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g1.png',alt: 'Website portofolio I',tech: [{src: 'https://webew.id/img/desainWebsite/HTML 5.png', alt: 'Logo HTML 5',},{src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',alt: 'Logo CSS 3',},{src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',alt: 'Logo Bootstrap 5',},],link: '/we-website/WebPortoI',},
    {id: '2',title: 'Web Porto II',imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g2.png',alt: 'Website portofolio II',tech: [{src: 'https://webew.id/img/desainWebsite/HTML 5.png', alt: 'Logo HTML 5',},{src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',alt: 'Logo CSS 3',},{src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',alt: 'Logo Bootstrap 5',},],link: '/we-website/WebPortoII',},
    {id: '3',title: 'Web Porto III',imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g3.png',alt: 'Website portofolio III',tech: [{src: 'https://webew.id/img/desainWebsite/HTML 5.png', alt: 'Logo HTML 5',},{src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',alt: 'Logo CSS 3',},{src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',alt: 'Logo Bootstrap 5',},],link: '/we-website/WebPortoIII',},
    {id: '4',title: 'Web Porto IV',imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g4.png',alt: 'Website portofolio IV',tech: [{src: 'https://webew.id/img/desainWebsite/HTML 5.png', alt: 'Logo HTML 5',},{src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',alt: 'Logo CSS 3',},{src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',alt: 'Logo Bootstrap 5',},],link: '/we-website/WebPortoIV',},
  ], []);

  return (
      <div className='background-home' style={{marginTop:'55px'}}>

          {/* INFO */}
              <section id='home'>
                  <div className='container'>
                      <div className='row'>

                          <div className='col-12 col-lg-6 px-3'>
                              <h2 className='title-webew-home'>We Website</h2>
                              <p className='text-webew-home fw-normal'>Pilih desain website yang kamu inginkan, lalu tinggal kamu download, gampang sekali bukan. Buruan download desain website disini</p>
                              <a href={'/'} className='btn btn-webew-product' aria-label='Kembali webew.id'><i className="bi bi-arrow-left-circle"></i> Kembali</a>
                          </div>

                          <div className='col-12 col-lg-6'>
                            <img loading="lazy" src='https://webew.id/img/25559969_7094814.webp' className='img-webew-home mx-auto mt-5'  style={{width: '100%',minHeight:'200px',display: 'block' }} alt='by Freepik on freepik'/>
                          </div>

                      </div>
                  </div>    
              </section>
          {/* TUTUP INFO */}

          <img loading="lazy" src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>

          {/* JENIS WE WEBSITE */}
              <section>
                  <div className="container">
                      <div className="row">
                          {jenisWeWebsite.map((data, index) => (
                            
                              <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <a href={data.link} aria-label="Produk webew.id">
                                  <div className="card mb-4 pt-3 pb-2 px-2" style={{ width: '100%' }}>
                                      <h5 className="card-title fw-bold text-center mt-2">{data.title}</h5>
                                      <img loading="lazy" src={data.imgSrc} className="mx-auto img-fluid mt-3" style={{ maxHeight: '450px',borderRadius:"25px" ,display: 'block' }}  alt={data.alt}/>
                                      <div className="card-body">
                                        <table className="mb-2 mt-1">
                                            <tbody>
                                            <tr>
                                                {data.tech.map((tech, techIndex) => (
                                                    <td key={techIndex} width="33.3%">
                                                        <img loading="lazy" src={tech.src} className="img-fluid mx-auto" style={{ width: '40px', minHeight: '40px' ,display: 'block' }} alt={tech.alt}/>
                                                    </td>
                                                ))}
                                            </tr>
                                            </tbody>
                                        </table>
                                      </div>
                                  </div>
                                </a>
                              </div>

                          ))}
                      </div>
                  </div>
              </section>
          {/* TUTUP JENIS WE WEBSITE */}

          <img loading="lazy" src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>

          {/* CREDIT IMAGE */}
              <section id='credit-image'>
                  <div className='container mb-4'>
                      <h3 className='judul-credit text-center'>Image By</h3>
                      <div className='row justify-content-center'>

                          <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                              <a href='https://www.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_25559969.htm#page=4&query=programming&position=7&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Freepik di Freepik'>
                                  <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img loading="lazy" src='https://webew.id/img/credit/25559969_7094814.webp' className='mx-auto'  style={{width: '100%',minHeight:'110px',display: 'block' }} alt='by Freepik on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Freepik on Freepik</p>
                                        </div>
                                  </div>
                              </a>
                          </div>

                      </div>
                  </div>
              </section>
          {/* TUTUP CREDIT IMAGE */}

          {/* NAVBAR BOTTOM */}
              <Suspense fallback={<div></div>}>
                  <NavbarBot />
              </Suspense>
          {/* TUTUP NAVBAR BOTTOM */}

      </div>
  ) 
}

export default WeWebsite